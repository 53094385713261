export * from "./ArmedPeopleCard";
export * from "./BicyclePeopleCard";
export * from "./Card";
export * from "./Input";
export * from "./CapacityCard";
export * from "./DailyTrafficCard";
export * from "./CardWithoutPeople";
export * from "./TrafficCard";
export * from "./PeoplePeakCard";
export * from "./RestrictedAccessCard";
export * from "./RunningPeopleCard";
export * from "./PetCard";
export * from "./LyingPersonCard";
export * from "./AgglomerationCard";
export * from "./ObjectMovementCard";
export * from "./PermanenceDetectionCard";
export * from "./ManagementCard";

export const COMMUNICATION_CHANNELS = ["SMS", "WhatsApp", "Email"];
export const PERIOD_OPTIONS = ["Diario", "Semanal", "Mensual"];
export const CAMERA_OPTIONS = [
    {
        id: 100,
        value: "Cámara 1",
        label: "Cámara 1",
    },
    {
        id: 101,
        value: "Cámara 2",
        label: "Cámara 2",
    },
];
export const TIME_OPTIONS = [
    {
        id: 1,
        value: "1 hora",
        label: "1 hora",
    },
    {
        id: 2,
        value: "3 horas",
        label: "3 horas",
    },
    {
        id: 3,
        value: "6 horas",
        label: "6 horas",
    },
    {
        id: 4,
        value: "12 horas",
        label: "12 horas",
    },
    {
        id: 5,
        value: "24 horas",
        label: "24 horas",
    },
];
export const SCHEDULE_OPTIONS = [
    {
        id: 1,
        value: "08:00 am - 09:00 am",
        label: "08:00 am - 09:00 am",
    },
    {
        id: 2,
        value: "09:00 am - 10:00 am",
        label: "09:00 am - 10:00 am",
    },
    {
        id: 3,
        value: "10:00 am - 11:00 am",
        label: "10:00 am - 11:00 am",
    },
    {
        id: 4,
        value: "11:00 am - 12:00 pm",
        label: "11:00 am - 12:00 pm",
    },
    {
        id: 5,
        value: "12:00 pm - 01:00 pm",
        label: "12:00 pm - 01:00 pm",
    },
    {
        id: 6,
        value: "01:00 pm - 02:00 pm",
        label: "01:00 pm - 02:00 pm",
    },
    {
        id: 7,
        value: "03:00 pm - 04:00 pm",
        label: "03:00 pm - 04:00 pm",
    },
    {
        id: 8,
        value: "04:00 pm - 05:00 pm",
        label: "04:00 pm - 05:00 pm",
    },
    {
        id: 9,
        value: "05:00 pm - 06:00 pm",
        label: "05:00 pm - 06:00 pm",
    },
    {
        id: 10,
        value: "06:00 pm - 07:00 pm",
        label: "06:00 pm - 07:00 pm",
    },
    {
        id: 11,
        value: "07:00 pm - 08:00 pm",
        label: "07:00 pm - 08:00 pm",
    },
    {
        id: 12,
        value: "08:00 pm - 09:00 pm",
        label: "08:00 pm - 09:00 pm",
    },
];
export const VALUABLE_OBJECTS = [
    {
        id: 1,
        value: "Portatil",
        label: "Portatil",
    },
    {
        id: 2,
        value: "Celular",
        label: "Celular",
    },
];
