import { ALERT } from "constants/Alert";

export const ALERT_INFORMATION = {
    [ALERT.CAPACITY]: {
        title: "Capacidad de aforo",
        description:
            'Si durante el día el aforo del centro comercial supera el "pico de aforo" que defina, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).',
    },
    [ALERT.TRAFFIC]: {
        title: "Resúmen de tráfico",
        description:
            "Si desea recibir un resumen del total de visitantes seleccione: diario, todos los dias le notifica el total de visitantes del día anterior; semanal, cada lunes le notifica el total de visitantes de la semana anterior; mensual, cada primero del mes le notifica el total de visitantes del mes anterior al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.DAILY_TRAFFIC]: {
        title: "Pico de tráfico durante el día",
        description:
            'Si durante el día el trafico del centro comercial supera el "pico tráfico" que defina, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).',
    },
    [ALERT.WITHOUT_PEOPLE]: {
        title: "Sin flujo de personas en una zona (cámara)",
        description:
            "Si en la cámara entrada/zona seleccionada no hay flujo de personas durante un periodo establecido, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo). Ideal para alertar si en alguna zona o entrada principal muy transitada comúnmente no se detecta flujo personas.",
    },
    [ALERT.PEOPLE_PEAK]: {
        title: "Pico de personas en una zona (cámara)",
        description:
            "Si en la cámara entrada/zona seleccionada hay un pico de visitantes durante un periodo establecido, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo). Ideal para alertar si en alguna zona o entrada transitan más personas de lo normal.",
    },
    [ALERT.PEOPLE_RUNNING]: {
        title: "Persona corriendo en una zona (cámara)",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una persona corriendo, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.PEOPLE_ON_BICYCLES]: {
        title: "Personas con bicicleta en una zona (cámara)",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una persona con bicicleta, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.ARMED_PEOPLE]: {
        title: "Personas con armas en una zona (cámara)",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una persona con un arma, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.RESTRICTED_ACCESS]: {
        title: "Área restringida",
        description:
            "Si en la cámara entrada/zona seleccionada como área restringida se detecta una persona, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.PETS]: {
        title: "Mascota en área restringida",
        description:
            "Si en la cámara entrada/zona seleccionada como área restringida se detecta una mascota, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo). Ideal para zonas que no pueden ingresar mascotas como plazoletas de comidas u otras.",
    },
    [ALERT.LYING_PERSON]: {
        title: "Persona en el suelo",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una persona en el suelo, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.AGGLOMERATION]: {
        title: "Aglomeración en zona",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una aglomeración de personas, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.OBJECT_MOVEMENT]: {
        title: "Movimiento de objeto",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta que un objeto valioso se mueve o ya no está, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo).",
    },
    [ALERT.DWELL_DETECTION]: {
        title: "Detección de permanencia",
        description:
            "Si en la cámara entrada/zona seleccionada se detecta una persona que lleva más de 30 minutos sin moverse o salir de la zona, llegará una alerta al canal que escoja (SMS/WhatsApp/Correo). Ideal para zonas que normalmente se encuentran solas o están cerca a objetos de valor.",
    },
    [ALERT.VISITOR_MANAGEMENT]: {
        title: "Gestión de visitantes",
        description:
            "Si no hay aumento del número de visitantes durante 10 minutos, se enviará una alerta a los correos: hugo@switchai.co - a@switchai.co ",
    },
    [ALERT.CAMERA_MANAGEMENT]: {
        title: "Gestión de cámaras",
        description: "Si no hay acceso a una cámara, se enviará una alerta a los correos: hugo@switchai.co - a@switchai.co ",
    },
};
