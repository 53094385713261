//Import main libraries and components
import React from "react";
import { Navbar, Nav, Row, Col, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../api/Main";
import { FcmNotifications } from "../init-fcm";
import ReactTooltip from 'react-tooltip';

//Import images
import logo from "../imgs/switch-logo.png";
import menu from "../imgs/menu-button.png";
import dashboard from "../imgs/dashboard.png";
import camera from "../imgs/camera.png";
import configuration from "../imgs/configuration.png";
import notification from "../imgs/notification.png";
import symptoms from "../imgs/symptom.png";
import logout from "../imgs/logout.png";
import feedback from "../imgs/question.png";
import imgEquis from "../imgs/equis2.png";
import imgChat from "../imgs/chat.png";
import imgHappyFace from "../imgs/Happy-face.png";
import imgLoveFace from "../imgs/love-face.png";
import imgNeutralFace from "../imgs/neutral-face.png";
import imgSadFace from "../imgs/Sad-Face.png";
import imgAngryFace from "../imgs/angry-face.png";
import resourcesGroup from "../imgs/admin.png";

//Import styles
import "../styles/Shared/navbar.css";

class LateralMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colLateral: 256,
      switch: 0,
      switchMenu: 0,
      switch_notification: 1,
      submenuOrientation: "",
      title: "",
      text: "",
      url_screenshot: "",
      create_date: "",
      loading: false,
      count: 0,
      notifications: [],
      isOpen: false,
      sideopen: false,
      tooltipOpen: false,
    };
  }

  drawerOpen = () =>{
    this.setState((prevState) =>{
      return{ sideopen: !prevState.sideopen};
    })
  }

  toggle = () => {
    this.setState({ tooltipOpen: true });
  };

  loadMenu() {
    const cookies = new Cookies();
    let menu = cookies.get("modules");
    let role = cookies.get("role");
    this.hideItems();
    for (var i = 0; i < menu.length; i++) {
      document.getElementById(menu[i]).style.display = "";
      if (menu[i] == "item-dashboard-general") {
        document.getElementById("item-dashboard-general").style.display = "";
        document.getElementById("item-dashboard-super").style.display = "";
      } else if (menu[i] == "item-dashboard-manager") {
        document.getElementById("item-dashboard-manager").style.display = "";
      }
    }
    if (role == "owner" && (menu[i] == "item-dashboard-general" || menu[i] == "item-dashboard-manager")) {
      document.getElementById("activeConfig").style.display = "none";
      document.getElementById("item-security").style.display = "none";
      document.getElementById("item-home-security").style.display = "none";
      document.getElementById("item-sympthoms").style.display = "none";
    }
  }

  hideItems() {
    document.getElementById("item-configuration").style.display = "none";
    document.getElementById("item-characterization").style.display = "none";
    document.getElementById("item-security").style.display = "none";
    document.getElementById("item-home-security").style.display = "none";
    document.getElementById("item-sympthoms").style.display = "none";
    document.getElementById("item-dashboard-general").style.display = "none";
    document.getElementById("item-dashboard-manager").style.display = "none";
    document.getElementById("item-dashboard-super").style.display = "none";
  }

  openFeedback = () => {
    document.getElementById("popUpFeedback").style.display = "";
    document.getElementById("genericBody").style.filter = "blur(5px)";
    document.getElementById("activeFeedback").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeFeedback").style.borderStyle = "dashed";
    document.getElementById("activeFeedback").style.borderColor = "#84ac34";
    document.getElementById("activeFeedback").style.borderRadius = "1rem";
  };

  handleClosePopUp = () => {
    document.getElementById("popUpFeedback").style.display = "none";
    document.getElementById("genericBody").style.filter = "";
    document.getElementById("message").style.display = "none";
    document.getElementById("sendButton").style.display = "";
    document.getElementById("activeFeedback").style.color = "";
    document.getElementById("activeFeedback").style.borderStyle = "none";
    this.setState({ text: "" });
  };

  handleClickNotification = () => {
    this.verifyClic();
    this.createNotification();
  };

  handleChangeFeeling(m){
    this.setState({ title: m });
    if(m == "Muy Insatisfecho"){
      document.getElementById("emoji1").style.backgroundColor = "#D6D8D2"; 
      document.getElementById("emoji2").style.backgroundColor = ""; 
      document.getElementById("emoji3").style.backgroundColor = ""; 
      document.getElementById("emoji4").style.backgroundColor = ""; 
      document.getElementById("emoji5").style.backgroundColor = ""; 

    }else if( m == "Insatisfecho"){
      document.getElementById("emoji1").style.backgroundColor = ""; 
      document.getElementById("emoji2").style.backgroundColor = "#D6D8D2"; 
      document.getElementById("emoji3").style.backgroundColor = ""; 
      document.getElementById("emoji4").style.backgroundColor = ""; 
      document.getElementById("emoji5").style.backgroundColor = ""; 

    }else if( m == "Neutral"){
      document.getElementById("emoji1").style.backgroundColor = ""; 
      document.getElementById("emoji2").style.backgroundColor = ""; 
      document.getElementById("emoji3").style.backgroundColor = "#D6D8D2"; 
      document.getElementById("emoji4").style.backgroundColor = ""; 
      document.getElementById("emoji5").style.backgroundColor = ""; 

    }else if( m == "Satisfecho"){
      document.getElementById("emoji1").style.backgroundColor = ""; 
      document.getElementById("emoji2").style.backgroundColor = ""; 
      document.getElementById("emoji3").style.backgroundColor = ""; 
      document.getElementById("emoji4").style.backgroundColor = "#D6D8D2"; 
      document.getElementById("emoji5").style.backgroundColor = ""; 

    }else{
      document.getElementById("emoji1").style.backgroundColor = ""; 
      document.getElementById("emoji2").style.backgroundColor = ""; 
      document.getElementById("emoji3").style.backgroundColor = ""; 
      document.getElementById("emoji4").style.backgroundColor = ""; 
      document.getElementById("emoji5").style.backgroundColor = "#D6D8D2"; 
    }
  };

  handleChangeMessage = (e) => {
    this.setState({ text: e.target.value });
    document.getElementById("inputMessage").style.borderColor = "";
  };

  sendFeedback = () => {
    if (this.state.text !== "" && this.state.title !== "") {
      this.setState({ loading: true });
      let body = JSON.stringify({
        data: {
          title: this.state.title,
          text: this.state.text,
          url_screenshot: this.state.url_screenshot,
          create_date: new Date().toISOString(),
        },
      });
      API.post("https://dot", "/general/feedback", body)
        .then((response) => {
          if (response.ok) {
            this.setState({ loading: false, title: "", text: "" });
            document.getElementById("sendButton").style.display = "none";
            document.getElementById("message").style.display = "";
            document.getElementById("message").style.color = "#84ac34";
            document.getElementById("message").innerText =
              "Comentario enviado con éxito";
          } else {
            this.setState({ loading: false });
            document.getElementById("sendButton").style.display = "none";
            document.getElementById("message").style.display = "";
            document.getElementById("message").style.color = "rgb(226, 86, 86)";
            document.getElementById("message").innerText =
              "Ha ocurrido un error. Por favor escribe a a@switchai.co";
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      if (this.state.text === "" && this.state.title === "") {
        document.getElementById("inputMessage").style.borderColor =
          "rgb(226, 86, 86)";
        document.getElementById("InputFeeling").style.border =
          "solid 2px rgb(226, 86, 86)";
      } else if (this.state.text === "" && this.state.title !== "") {
        document.getElementById("inputMessage").style.borderColor =
          "rgb(226, 86, 86)";
      } else if (this.state.text !== "" && this.state.title === "") {
        document.getElementById("InputFeeling").style.border =
          "solid 2px rgb(226, 86, 86)";
      }
    }
  };

  handleLinkStores = () => {
    const cookies = new Cookies();
    let menu = cookies.get("modules");
    let role = cookies.get("role");
    if ((menu[0] == "item-characterization")) {
      window.location = "/configuration/zones-selection";
    } else if(menu[0] == "item-security"){
      window.location = "/configuration/algorithm";
    }
  }

  handleLogoClick = () => {
    const cookies = new Cookies();
    let menu = cookies.get("modules");
    let role = cookies.get("role");
    if ((menu[0] == "item-characterization") & (role == "admin")) {
      window.location = "/marketing/dashboard";
    } else if ((menu[0] == "item-characterization") & (role == "owner")) {
      window.location = "/marketing/general";
    } else if ((menu[0] == "item-security")){
      window.location = "/admin/homeSecurityDash";
    }
  };

  handleMenuClick = () => {
    this.verifySwitch();
  };

  closeLateral = () =>{
    if (this.state.switchMenu === 0) {
        document.getElementById("lateral").style.display = "";
        document.getElementById("iconMenu").style.display= "none";
        this.setState({ switchMenu : 1});
    }else if (this.state.switchMenu === 1) {
        document.getElementById("lateral").style.display = "none";
        document.getElementById("iconMenu").style.display= "";
        this.setState({ switchMenu : 0});
    }
    
      //this.setState({ colLateral: 0})
  }

  /* verifySwitch() {
    if (this.state.switch === 0) {
      document.getElementById("lateralMenu").style.animation = "fadeOut 0.3s";
      document.getElementById("lateralMenu").style.display = "none";
      document.getElementById("genericBody").style.filter = "none";
      document.getElementById("menuIcon").style.borderColor = "#dae0e5";
      
      this.setState({ switch: 1 });
    } else if (this.state.switch === 1) {
      document.getElementById("lateralMenu").style.animation = "fadeIn 0.3s";
      document.getElementById("lateralMenu").style.display = "";
      document.getElementById("menuIcon").style.borderColor = "#84ac34";
      document.getElementById("genericBody").style.filter = "blur(5px)";

      this.setState({ switch: 0 });
    }
  } */

  verifyDevice() {
    let agent = window.navigator.userAgent;
    let devices = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i; //Expresión regular de dispositivos móviles para usar en el match()
    //Usando el array que devuelve el userAgent se modifica la orientación del submenú
    if (agent.match(devices) != null) {
      this.setState({ submenuOrientation: "bottom" });
    } else {
      this.setState({ submenuOrientation: "right" });
    }
  }

  doLogout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    window.location = "/";
    //http://control.holasoysami.com/
  };

  verifyClic() {
    if (this.state.switch_notification === 0) {
      document.getElementById("notificationDiv").style.display = "none";
      this.setState({ switch_notification: 1 });
    } else if (this.state.switch_notification === 1) {
      document.getElementById("notificationDiv").style.display = "";
      this.setState({ switch_notification: 0 });
    }
  }

  async createNotification() {
    const nameClass = document.getElementsByClassName("Notification").item(0)
      .classList;
    let stateNotifications = this.state.notifications;
    try {
      if (nameClass.contains("NotificationVisible") == true) {
        nameClass.remove("NotificationVisible");
        this.setState({
          isOpen: false,
        });
      } else {
        nameClass.add("NotificationVisible");
        const request = await API.get(
          "https://dot",
          "/notifications/action/send/notification"
        );
        await API.post(
          "https://dot",
          "/notifications/action/validate/open",
          JSON.stringify({
            data: {
              response: true,
            },
          })
        );
        const response = await request.json();
        response.sort((a, b) => {
          return new Date(b.create_date) - new Date(a.create_date);
        });
        response.forEach((value) => {
          stateNotifications.push(
            <a className="Notification__Item" href={value.url}>
              <h3>{value.title}</h3>
              <p>{value.body}</p>
            </a>
          );
        });
        this.setState({
          notifications: stateNotifications,
          isOpen: true,
          count: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async listenerMessageSw() {
    const message = FcmNotifications.messaging;
    let tempNotification = this.state.notifications;
    message.onMessage((payload) => {
      if (this.state.isOpen === true) {
        
        tempNotification.splice(
          0,
          0,
          <a className="Notification__Item" href={payload["fcmOptions"].link}>
            <h3>{payload["notification"].title}</h3>
            <p>{payload["notification"].body}</p>
          </a>
        );
        this.setState({
          notificacions: tempNotification,
        });
      } else {
        this.setState({
          count: this.state.count + 1,
        });
      }
    });
  }

  async loadCount() {
    try {
      const request = await API.get(
        "https://dot",
        "/notifications/action/send/notification"
      );
      const data = await request.json();
      data.forEach((value) => {
        if (value.see_date === null) {
          this.setState({
            count: this.state.count + 1,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    //this.verifySwitch();
    this.verifyDevice();
    this.loadMenu();
    this.loadCount();
    this.listenerMessageSw();
    document.getElementById("popUpFeedback").style.display = "none";
    document.getElementById("iconMenu").style.display = "none";
    document
      .getElementsByClassName("Notification")
      .item(0)
      .classList.remove("NotificationVisible");
    document.getElementById("notificationDiv").style.display = "none";
  }

  render() {
    return (
      <div>
        {/* <Navbar bg="light" expand="lg">
          <Navbar.Brand>
            <img
              src={menu}
              alt="Icono menú lateral"
              width="40rem"
              onClick={this.handleMenuClick}
              id="menuIcon"
              className="Menu-Icon"
            ></img>
            <img
              src={logo}
              alt="Logo Switch Up"
              width="100rem"
              onClick={this.handleLogoClick}
              className="Switch-Icon"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="Row-Reverse">
            <div>
              <Nav className="mr-auto">
                <Nav.Link
                  className="Menu-Item"
                  onClick={this.handleClickNotification}
                  id="notification"
                >
                  <img
                    src={notification}
                    alt="Icono Notificacion"
                    width="22rem"
                    className="Icon-Menu"
                  ></img>
                  {this.state.count} notificaciones
                </Nav.Link>
                <Nav.Link
                  className="Menu-Item"
                  onClick={this.openFeedback}
                  id="activeFeedback"
                >
                  <img
                    src={feedback}
                    alt="Icono Feedback"
                    width="22rem"
                    className="Icon-Menu"
                  ></img>
                  Comentarios
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar> 
        style={{width: this.state.colLateral}}*/}
        <p onClick={this.closeLateral}>CERRAR</p> 
        <div col-lg={1} id="lateral" className="Lateral">
          <div id="item-characterization" style={{width: "100%"}}>
            <label className="Menu-Item-Title">Analíticas</label>
            <Nav.Link
              id="item-dashboard-manager"
              className="Menu-Item"
              href="/marketing/dashboard"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
              Visión General
            </Nav.Link>
            <Nav.Link
              id="item-dashboard-general"
              className="Menu-Item"
              href="/marketing/general"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
              Visión General
            </Nav.Link>
            <Nav.Link
              id="item-dashboard-super"
              className="Menu-Item"
              href="/marketing/super"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
              Comportamiento Tienda
            </Nav.Link>
            <hr></hr>
          </div>

          <div id="item-security">
            <label className="Menu-Item-Title">Seguridad</label>
            <Nav className="mr-auto">
              <Col lg={12} className="px-0">
                <Nav.Link
                    id="activeDashBio"
                    className="Menu-Item"
                    href="/admin/homeSecurityDash"
                  >
                    <img
                      src={dashboard}
                      alt="Icono Dashboard"
                      width="20rem"
                      className="Icon-Menu"
                    ></img>
                    Dashboard
                  </Nav.Link>
              </Col>
              <Col lg={12} className="px-0"> 
                  <Nav.Link
                      Col={12}
                      id="activeCamsBio"
                      className="Menu-Item"
                      href="/biodot/cameras"
                    >
                      <img
                        src={camera}
                        alt="Icono Cameras"
                        width="22rem"
                        className="Icon-Menu"
                      ></img>
                      Cámaras
                  </Nav.Link>
              </Col>
              <Col lg={12} className="px-0"> 
                <Nav.Link
                  id="activeConfigBio"
                  className="Menu-Item d-none"
                  href="/biodot/account"
                >
                  <img
                    src={configuration}
                    alt="Icono Configuration"
                    width="19rem"
                    className="Icon-Menu"
                  ></img>
                  Configuración
                </Nav.Link>
              </Col>
            </Nav>
            <hr></hr>
          </div>

          <div id="item-sympthoms">
            <label className="Menu-Item-Title">Síntomas</label>
            <Nav.Link
              id="activeDashSym"
              className="Menu-Item"
              href="/symptoms/dashboard"
            >
              <img
                src={dashboard}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
              Dashboard
            </Nav.Link>
            <Nav.Link
              id="activeAnsSym"
              className="Menu-Item"
              href="/symptoms/answers"
            >
              <img
                src={symptoms}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
              Respuestas individuales
            </Nav.Link>
            <Nav.Link
              id="activeConfigSym"
              className="Menu-Item"
              href="/symptoms/emails"
            >
              <img
                src={configuration}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
              Configuración
            </Nav.Link>
            <hr></hr>
          </div>

          <div id="item-configuration">
            <label className="Menu-Item-Title">Configuración</label>
            <Nav.Link
              id="activeAdmin"
              className="Menu-Item"
              href="/admin/admin-resources-group"
            >
              <img
                src={resourcesGroup}
                alt="Icono Configuración"
                width="26rem"
                className="Icon-Menu"
              ></img>
              Administrar zonas
            </Nav.Link>
            <Nav.Link
              id="activeConfig"
              className="Menu-Item"
              onClick= {this.handleLinkStores}
            >
              <img
                src={configuration}
                alt="Icono Configuración"
                width="20rem"
                className="Icon-Menu"
              ></img>
              Configuración de algoritmos por Zona
            </Nav.Link>
            <Nav className="mr-auto"></Nav>
            <hr></hr>
          </div>

          <Nav className="mr-auto">
            <Nav.Link className="Menu-Item" onClick={this.doLogout}>
              <img
                src={logout}
                alt="Icono Logout"
                width="22rem"
                className="Icon-Menu"
              ></img>
              Cerrar sesión
            </Nav.Link>
          </Nav>
        </div>
        <div id="iconMenu" style={{ width: "90px"}} className="iconMenu">
            <div id="item-characterization" style={{width: "100%"}}>
            <Nav.Link
              id="item-dashboard-manager"
              className="Menu-Item"
              href="/marketing/dashboard"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav.Link
              id="item-dashboard-general"
              className="Menu-Item"
              href="/marketing/general"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav.Link
              id="item-dashboard-super"
              className="Menu-Item"
              href="/marketing/super"
            >
              <img
                src={dashboard}
                alt="Icono Dashboard"
                width="20rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <hr></hr>
          </div>

          <div id="item-security">
            <Nav className="mr-auto">
              <Col lg={12} className="px-0">
                <Nav.Link
                    id="activeDashBio"
                    className="Menu-Item"
                    href="/admin/homeSecurityDash"
                  >
                    <img
                      src={dashboard}
                      alt="Icono Dashboard"
                      width="20rem"
                      className="Icon-Menu"
                    ></img>
                  </Nav.Link>
              </Col>
              <Col lg={12} className="px-0"> 
                  <Nav.Link
                      Col={12}
                      id="activeCamsBio"
                      className="Menu-Item"
                      href="/biodot/cameras"
                    >
                      <img
                        src={camera}
                        alt="Icono Cameras"
                        width="22rem"
                        className="Icon-Menu"
                      ></img>
                  </Nav.Link>
              </Col>
              <Col lg={12} className="px-0"> 
                <Nav.Link
                  id="activeConfigBio"
                  className="Menu-Item d-none"
                  href="/biodot/account"
                >
                  <img
                    src={configuration}
                    alt="Icono Configuration"
                    width="19rem"
                    className="Icon-Menu"
                  ></img>
                </Nav.Link>
              </Col>
            </Nav>
            <hr></hr>
          </div>

          <div id="item-sympthoms">
            <Nav.Link
              id="activeDashSym"
              className="Menu-Item"
              href="/symptoms/dashboard"
            >
              <img
                src={dashboard}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav.Link
              id="activeAnsSym"
              className="Menu-Item"
              href="/symptoms/answers"
            >
              <img
                src={symptoms}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav.Link
              id="activeConfigSym"
              className="Menu-Item"
              href="/symptoms/emails"
            >
              <img
                src={configuration}
                alt="Icono Configuration"
                width="19rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <hr></hr>
          </div>

          <div id="item-configuration">
            <Nav.Link
              id="activeAdmin"
              className="Menu-Item"
              href="/admin/admin-resources-group"
            >
              <img
                src={resourcesGroup}
                alt="Icono Configuración"
                width="26rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav.Link
              id="activeConfig"
              className="Menu-Item"
              onClick= {this.handleLinkStores}
            >
              <img
                src={configuration}
                alt="Icono Configuración"
                width="20rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
            <Nav className="mr-auto"></Nav>
            <hr></hr>
          </div>

          <Nav className="mr-auto">
            <Nav.Link className="Menu-Item" onClick={this.doLogout}>
              <img
                src={logout}
                alt="Icono Logout"
                width="22rem"
                className="Icon-Menu"
              ></img>
            </Nav.Link>
          </Nav>
        </div>
        {/* <Row>
          <Col lg={2}></Col>
          <Col lg={8}>
            <div id="popUpFeedback" className="Pop-Up-Feedback">
              <Row className="Head-Modal">
                <Col lg={1}></Col>
                <Col lg={9} md={9} sm={9} xs={10} >
                  <img
                    src={imgChat}
                    alt="Icono chat"
                    width="40rem"
                    className="icon-chat"
                  ></img> 
                  <p className="text-light Title-Comment"> Comentarios</p> 
                </Col>
                <Col lg={2} md={2} sm={2} xs={1} style={{ paddingTop: "0.5rem"}}>
                  <img
                    src={imgEquis}
                    alt="Icono close"
                    width="25rem"
                    className="Equis"
                    onClick={this.handleClosePopUp}
                  ></img>
                </Col>
              </Row>
              <Row style={{ paddingTop: "1rem"}}>
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="Load">
                    {this.state.loading && <div className="loader"></div>}
                  </div>
                  <div className="Div-Left">
                    <label className="Title-Feedback">¿Cómo te has sentido con la plataforma?</label>
                    <br></br>
                  </div>
                  <div id="InputFeeling">
                    <div id="emoji1" onClick={() => this.handleChangeFeeling("Muy Insatisfecho")} style={{display: "inline-block", borderRadius: "8px"}}>
                      <img
                      data-tip data-for="tooltipVeryUnsatisfied"
                      src={imgAngryFace}
                      alt="Icono Sad Face"
                      width="50rem"
                      className="Equis"
                      ></img>
                    </div>
                    <div id="emoji2" onClick={() => this.handleChangeFeeling("Insatisfecho")} style={{display: "inline-block", borderRadius: "8px"}}>
                      <img
                      data-tip data-for="tooltipUnsatisfied"
                      src={imgSadFace}
                      alt="Icono Sad Face"
                      width="50rem"
                      className="Equis"
                      ></img>
                    </div>
                    <div id="emoji3" onClick={() => this.handleChangeFeeling("Neutral")} style={{display: "inline-block", borderRadius: "8px"}}>
                      <img
                      data-tip data-for="tooltipNeutral"
                      src={imgNeutralFace}
                      alt="Icono Neutral Face"
                      width="50rem"
                      className="Equis"
                      ></img>
                    </div>
                    <div id="emoji4" onClick={() => this.handleChangeFeeling("Satisfecho")} style={{display: "inline-block", borderRadius: "8px"}}>
                      <img
                      data-tip data-for="tooltipSatisfied"
                      src={imgHappyFace}
                      alt="Icono Happy Face"
                      width="50rem"
                      className="Equis"
                      ></img>
                    </div>
                    <div id="emoji5" onClick={() => this.handleChangeFeeling("Muy Satisfecho")} style={{display: "inline-block", borderRadius: "8px"}}>
                      <img
                      data-tip data-for="tooltipVerySatisfied"
                      src={imgLoveFace}
                      alt="Icono Love Face"
                      width="50rem"
                      className="Equis"
                      ></img>
                    </div>
                  <ReactTooltip id="tooltipVeryUnsatisfied" place="bottom" effect="solid">Muy Insatisfecho</ReactTooltip>
                  <ReactTooltip id="tooltipUnsatisfied" place="bottom" effect="solid">Insatisfecho</ReactTooltip>
                  <ReactTooltip id="tooltipNeutral" place="bottom" effect="solid">Neutral</ReactTooltip>
                  <ReactTooltip id="tooltipSatisfied" place="bottom" effect="solid">Satisfecho</ReactTooltip>
                  <ReactTooltip id="tooltipVerySatisfied" place="bottom" effect="solid">Muy Satisfecho</ReactTooltip>

                  </div>
                  
                  <div className="Div-Left">
                    <br></br>
                    <label className="Message-Feedback">Mensaje</label>
                  </div>
                  <textarea
                    className="Input-Message"
                    id="inputMessage"
                    rows="5"
                    onChange={this.handleChangeMessage}
                    value={this.state.text}
                  ></textarea>
                  <br></br>
                  <div className="Div-Center">
                    <Button
                      variant="success"
                      className="Submit-Button-Form"
                      onClick={this.sendFeedback}
                      id="sendButton"
                    >
                      Enviar
                    </Button>
                    <label className="Message" id="message"></label>
                  </div>
                </Col>
                <Col lg={2}></Col>
              </Row>
            </div>
          </Col>
          <Col lg={2}></Col>
        </Row>
        <div className="Notification" id="notificationDiv">
          {this.state.notifications}
        </div> */}
      </div>
    );
  }
}

export default LateralMenu;
