import React from "react";
import { Switch } from "components/switch";
import { ALERT_INFORMATION } from "information-texts/Alerts";

export const Card = ({ children, toggleAlert, type, isChecked }) => {
    const { description, title } = ALERT_INFORMATION[type];

    return (
        <div className={`card card--${isChecked ? "active" : ""}`}>
            <div className="card__header">
                <h3 className="card__title">{title}</h3>
                <Switch checked={isChecked} handleChange={() => toggleAlert(type)} />
            </div>
            <p className="card__description">{description}</p>
            {children}
        </div>
    );
};
