//Import main libraries and components
import React from "react";
import Footer from "../../components/Footer";
import { Row, Col, Button } from "react-bootstrap";
import API from "../../api/Main";

//Import images
import checkImage from "../../imgs/checked-checkbox.png";
import uncheckImage from "../../imgs/unchecked-checkbox.png";
//import imgSymptoms from "../../imgs/bg-symptoms.png";
import imgSuccess from "../../imgs/success.png";

//Import styles
import "../../styles/Sympthoms/formsymptoms.css";

class FormSymptoms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      symptoms: [
        ["Fiebre", 0],
        ["DolorGarganta", 0],
        ["DolorCabeza", 0],
        ["Congestion", 0],
        ["Tos", 0],
        ["DificultadRespirar", 0],
        ["Fatiga", 0],
        ["Escalofrio", 0],
        ["DolorMuscular", 0],
      ],
      covid: [
        ["ViajeI", 0],
        ["ViajeN", 0],
        ["Trabajador", 0],
        ["ContactoCovid", 0],
      ],
      token: this.props.location.search.split("&")[1].split("=")[1],
    };
  }

  changeCheckFiebre = () => {
    this.verifyIsCheckedSymptom("Fiebre");
  };

  changeCheckDolorGarganta = () => {
    this.verifyIsCheckedSymptom("DolorGarganta");
  };

  changeCheckDolorCabeza = () => {
    this.verifyIsCheckedSymptom("DolorCabeza");
  };

  changeCheckCongestion = () => {
    this.verifyIsCheckedSymptom("Congestion");
  };

  changeCheckTos = () => {
    this.verifyIsCheckedSymptom("Tos");
  };

  changeCheckDificultadRespirar = () => {
    this.verifyIsCheckedSymptom("DificultadRespirar");
  };

  changeCheckFatiga = () => {
    this.verifyIsCheckedSymptom("Fatiga");
  };

  changeCheckEscalofrio = () => {
    this.verifyIsCheckedSymptom("Escalofrio");
  };

  changeCheckDolorMuscular = () => {
    this.verifyIsCheckedSymptom("DolorMuscular");
  };

  changeCheckViajeI = () => {
    this.verifyIsCheckedCovid("ViajeI");
  };

  changeCheckViajeN = () => {
    this.verifyIsCheckedCovid("ViajeN");
  };

  changeCheckTrabajador = () => {
    this.verifyIsCheckedCovid("Trabajador");
  };

  changeCheckContactoCovid = () => {
    this.verifyIsCheckedCovid("ContactoCovid");
  };

  verifyIsCheckedSymptom(tempSymptom) {
    let idCheck = "check" + tempSymptom;
    let idUncheck = "uncheck" + tempSymptom;
    for (var i = 0; i < this.state.symptoms.length; i++) {
      if (this.state.symptoms[i][0] === tempSymptom) {
        if (this.state.symptoms[i][1] === 0) {
          document.getElementById(idUncheck).style.display = "none";
          document.getElementById(idCheck).style.display = "";
          let temp = this.state.symptoms;
          temp[i][1] = 1;
          this.setState({ symptoms: temp });
        } else if (this.state.symptoms[i][1] === 1) {
          document.getElementById(idCheck).style.display = "none";
          document.getElementById(idUncheck).style.display = "";
          let temp = this.state.symptoms;
          temp[i][1] = 0;
          this.setState({ symptoms: temp });
        }
      }
    }
  }

  verifyIsCheckedCovid(tempCovid) {
    let idCheck = "check" + tempCovid;
    let idUncheck = "uncheck" + tempCovid;
    for (var i = 0; i < this.state.covid.length; i++) {
      if (this.state.covid[i][0] === tempCovid) {
        if (this.state.covid[i][1] === 0) {
          document.getElementById(idUncheck).style.display = "none";
          document.getElementById(idCheck).style.display = "";
          let temp = this.state.covid;
          temp[i][1] = 1;
          this.setState({ covid: temp });
        } else if (this.state.covid[i][1] === 1) {
          document.getElementById(idCheck).style.display = "none";
          document.getElementById(idUncheck).style.display = "";
          let temp = this.state.covid;
          temp[i][1] = 0;
          this.setState({ covid: temp });
        }
      }
    }
  }

  verifyAllSymptoms() {
    for (var i = 0; i < this.state.symptoms.length; i++) {
      if (this.state.symptoms[i][1] === 0) {
        document.getElementById(
          "check" + this.state.symptoms[i][0]
        ).style.display = "none";
        document.getElementById(
          "uncheck" + this.state.symptoms[i][0]
        ).style.display = "";
      } else if (this.state.symptoms[i][1] === 1) {
        document.getElementById(
          "uncheck" + this.state.symptoms[i][0]
        ).style.display = "none";
        document.getElementById(
          "check" + this.state.symptoms[i][0]
        ).style.display = "";
      }
    }
  }

  verifyAllCovid() {
    for (var i = 0; i < this.state.covid.length; i++) {
      if (this.state.covid[i][1] === 0) {
        document.getElementById(
          "check" + this.state.covid[i][0]
        ).style.display = "none";
        document.getElementById(
          "uncheck" + this.state.covid[i][0]
        ).style.display = "";
      } else if (this.state.covid[i][1] === 1) {
        document.getElementById(
          "uncheck" + this.state.covid[i][0]
        ).style.display = "none";
        document.getElementById(
          "check" + this.state.covid[i][0]
        ).style.display = "";
      }
    }
  }

  handleButtonClickSend = () => {
    this.setState({ loading: true });
    document.getElementById("bodyForm").style.filter = "blur(5px)";
    let data = {
      data: {
        timestamp: new Date().toString(),
        symptoms: this.state.symptoms,
        covid: this.state.covid,
      },
    };
    API.postForm(
      "https://dot",
      "/symptom/save",
      this.state.token,
      JSON.stringify(data)
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            document.getElementById("popUpSuccess").style.display = "";
            this.setState({ loading: false });
          });
        } else {
          console.log("Ha ocurrido un error ):");
        }
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    document.title = "Switch AI | Formulario síntomas";
    this.verifyAllSymptoms();
    this.verifyAllCovid();
    document.getElementById("popUpSuccess").style.display = "none";
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Load">
            {this.state.loading && <div className="loader"></div>}
          </div>

          <div id="popUpSuccess" className="Pop-Up-Success">
            <br></br>
            <img src={imgSuccess} alt="Icono success" width="100rem"></img>
            <br></br>
            <label className="Title-Pop-Up">Envío exitoso</label>
          </div>
          <div className="Body-Form" id="bodyForm">
            <Row>
              <Col lg={12}>
                <div className="Div-Center-Title">
                  <label className="Title-Form-Symptoms">
                    Formulario de Registro de Síntomas
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}></Col>
              <Col lg={6}>
                <div className="Card-Layout-Form-Symptoms">
                  <label className="Title-Question">
                    1. Por favor selecciona los síntomas que presentes*
                  </label>
                  <label className="Text-Advice">
                    *Si no seleccionas ninguno se tomará tu respuesta como
                    ninguno de los anteriores
                  </label>
                  <br></br>
                  <div>
                    <img
                      className="Check"
                      id="uncheckFiebre"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckFiebre}
                    ></img>
                    <img
                      className="Check"
                      id="checkFiebre"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckFiebre}
                    ></img>
                    <label className="Text-Symptom">Fiebre</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckDolorGarganta"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorGarganta}
                    ></img>
                    <img
                      className="Check"
                      id="checkDolorGarganta"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorGarganta}
                    ></img>
                    <label className="Text-Symptom">Dolor de garganta</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckDolorCabeza"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorCabeza}
                    ></img>
                    <img
                      className="Check"
                      id="checkDolorCabeza"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorCabeza}
                    ></img>
                    <label className="Text-Symptom">Dolor de cabeza</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckCongestion"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckCongestion}
                    ></img>
                    <img
                      className="Check"
                      id="checkCongestion"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckCongestion}
                    ></img>
                    <label className="Text-Symptom">Congestión nasal</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckTos"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckTos}
                    ></img>
                    <img
                      className="Check"
                      id="checkTos"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckTos}
                    ></img>
                    <label className="Text-Symptom">Tos</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckDificultadRespirar"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDificultadRespirar}
                    ></img>
                    <img
                      className="Check"
                      id="checkDificultadRespirar"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDificultadRespirar}
                    ></img>
                    <label className="Text-Symptom">
                      Dificultad para respirar
                    </label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckFatiga"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckFatiga}
                    ></img>
                    <img
                      className="Check"
                      id="checkFatiga"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckFatiga}
                    ></img>
                    <label className="Text-Symptom">Fatiga</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckEscalofrio"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckEscalofrio}
                    ></img>
                    <img
                      className="Check"
                      id="checkEscalofrio"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckEscalofrio}
                    ></img>
                    <label className="Text-Symptom">Escalofrío</label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckDolorMuscular"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorMuscular}
                    ></img>
                    <img
                      className="Check"
                      id="checkDolorMuscular"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckDolorMuscular}
                    ></img>
                    <label className="Text-Symptom">Dolor muscular</label>
                    <br></br>
                  </div>
                </div>
                <br></br>

                <div className="Card-Layout-Form-Covid">
                  <label className="Title-Question">
                    2. Elige las opciones que apliquen en tu caso (puedes marcar
                    varias)
                  </label>
                  <label className="Text-Advice">
                    *Si no seleccionas ninguno se tomará tu respuesta como
                    ninguna de las anteriores
                  </label>
                  <div>
                    <img
                      className="Check"
                      id="uncheckViajeI"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckViajeI}
                    ></img>
                    <img
                      className="Check"
                      id="checkViajeI"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckViajeI}
                    ></img>
                    <label className="Text-Symptom">
                      Hice un viaje internacional en los últimos 30 días
                    </label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckViajeN"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckViajeN}
                    ></img>
                    <img
                      className="Check"
                      id="checkViajeN"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckViajeN}
                    ></img>
                    <label className="Text-Symptom">
                      Hice un viaje nacional en los últimos 30 días
                    </label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckTrabajador"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckTrabajador}
                    ></img>
                    <img
                      className="Check"
                      id="checkTrabajador"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckTrabajador}
                    ></img>
                    <label className="Text-Symptom">
                      Soy trabajador de salud
                    </label>
                    <br></br>
                  </div>

                  <div>
                    <img
                      className="Check"
                      id="uncheckContactoCovid"
                      src={uncheckImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckContactoCovid}
                    ></img>
                    <img
                      className="Check"
                      id="checkContactoCovid"
                      src={checkImage}
                      alt="Checkbox"
                      width="30rem"
                      onClick={this.changeCheckContactoCovid}
                    ></img>
                    <label className="Text-Symptom">
                      He estado en contacto en los últimos 14 días con alguna
                      persona diagnosticada con Covid-19
                    </label>
                    <br></br>
                  </div>
                </div>
                <div className="Div-Center-Title">
                  <Button
                    variant="success"
                    className="Submit-Button-Form"
                    onClick={this.handleButtonClickSend}
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
              <Col lg={3}></Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default FormSymptoms;
