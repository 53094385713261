import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { calendarProps, formatEvents, getEventDates, EventModal, MODALS, DEFAULT_EVENT, SWAL_PROPS, DURATION_OPTIONS } from ".";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import API from "api/Main";
import { URLS } from "api/Urls";
import { addDays } from "utils/Date";
import { ONE_DAY_EVENT_TIME } from "constants/Date";
import { ChartsModal, createCameraOptions } from "..";
import "./Calendar.scss";

export const Calendar = ({ cameras }) => {
    const [event, setEvent] = useState(DEFAULT_EVENT);
    const [events, setEvents] = useState([]);
    const [activeModal, setActiveModal] = useState("");
    const cameraOptions = useMemo(() => createCameraOptions(cameras), [cameras]);

    const getEvents = useCallback(async () => {
        const response = await API.get("https://dot", URLS.calendar.get);
        if (response.ok) setEvents(formatEvents(await response.json()));
    }, []);

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    const createOrUpdateEvent = async () => {
        const newEvent = JSON.stringify({
            ...event,
            ...getEventDates(event),
            subscription_group_ids: event.cameras.map(({ id }) => id),
            name: event.title,
        });

        const response = await API.post("https://dot", event.edit ? URLS.calendar.update : URLS.calendar.post, newEvent);
        if (response.ok) {
            Swal.fire({ ...SWAL_PROPS, title: `Evento ${event.edit ? "editado" : "creado"} correctamente` });
            toggleModal();
            getEvents();
            return setEvent(DEFAULT_EVENT);
        }
        toggleModal();
    };

    const deleteEvent = async () => {
        const response = await API.delete("https://dot", URLS.calendar.delete(event.id));
        if (response.ok) {
            Swal.fire({ ...SWAL_PROPS, title: "Evento eliminado correctamente" });
            toggleModal();
            getEvents();
        }
    };

    const handleEventChange = ({ target: { name, value } }) => setEvent({ ...event, [name]: value });

    const selectEvent = event => {
        toggleModal(MODALS.FORM);
        if (event.event) {
            const { id, start, end, allDay } = event.event;
            const selectedEvent = events.find(item => item?.id === id);
            const duration = DURATION_OPTIONS[new Date(selectedEvent.end).getMinutes() === ONE_DAY_EVENT_TIME ? 0 : 1];
            const cameras = cameraOptions.filter(({ id }) => selectedEvent?.cameras.includes(id));
            return setEvent({ ...selectedEvent, allDay, startStr: start, endStr: end, edit: true, cameras, duration });
        }

        setEvent({ ...DEFAULT_EVENT, id: uuid(), end: addDays(event.end, -1), start: event.start });
    };

    const toggleModal = (modal = "") => setActiveModal(modal);

    return (
        <div className="calendar">
            <h2 className="calendar__title">Cronograma</h2>
            <FullCalendar
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                select={selectEvent}
                eventClick={selectEvent}
                eventChange={selectEvent}
                events={events}
                {...calendarProps}
            />
            {activeModal === MODALS.FORM && (
                <EventModal
                    cameraOptions={cameraOptions}
                    deleteEvent={deleteEvent}
                    event={event}
                    setEvent={setEvent}
                    handleEventChange={handleEventChange}
                    saveEvent={createOrUpdateEvent}
                    toggleForm={toggleModal}
                />
            )}
            {activeModal === MODALS.CHARTS && <ChartsModal cameras={cameras} event={event} toggleForm={toggleModal} />}
        </div>
    );
};
