//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import { Row, Col, Button, Dropdown, Modal, ModalBody} from "react-bootstrap";
//import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";

//Import images
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";

class DashboardCharacterization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameras: [],
      groups: [],
      tempId: "",
      tempName: "",
      genderMore: "",
      genderLess: "",
      barGraphic_2: {},
      loadingStaticGender: false,
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 604800000),
    }
  }

  applyFilterDynamic = () => {
    clearInterval(this.intervalId);
    this.updateDashboard(
      this.state.tempId,
      this.state.tempName,
      true,
      this.state.startDate.getTime(),
      this.state.finishDate.getTime()
    );
  };

  deleteFilterDynamic = () => {
    this.setState({
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 604800000 ),
    });
    this.updateDashboard(
      this.state.tempId,
      this.state.tempName,
      true,
      new Date().getTime(),
      new Date().getTime() - 604800000 
    );
    this.intervalId = setInterval(
      () =>
        this.updateDashboard(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 604800000 
        ),
      300000
    );
  };

  getListCameras() {
    this.setState({ cameras: [], tempId: "", tempName: "" });
    API.get(
      "https://dot",
      "/cameras/1" + "?groupId=" + localStorage.getItem("idGroup")
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length != 0) {
              data.forEach((element) => {
                let item = (
                  <Dropdown.Item
                    onClick={this.handleClickCamera}
                    className="Camera"
                    id={element.id}
                    key={element.id}
                    name={element.name}
                  >
                    Cámara {element.name}
                  </Dropdown.Item>
                );
                this.state.cameras.push(item);
              });
              document.getElementById("buttonLoad").style.display = "none";
              this.setState({
                loading: false,
                tempId: this.state.cameras[0].key,
                tempName: this.state.cameras[0].props.name,
              });
              this.updateDashboard(
                this.state.cameras[0].key,
                this.state.cameras[0].props.name,
                false,
                new Date().getTime(),
                new Date().getTime() - 604800000 
              );

            } else {

            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  getListGroups() {
    API.get("https://dot", "/resource_group")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickGroup}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  Grupo {element.name}
                </Dropdown.Item>
              );
              this.state.groups.push(item);
            });
            document.getElementById("buttonLoad").style.display = "none";
            this.setState({
              loading: false,
            });
            localStorage.setItem("idGroup", this.state.groups[0].key);
            localStorage.setItem("nameGroup", this.state.groups[0].props.name);

            this.getListCameras();
            //this.getAforo();

            this.updateDashboard(
              localStorage.getItem("idGroup"),
              localStorage.getItem("nameGroup"),
              false,
              new Date().getTime(),
              new Date().getTime() - 604800000
            );
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  handleClickCamera = (e) => {
    this.setState({
      loadingDynamicImgZones: false,
      loadingDynamicZones: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    this.updateDashboard(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 604800000 
    );
  };

  handleClickGroup = (e) => {
    localStorage.setItem("idGroup", e.target.id);
    localStorage.setItem("nameGroup", e.target.name);
    this.getListCameras();
    this.updateDashboard(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 604800000 
    );
    this.setState({ capacity: "0" });
  };

  updateDashboard(id, name, isUpdate, startDate, finishDate) {
    //this.hideObjects();
    //this.showUpdates(); 
    this.showGraphics(id, name, isUpdate, startDate, finishDate);
    //this.showHeatMap(id);
  }

  showGraphics(id, name, isUpdate, startDate, finishDate) {
    let arrayYGenderW = [];
    let arrayYGenderM = [];
    let arrayYGenderG = [];
    let arrayYGenderB = [];
    let valuesNames = ["Mujeres", "Hombres", "Niños", "Niñas"];
    let valuesData = []; 
    let configStateBar_2;

    this.setState({
      loadingStaticGender: true,
    });

    API.get(
      "https://dot",
      "/analytics/gender" + "?start=" + finishDate + "&end=" + startDate)
      .then((response) => {

        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              console.log("data gender", data);
              valuesData = [data.women, data.men, data.boys, data.girls];

              document.getElementById("chartBarGender").style.display = "";
              document.getElementById("loadingBarChartGender").style.display =
                "none";

              arrayYGenderW.push({
                x: 0,
                y: data.women,
              });
              arrayYGenderM.push({
                x: 1,
                y: data.men,
              });
              arrayYGenderB.push({
                x: 2,
                y: data.boys,
              });
              arrayYGenderG.push({
                x: 3,
                y: data.girls,
              });

            } else {
              document.getElementById("chartBarGender").style.display = "none";
              document.getElementById("loadingBarChartGender").style.display = "";
            }
            let Mayor = 0;
            let Menor =  valuesData[0]; 
            let PositionMayor;
            let PositionMenor;

            for(let i=0; i<valuesData.length; i++){
                
              if(valuesData[i] > Mayor){
                  Mayor = valuesData[i];
                  PositionMayor = i;
                }
                if(valuesData[i]< Menor){
                  PositionMenor = i;
                }
            }
            let peopleMoreTraffic = valuesNames[PositionMayor];
            let peopleLessTraffic = valuesNames[PositionMenor];
            
            this.setState({ genderMore: peopleMoreTraffic, genderLess: peopleLessTraffic });

            configStateBar_2 = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Histórico de género",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 30,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: [this.state.finishDate.toLocaleTimeString() + " " + this.state.finishDate.toLocaleDateString() + " - " + this.state.startDate.toLocaleTimeString() + " " + this.state.startDate.toLocaleDateString() ],
                datasets: [
                  {
                    label: ["Mujeres"],
                    data: arrayYGenderW,
                    backgroundColor: "#1A3D5A",
                    borderColor: "#1A3D5A",
                    borderWidth: 3,
                  },
                  {
                    label: ["Hombres"],
                    data: arrayYGenderM,
                    backgroundColor: "#606060",
                    borderColor: "#606060",
                    borderWidth: 3,
                  },
                  {
                    label: ["Niños"],
                    data: arrayYGenderB,
                    backgroundColor: "#86ac36",
                    borderColor: "#86ac36",
                    borderWidth: 3,
                  }, {
                    label: ["Niñas"],
                    data: arrayYGenderG,
                    backgroundColor: "#DCDCDC",
                    borderColor: "#DCDCDC",
                    borderWidth: 3,
                  },
                ],
              },
            };

            let bar_2 = document.getElementById("chartBarGender").getContext("2d");
            let chartBarGender = new Chart(bar_2, configStateBar_2);

            this.setState({
              barGraphic_2: chartBarGender,
              loadingStaticGender: false,
            });

            /* if (isUpdate) {
              let tmpBar = this.state.barGraphic_2;
              tmpBar.data.labels = configStateBar_2.data.labels;
              tmpBar.data.datasets = configStateBar_2.data.datasets;
              tmpBar.update();
              this.setState({
                barGraphic_2: tmpBar,
                loadingStaticGender: false,
              });
            } else {

              let bar_2 = document.getElementById("chartBarGender").getContext("2d");
              let chartBarGender = new Chart(bar_2, configStateBar_2);

              this.setState({
                barGraphic_2: chartBarGender,
                loadingStaticGender: false,
              });
            } */
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));  
  }

  hideObjects() {
    document.getElementById("loadingBarChartGender").style.display = "none";
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard";
    this.getListGroups();
    this.hideObjects();
    this.updateDashboard(
      localStorage.getItem("idGroup"),
      localStorage.getItem("nameGroup"),
      true,
      new Date().getTime(),
      new Date().getTime() - 604800000
    )
    document.getElementById("staticDashboard").style.display = "";
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <div>
                    <div className="Body-Dashboard" id="Body-Dashboard">
                      <div id="staticDashboard">
                        <Row>
                          <Col lg={12}>
                            <div className="form-inline">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                  className="Camera-Button"
                                >
                                Grupo {localStorage.getItem("nameGroup")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.groups}
                                </Dropdown.Menu>
                              </Dropdown>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                  className="Camera-Button"
                                >
                                  Cámara {this.state.tempName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.cameras}
                                </Dropdown.Menu>
                              </Dropdown>
                                <Button
                                  id="buttonLoad"
                                  variant="success"
                                  className="Button-Success-Load-More"
                                >
                                  Cargar más
                                </Button>
                                        <div className="Div-Date">
                                            <DatePicker
                                            className="Date-Picker"
                                            selected={this.state.finishDate}
                                            onChange={(date) =>
                                                this.setState({ finishDate: date })
                                            }
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            timeCaption="Hora"
                                            dateFormat="yyyy/MM/dd h:mm aa"
                                            />
                                            <DatePicker
                                            className="Date-Picker"
                                            selected={this.state.startDate}
                                            onChange={(date) =>
                                                this.setState({ startDate: date })
                                            }
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            timeCaption="Hora"
                                            dateFormat="yyyy/MM/dd h:mm aa"
                                            />
                                        </div>
                                        <Button
                                            variant="success"
                                            className="Button-Apply-Filter"
                                            onClick={this.applyFilterDynamic}
                                        >
                                            Aplicar
                                        </Button>
                                        <Button
                                            variant="dark"
                                            className="Button-Delete-Filter"
                                            onClick={this.deleteFilterDynamic}
                                        >
                                            Eliminar
                                        </Button>
                                        </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                           <div className="Container-Layout">
                              <div className="Load">
                                {this.state.loadingStaticGender && (
                                  <div className="loader"></div>
                                )}
                              </div>
                              <canvas id="chartBarGender"></canvas>
                              <div
                                id="loadingBarChartGender"
                                className="Div-Center"
                              >
                                <label className="Title-Message">
                                  Histórico de género
                                </label>
                                <br></br>
                                <br></br>
                                <img
                                  src={working}
                                  width="80rem"
                                  alt="Imagen de Loading"
                                ></img>
                                <br></br>
                                <br></br>
                                <label className="Text-Message">
                                  Estamos procesando tus datos
                                </label>
                                <br></br>
                                <label className="Text-Message">
                                  Pronto tendremos estadísticas para ti
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                                <Row>
                                  <Col lg={10} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "}Género que más visito la tienda</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.genderMore}</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                </Row>
                          <Row>
                            <Col lg={10} className="pt-4 pl-4">
                               <Row style={{margin:"auto"}}>
                                  <Col lg={10} className="Card-insight">
                                    <Row style={{minHeight: "7rem"}}>
                                      <Col lg={12} className="mx-2">
                                        <Row className="pt-3">
                                          <label className="label-Option-Dashboard">{" "}Género que menos visito la tienda</label>
                                        </Row>
                                        <Row>
                                          <div style={{ margin: "auto"}}>
                                            <label className="Insight-Home">{this.state.genderLess}</label>
                                          </div> 
                                        </Row>
                                      </Col>  
                                    </Row>            
                                  </Col>
                                </Row>   
                            </Col>
                          </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default DashboardCharacterization;