export { default } from "./Schedule";
export * from "./components";

/**
 * Static props of the calendar
 */
export const calendarProps = {
    initialView: "dayGridMonth",
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listYear",
    },
    locale: "es",
    longPressDelay: 1000,
    eventLongPressDelay: 1000,
    selectLongPressDelay: 1000,
    selectable: true,
    dayMaxEvents: true,
    editable: true,
    allDaySlot: false,
    buttonText: {
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
        list: "Lista",
    },
    views: {
        dayGridMonth: {
            buttonText: "Mes",
            eventDisplay: "list-item",
        },
        timeGridWeek: {
            buttonText: "Semana",
        },
        timeGridDay: {
            buttonText: "Día",
        },
        listYear: {
            buttonText: "Año",
            listYearFormat: "YYYY",
        },
    },
};

export const ALL_ACCESS = "Todos los accesos";

export const createCameraOptions = cameras => [
    {
        id: "123123",
        value: ALL_ACCESS,
        label: ALL_ACCESS,
        name: ALL_ACCESS,
    },
    ...cameras.map(camera => ({ ...camera, label: camera.name, value: camera.name })),
];
