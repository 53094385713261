//Import main libraries and components
import React from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import API from "../../api/Main";
//import NavMenuBar from "../../components/NavMenuBar";
import NavMenuBar from "../../components/NavMenuBar";
import Cookies from "universal-cookie";
import Footer from "../../components/Footer";

//Import styles
import "../../styles/Biosecurity/cameras.css";

class Cameras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
      items: [],
      cameras: [],
      loading: true,
    };
  }

  handleClickPag = (item) => {
    this.doPagination(parseInt(item.target.innerText));
  };

  handleClickEvent = (e) => {
    localStorage.setItem("CameraID", e.target.id);
    localStorage.setItem("CameraName", e.target.name);
    this.props.history.push("/biodot/cameras/events/" + e.target.id);
  };

  doPagination(itemToActive) {
    const cookies = new Cookies();
    let paginationTemp = [];
    for (let number = 1; number <= cookies.get("pagination"); number++) {
      paginationTemp.push(
        <Pagination.Item
          key={number}
          active={number === itemToActive}
          onClick={this.handleClickPag}
        >
          {number}
        </Pagination.Item>
      );
    }
    this.getCameras(itemToActive, paginationTemp);
  }

  async getResourceGroup() {
    try {
      const request = await API.get("https://dot", "/resource_group");

      const data = await request.json();
      return data[0].id;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCameras(activePage, paginationTemp) {
    const cookies = new Cookies();
    let role = cookies.get("role");

    if (role === "owner") {
      const idGroup = await this.getResourceGroup();
      try {
        const request = await API.get(
          "https://dot",
          "/cameras/1" + "?groupId=" + idGroup
        );
        const data = await request.json();

        let camerasTemp = [];

        data.forEach((element) => {
          const popover = (
            <Popover id="popover-basic" key={element.id}>
              <Popover.Title as="h3" className="Title-Card">
                Cámara {element.name}
              </Popover.Title>
              <Popover.Content className="Content-Popover">
                <label className="Title-Content">Fecha de actualización:</label>
                <label className="Text-Content">{element.update_date}</label>
                <label className="Title-Content">Descripción: </label>
                <label className="Text-Content">{element.description}</label>
                <br></br>
                <label className="Title-Content">Localización: </label>
                <label className="Text-Content">
                  {element.localization.x} {element.localization.y}
                </label>
                <div className="Div-Center">
                  <Button
                    name={element.name}
                    id={element.id}
                    variant="success"
                    className="Events-Button"
                    onClick={this.handleClickEvent}
                  >
                    Ver todos los eventos
                  </Button>
                </div>
              </Popover.Content>
            </Popover>
          );
          const overlay = (
            <OverlayTrigger
              trigger="click"
              rootClose={true}
              placement="bottom"
              overlay={popover}
              key={element.id}
            >
              <Button variant="light" className="Camera-Element">
                Cámara {element.name}
                <br></br>
                <img src={element.thumbnail} alt="evento" width="240rem"></img>
              </Button>
            </OverlayTrigger>
          );
          camerasTemp.push(overlay);
        });
        this.setState({
          cameras: camerasTemp,
          items: paginationTemp,
          active: activePage,
          loading: false,
        });
      } catch (error) {
        throw new Error(error);
      }
    } else {
      API.get("https://dot", "/cameras/" + activePage)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              let camerasTemp = [];
              data.forEach((element) => {
                const popover = (
                  <Popover id="popover-basic" key={element.id}>
                    <Popover.Title as="h3" className="Title-Card">
                      Cámara {element.name}
                    </Popover.Title>
                    <Popover.Content className="Content-Popover">
                      <label className="Title-Content">
                        Fecha de actualización:
                      </label>
                      <label className="Text-Content">
                        {element.update_date}
                      </label>
                      <label className="Title-Content">Descripción: </label>
                      <label className="Text-Content">
                        {element.description}
                      </label>
                      <br></br>
                      <label className="Title-Content">Localización: </label>
                      <label className="Text-Content">
                        {element.localization.x} {element.localization.y}
                      </label>
                      <div className="Div-Center">
                        <Button
                          name={element.name}
                          id={element.id}
                          variant="success"
                          className="Events-Button"
                          onClick={this.handleClickEvent}
                        >
                          Ver todos los eventos
                        </Button>
                      </div>
                    </Popover.Content>
                  </Popover>
                );
                const overlay = (
                  <OverlayTrigger
                    trigger="click"
                    rootClose={true}
                    placement="bottom"
                    overlay={popover}
                    key={element.id}
                  >
                    <Button variant="light" className="Camera-Element">
                      Cámara {element.name}
                      <br></br>
                      <img
                        src={element.thumbnail}
                        alt="evento"
                        width="240rem"
                      ></img>
                    </Button>
                  </OverlayTrigger>
                );
                camerasTemp.push(overlay);
              });
              this.setState({
                cameras: camerasTemp,
                items: paginationTemp,
                active: activePage,
                loading: false,
              });
            });
          } else {
            console.log("Error" + response);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  componentDidMount() {
    document.title = "Switch AI | Cámaras";
    this.doPagination(this.state.active);
    this.styleToActive();
    this.intervalId = setInterval(
      () => this.doPagination(this.state.active),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  styleToActive() {
   /*  document.getElementById("activeCamsBio").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeCamsBio").style.borderStyle = "dashed";
    document.getElementById("activeCamsBio").style.borderColor = "#84ac34";
    document.getElementById("activeCamsBio").style.borderRadius = "1rem";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none"; */
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Cameras">
            <div className="Loading">
              {this.state.loading && (
                <div className="lds-grid">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <div className="Div-Center">
                  <label className="Title-Card">Cámaras conectadas</label>
                  <div id="camaras">{this.state.cameras}</div>
                </div>
              </Col>
              <Col lg={1}></Col>
            </Row>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <br></br>
                <label className="Title-Content">Paginación</label>
                <Pagination>{this.state.items}</Pagination>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Cameras;
