//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import { Row, Col, Button, Dropdown, Modal, ModalBody} from "react-bootstrap";
//import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";

//Import images
import iconHand from "../../imgs/manita.png";
import working from "../../imgs/Recurso10.png";
import dashboard from "../../imgs/dashboard.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";

class DashboardBehaviour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        cameras: [],
        groups: [],
        tempId: "",
        tempName: "",
        capacity: "",
        timeCamera: 0,
        timeMin: 0,
        timeMax: 0,
        MoreDirection: 0,
        LessDirection: 0,
        dayFilter: 7,
        loadingDynamicImgZones1: false,
        loadingDynamicImgZones2: false,
        loadingDynamicImgZones3: false,
        loadingDynamicZones: false,
        loadingStaticInLine: false,
        loadingTime : false,
        showModalCustomFilter: false,
        startDateDynamic: new Date(),
        DateHeatMap: new Date(),

        finishDateDynamic: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
    }
  }

  handleClickCamera = (e) => {
    this.setState({
      loadingDynamicImgZones1: false,
      loadingDynamicImgZones2: false,
      loadingDynamicImgZones3: false,
      loadingDynamicZones: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    
    var myCurrentDate=new Date();
    var myPastDate=new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 1);

    let startDate = myPastDate.toLocaleDateString("en-US") + " 00:00:00"
    let EndDate = myPastDate.toLocaleDateString("en-US") + " 23:59:59"
    this.updateDashboardHeatMap(
      this.state.cameras[0].key,
      this.state.cameras[0].props.name,
      false,
      startDate,
      EndDate 
    );
    this.updateDashboardTime(
      this.state.cameras[0].key,
      this.state.cameras[0].props.name,
      false,
      new Date().getTime(),
      new Date().getTime() - (7 * 24 * 60 * 60 * 1000) 
    );
  }; 

  handleClickGroup = (e) => {
    localStorage.setItem("idGroup", e.target.id);
    localStorage.setItem("nameGroup", e.target.name);
    this.getListCameras();
    
  };
  
  applyFilterDynamic = () => {
    clearInterval(this.intervalId);
    
    this.updateDashboardTime(
      this.state.tempId,
      localStorage.getItem("idGroup"),
      true,
      this.state.startDateDynamic,
      this.state.finishDateDynamic.getTime(),
    );

    this.setState({ showModalCustomFilter: false});
  };


  applyFilterHeatMap= () => {
    
    let startDate = this.state.DateHeatMap.toLocaleDateString("en-US") + " 00:00:00"
    let EndDate = this.state.DateHeatMap.toLocaleDateString("en-US") + " 23:59:59"

    this.updateDashboardHeatMap(
      this.state.tempId,
      localStorage.getItem("nameGroup"),
      false,
      startDate,
      EndDate, 
    );


  };

  deleteFilterDynamic = () => {
    this.setState({
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000) ),
    });
    this.updateDashboardTime(
      this.state.tempId,
      this.state.tempName,
      true,
      new Date().getTime(),
      new Date().getTime() - (7 * 24 * 60 * 60 * 1000) 
    );

  };

  updateDashboardHeatMap(id, name, isUpdate, startDate, finishDate) {
     this.showHeatMap(id, startDate, finishDate);
  }

  updateDashboardTime(id, name, isUpdate, startDate, finishDate) {
    this.showGraphics(id, name, isUpdate, startDate, finishDate);
  }

  async showUpdates() {
    try {
      const res = await API.get("https://dot", "/general");
      let js = await res.json();
      let arry = [];
      if (!Object.keys(js).includes("message")) {
        js.forEach((element) => {
          const url = element.url_tuto;
          arry.push(
            <div className="Card">
              <div className="contain_update" key={js.indexOf(element)}>
                <label className="Title-Update">{element.description} </label>
                <label className="Text-Update">
                  <img src={iconHand} alt="Icono noticia" width="26rem"></img>{" "}
                  <a
                    className="Link-Update"
                    href={"https://www." + url}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {element.url_tuto}
                  </a>
                </label>
              </div>
            </div>
          );
        });
      } else {
        arry.push(
          <div className="contain_update-none">
            <strong>{js.message}</strong>
          </div>
        );
      }
      this.setState({
        updates: arry,
      });
    } catch (error) {
      return error;
    }
  }

  getListCameras() {
    this.setState({ cameras: [], tempId: "", tempName: "" });
    API.get(
      "https://dot",
      "/cameras/1" + "?groupId=" + localStorage.getItem("idGroup")
    )
      .then((response) => {
        
        if (response.ok) {
          response.json().then((data) => {
            if (data.length != 0) {
              data.forEach((element) => {
                let item = (
                  <Dropdown.Item
                    onClick={this.handleClickCamera}
                    className="Camera"
                    id={element.id}
                    key={element.id}
                    name={element.name}
                  >
                    Cámara {element.name}
                  </Dropdown.Item>
                );
                this.state.cameras.push(item);
              });
              
              var myCurrentDate=new Date();
              var myPastDate=new Date(myCurrentDate);
              myPastDate.setDate(myPastDate.getDate() - 1);

              this.setState({
                loading: false,
                tempId: this.state.cameras[0].key,
                tempName: this.state.cameras[0].props.name,
                DateHeatMap:myPastDate,
              });

              let startDate = myPastDate.toLocaleDateString("en-US") + " 00:00:00"
              let EndDate = myPastDate.toLocaleDateString("en-US") + " 23:59:59"
              this.updateDashboardHeatMap(
                this.state.cameras[0].key,
                this.state.cameras[0].props.name,
                false,
                startDate,
                EndDate 
              );

    
              this.updateDashboardTime(
                this.state.cameras[0].key,
                this.state.cameras[0].props.name,
                false,
                new Date().getTime(),
                new Date().getTime() - (7 * 24 * 60 * 60 * 1000) 
              );
             

            } 
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  showGraphics(id, name, isUpdate, startDate, finishDate) {
    
    let arrayXDateChartLine = [];
    let arrayYOutChartLine = [];
    let arrayYInChartLine = [];
    let arrayXDateZones = [];
    let configStateLine;
    let configStateLine_2;
    let datasetsZone = [];
    let startDateZones = new Date(startDate);
    let finishDateZones = new Date(finishDate);
    let arrayDates = [];
    let startDateFormat = `${startDateZones.getFullYear()}-${startDateZones.getMonth()+1}-${startDateZones.getDate()}`;
    let finishDateFormat = `${finishDateZones.getFullYear()}-${finishDateZones.getMonth()+1}-${finishDateZones.getDate()}`;
    
    this.setState({ loadingDynamicZones: true, loadingStaticInLine: true, loadingTime: true});

    
    API.get(
      "https://dot",
      "/analytics/resume-zones/" +
        id +
        "?groupId=" +
        localStorage.getItem("idGroup") +
        "&start=" + finishDateFormat +
        "&end=" + startDateFormat)

      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            
            if (data.length !== 0 ) {
              //document.getElementById("chartLineZones").style.display = "";
              //document.getElementById("loadingLineZones").style.display ="none";
              document.getElementById("noZonesText").style.display= "none";
              
           
              var bBandera = true;
              for (let i = 0; i < data.length; i++) { 
                if ( typeof data[i] !== 'undefined' && data[i].zones ){
                  
                  let zonesNames = Object.keys(data[i].zones);
                  let zonesObject = {};

                  zonesNames.forEach((zone) => {

                    zonesObject[zone] = [];
                    data.forEach((element) => {
                      
                      if ( typeof element.zones !== 'undefined' && element.zones ){
                        
                        zonesObject[zone].push({
                          x: data.indexOf(element),
                          y: element.zones[zone],
                        });
                      }
                      
                      
                    });
                  });
    

                    data.forEach((element) => {
                      if ( typeof element.zones !== 'undefined' && element.zones ){
                        arrayXDateZones.push(element.fecha);
                      }
                      
                    });

                    for(let i=0; i<arrayXDateZones.length; i++){
                      var fechaEjeX = new Date(arrayXDateZones[i]);
                      var fechaFinal = `${fechaEjeX.getFullYear()}-${fechaEjeX.getMonth()+1}-${fechaEjeX.getDate()}`;
                      arrayDates.push(fechaFinal);
                      
                    } 

                    datasetsZone = [];
                    let arrayColors = ["#BEF74A", "#A0B96F", "#D6F794", "#C6F763"];
                    let counter = 0;

                    zonesNames.forEach((zone) => {
                      datasetsZone.push({
                        label: [zone],
                        data: zonesObject[zone],
                        backgroundColor: ["rgb(255, 255, 255, 0)"],
                        borderColor: arrayColors[counter],
                        pointStyle: "star",
                        pointHoverBorderWidth: 10,
                      });
                      counter++;
                    });

                    var bBandera = false;
                    break;

                }

              }
              //console.log("Chequeo", data.some(e => e.zones.hasOwnProperty("zones") ))
              if(bBandera){
                document.getElementById("noZonesText").style.display="";
              }

            } else {
              
              /*document.getElementById("chartLineZones").style.display = "none";
              document.getElementById("loadingLineZones").style.display = "";*/
            } 

            configStateLine_2 = {
              type: "line",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Aforo por zona",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayDates,
                datasets: datasetsZone,
              },
            };

            
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));

      let arrayDirection = [];
      let arrayDirection_2 = [];
      let counterRight = 0;
      let counterLeft = 0;
      let counterUp = 0;
      let counterDown = 0;
      let counterUpRight = 0;
      let counterDownRight = 0;
      let counterUpLeft = 0;
      let counterDownLeft = 0;
      let counterNoDeterminated = 0;

      let TimeZone = 0;
      let CounterTime = 0;
      let TimeAverage = 0;
      let maxTime;
      let minTime;
      let arrayTimer = [];
      let Direction = [];

      API.get(
        "https://dot",
        "/analytics/journey/" + 
        id +
        "?groupId=" +
        localStorage.getItem("idGroup") +
        "&start=" + finishDateFormat +
        "&end=" + startDateFormat
      )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {

            if (data.length !== 0) {
              document.getElementById("chartLineIn").style.display = "";
              document.getElementById("loadingLineIn").style.display = "none";

              data.forEach((element) =>{
                
                TimeZone += element.timer;
                CounterTime ++;
                arrayTimer.push(element.timer);

                arrayDirection.push(element.direction);

                if(element.direction === "Derecha "){
                  counterRight ++;

                }else if(element.direction === "Izquierda "){
                  counterLeft ++;

                }else if(element.direction === "arriba"){
                  counterUp ++;

                }else if(element.direction ===  "abajo"){
                  counterDown ++;

                }else if(element.direction === "Derecha arriba"){
                  counterUpRight ++;

                }else if(element.direction === "Derecha abajo"){
                  counterDownRight ++;

                }else if(element.direction === "Izquierda arriba"){
                  counterUpLeft ++;

                }else if(element.direction === "Izquierda abajo"){
                  counterDownLeft ++;

                }else{
                  counterNoDeterminated ++;
                }
              }); 

              arrayDirection_2.push("Arriba", "Abajo", "Izquierda", "Derecha", "Arriba Izquierda", "Arriba Derecha", "Abajo Izquierda", "Abajo Derecha", "No determinado");

              Direction.push(counterUp, counterDown, counterLeft, counterRight, counterUpLeft, counterUpRight, counterDownLeft, counterDownRight, counterNoDeterminated );
              
              /* Tiempo promedio mayor y menor */
              TimeAverage = (TimeZone / CounterTime).toFixed(2);
              maxTime = Math.max.apply(null, arrayTimer).toFixed(2);
              minTime = Math.min.apply(null, arrayTimer).toFixed(2);

              /* Dirección a la que las personas se dirijen menos o más */
              let NumeroMayorPersonas = 0;
              let positionMaximo = 0;
              let positionMinimo = 0;
              let positionInicial = Direction[0];

              for(let i=0; i<Direction.length; i++){

                if(Direction[i] > NumeroMayorPersonas){
                  NumeroMayorPersonas = Direction[i];
                  positionMaximo = i;
                }
                if(Direction[i]< positionInicial){
                  positionMinimo = i;
                }
              }
              let MorePeopleDirection = arrayDirection_2[positionMaximo];
              let LessPeopleDirection = arrayDirection_2[positionMinimo];


              this.setState({ timeCamera: TimeAverage, timeMin: minTime, timeMax: maxTime, MoreDirection: MorePeopleDirection, LessDirection: LessPeopleDirection });

            } else {
              document.getElementById("chartLineIn").style.display = "none";
              document.getElementById("loadingLineIn").style.display = "";
            }

            configStateLine = {
              type: "line",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Dirección",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                      },
                    },
                  ],
                },
              },
              data: {
                //labels: arrayXDateChartLine,
                labels: arrayDirection_2,
                datasets: [
                  {
                    label: ["Número de personas"],
                    data: Direction,
                    backgroundColor: "transparent",
                    borderColor: "#86ac36",
                    pointStyle: "star",
                    pointHoverBorderWidth: 10,
                  },
                ],
              },
            };
            
            let line = document
                .getElementById("chartLineIn")
                .getContext("2d");
              let chartLineIn = new Chart(line, configStateLine);

              this.setState({
                lineGraphic: chartLineIn,
                loadingTime: false,
                loadingStaticInLine: false,
              });

            
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));
  }

  getListGroups() {
    API.get("https://dot", "/resource_group_specific/?model=ML-D7")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickGroup}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  Grupo {element.name}
                </Dropdown.Item>
              );
              this.state.groups.push(item);
            });
            
            this.setState({
              loading: false,
            });
            localStorage.setItem("idGroup", this.state.groups[0].key);
            localStorage.setItem("nameGroup", this.state.groups[0].props.name);

            this.getListCameras();

            
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  changeToZones = () => {
    document.getElementById("dashboardZones").style.display = "";
    document.getElementById("dashboardTime").style.display = "none";
    document.getElementById("dashboardDirection").style.display = "none";
    document.getElementById("buttonZones").style.backgroundColor = "#84AC3490";
    document.getElementById("buttonTime").style.backgroundColor = "#dae0e5"; 
    //document.getElementById("buttonDirection").style.backgroundColor = "#dae0e5"; 
  };

  changeToTime = () => {
    document.getElementById("dashboardZones").style.display = "none";
    document.getElementById("dashboardTime").style.display = "";
    document.getElementById("dashboardDirection").style.display = "none";
    document.getElementById("buttonZones").style.backgroundColor = "#dae0e5";
    document.getElementById("buttonTime").style.backgroundColor = "#84AC3490"; 
    //document.getElementById("buttonDirection").style.backgroundColor = "#dae0e5"; 
  };

  changeToDirection = () => {
    document.getElementById("dashboardZones").style.display = "none";
    document.getElementById("dashboardTime").style.display = "none";
    document.getElementById("dashboardDirection").style.display = "";
    document.getElementById("buttonZones").style.backgroundColor = "#dae0e5";
    document.getElementById("buttonTime").style.backgroundColor = "#dae0e5"; 
    //document.getElementById("buttonDirection").style.backgroundColor = "#84AC3490"; 
  };
  
  async showHeatMap(cameraId, startDate, EndDate) {
    try {
      this.setState({ loadingDynamicImgZones1: true });
      this.setState({ loadingDynamicImgZones2: true });
      this.setState({ loadingDynamicImgZones3: true });
      const api = await API.get(
        "https://dot",
        "/points/find/heatmap/" + cameraId + "?start=" + startDate + "&end=" + EndDate 
      );
      console.log("Tiempo start" + startDate + " tiempo final: " + EndDate)
      const data = await api.json();
      console.log("Ninguno ", data )
      if (data.status === 200) {
        for (var i = 0; i < 3; i++) {
          
          let nCount = i + 1 ;
          let elementLabel = "loadingImg" + nCount ; 

          const element = document.getElementById(elementLabel);
          
          if (data.path[i] !== undefined){
            console.log("El path es definido: ", data.path[i] )
            element.innerHTML = `<div align="center"> <img className="imgHeatMap" src="https://${window.location.hostname}/imgs/${cameraId}/${data.path[i]}" style="max-width:100%;height:auto"> </div>`;
            
          }
          else{
            
            element.innerHTML = `<div align="center">
            <br></br>
            <img
                src=${dashboard}
                width="180rem"
                alt="Imagen de Loading"
                
            ></img>
            <br></br>
            <label className="Text-Message">
                En la fecha actual no tenemos información
            </label>
            
            <label className="Text-Message">
                Pronto tendremos estadísticas para ti
            </label>
            </div>`
          }
          this.setState({ loadingDynamicImgZones1: false });
          this.setState({ loadingDynamicImgZones2: false });
          this.setState({ loadingDynamicImgZones3: false });
        }
      }
      else{
        for (var i = 0; i < 3; i++) {
          
          let nCount = i + 1 ;
          let elementLabel = "loadingImg" + nCount ; 

          const element = document.getElementById(elementLabel);
          element.innerHTML = `<div align="center">
          <br></br>
          <img
              src=${dashboard}
              width="180rem"
              alt="Imagen de Loading"
              
          ></img>
          <br></br>
          <label className="Text-Message">
              En la fecha actual no tenemos información
          </label>
          
          <label className="Text-Message">
              Pronto tendremos estadísticas para ti
          </label>
          </div>`  
        }
     

      }
      this.setState({ loadingDynamicImgZones1: false });
      this.setState({ loadingDynamicImgZones2: false });
      this.setState({ loadingDynamicImgZones3: false });
      
    } catch (error) {
      console.log(error);
    }
  }

  filterchart(e){
    
    if(e == 1){

      let days = 7;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("idGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (7 * 24 * 60 * 60 * 1000),
      );

    }else if(e == 2){
      
      let days = 15;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("idGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (15 * 24 * 60 * 60 * 1000),
      );

    }else if(e == 3){

      let days = 21;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (21 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("idGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (21 * 24 * 60 * 60 * 1000),
      );

    }else if( e == 4){

      let days = 30;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("idGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (30 * 24 * 60 * 60 * 1000),
      );

    }else if( e == 5){

      let days = 90;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (90 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("idGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (90 * 24 * 60 * 60 * 1000),
      );
    }else if(e == 6){

      this.setState({ showModalCustomFilter: true});
    }
  }

  filterchartTime(e){
    
    if(e == 1){

      let days = 7;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("nameGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (7 * 24 * 60 * 60 * 1000),
      );


    }else if(e == 2){
      let days = 15;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("nameGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (15 * 24 * 60 * 60 * 1000),
      );

    }else if(e == 3){

      let days = 21;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (21 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("nameGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (21 * 24 * 60 * 60 * 1000),
      );

    }else if( e == 4){

      let days = 30;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("nameGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (30 * 24 * 60 * 60 * 1000),
      );

    }else if( e == 5){

      let days = 90;
      this.setState({ dayFilter: days, startDateDynamic: new Date(new Date().getTime()), finishDateDynamic: new Date(new Date().getTime() - (90 * 24 * 60 * 60 * 1000))});
      this.updateDashboardTime(
        this.state.tempId,
        localStorage.getItem("nameGroup"),
        true,
        new Date().getTime(),
        new Date().getTime() - (90 * 24 * 60 * 60 * 1000),
      );
    }else if(e == 6){

      this.setState({ showModalCustomFilter: true});
    }
  }

  hideObjects() {
    //document.getElementById("loadingLineZones").style.display = "none";
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard";
    
    this.getListGroups();
    //this.styleToActive();
    this.hideObjects();
    
    document.getElementById("noZonesText").style.display="none";
    document.getElementById("dashboardZones").style.display = "";
    document.getElementById("dashboardTime").style.display = "none";
    document.getElementById("dashboardDirection").style.display = "none";
    document.getElementById("loadingLineIn").style.display = "none";
    document.getElementById("buttonZones").style.backgroundColor = "#84AC3490";
    document.getElementById("buttonTime").style.backgroundColor = "#dae0e5"; 
    //document.getElementById("buttonDirection").style.backgroundColor = "#dae0e5"; 
    
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
            <div id="genericBody" onClick={this.closeSideBar}>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={12}>
                            <div>
                                <div className="Body-Dashboard" id="Body-Dashboard">
                                <Row>
                                    <Col lg={12}>
                                        <div className="Line-Separator-Menu">
                                            <Button
                                            id="buttonZones"
                                            variant="light"
                                            className="Button-Change-Dash"
                                            onClick={this.changeToZones}
                                            >
                                            Mapas de Calor
                                            </Button>
                                            <Button
                                            id="buttonTime"
                                            variant="light"
                                            className="Button-Change-Dash"
                                            onClick={this.changeToTime}
                                            >
                                            Tiempo
                                            </Button> 
                                            {/* <Button
                                            id="buttonDirection"
                                            variant="light"
                                            className="Button-Change-Dash"
                                            onClick={this.changeToDirection}
                                            >
                                            Dirección
                                            </Button> */}
                                        </div>
                                    </Col>
                                </Row>

                                <div id="dashboardZones">
                                    <Row>
                                    <Col lg={12}>
                                        <div className="form-inline">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                          >
                                            Grupo {localStorage.getItem("nameGroup")}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {this.state.groups}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                            >
                                            Cámara {this.state.tempName}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {this.state.cameras}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div>
                                        <div className="Div-Date">
                                          <DatePicker
                                            className="Date-Picker-day"
                                            selected={this.state.DateHeatMap}
                                            onChange={(date) =>
                                              this.setState({ DateHeatMap: date })
                                            }

                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            dateFormat="yyyy/MM/dd"
                                          />
                                          <Button
                                            variant="success"
                                            className="Button-Apply-Filter"
                                            onClick={this.applyFilterHeatMap}
                                          >
                                            Aplicar
                                          </Button>

                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <p id="noZonesText" className="ml-4">Este establecimiento no tiene algoritmo de zonas</p>
                                      </Col>                                      
                                    </Row>
                                    
                                    <Row>
                                    <Col lg={4}>
                                        <Row>
                                          <Col lg={12}>
                                              <div className="Container-Layout">
                                              <div className="Load">
                                                  {this.state.loadingDynamicImgZones1 && (
                                                  <div className="loader"></div>
                                                  )}
                                              </div>
                                                
                                              <label className="Title-Message">
                                                Mapa de calor 6 am - 12 m
                                              </label>

                                              <div id="loadingImg1">
                                                  <div className="Div-Center">
                                               
                                                  <br></br>
                                                  <br></br>
                                                  <img
                                                      src={working}
                                                      width="80rem"
                                                      alt="Imagen de Loading"
                                                      
                                                  ></img>
                                                  <br></br>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Estamos procesando tus datos
                                                  </label>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Pronto tendremos estadísticas para ti
                                                  </label>
                                                  </div>
                                              </div>
                                              </div>
                                          </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                        <Row>
                                          <Col lg={12}>
                                              
                                              <div className="Container-Layout">
                                              <label className="Title-Message">
                                                Mapa de calor 12 m - 6 pm
                                              </label>
                                              <div className="Load">
                                                  {this.state.loadingDynamicImgZones2 && (
                                                  <div className="loader"></div>
                                                  )}
                                              </div>
                                              <div id="loadingImg2">
                                                  <div className="Div-Center">
                                                 
                                                  <br></br>
                                                  <br></br>
                                                  <img
                                                      src={working}
                                                      width="80rem"
                                                      alt="Imagen de Loading"
                                                      
                                                  ></img>
                                                  <br></br>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Estamos procesando tus datos
                                                  </label>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Pronto tendremos estadísticas para ti
                                                  </label>
                                                  </div>
                                              </div>
                                              </div>
                                          </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                        <Row>
                                          <Col lg={12}>
                                              <div className="Container-Layout">
                                              <label className="Title-Message">
                                                Mapa de calor 6 pm - 12 m
                                              </label>
                                              <div className="Load">
                                                  {this.state.loadingDynamicImgZones3 && (
                                                  <div className="loader"></div>
                                                  )}
                                              </div>
                                              <div id="loadingImg3">
                                              <div className="Div-Center">
                                                  
                                                  <br></br>
                                                  <br></br>
                                                  <img
                                                      src={working}
                                                      width="80rem"
                                                      alt="Imagen de Loading"
                                                  ></img>
                                                  <br></br>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Estamos procesando tus datos
                                                  </label>
                                                  <br></br>
                                                  <label className="Text-Message">
                                                      Pronto tendremos estadísticas para ti
                                                  </label>
                                                  </div>
                                              </div>
                                              </div>
                                          </Col>
                                        </Row>
                                    </Col>
                                    {/*
                                    
                                    
                                    <Col lg={5}>
                                        <div className="Container-Layout">
                                        <div className="Load">
                                            {this.state.loadingDynamicZones && (
                                            <div className="loader"></div>
                                            )}
                                        </div>
                                        <canvas id="chartLineZones"></canvas>
                                        <div id="loadingLineZones">
                                            <div className="Div-Center">
                                            <label className="Title-Message">
                                                Aforo por zona
                                            </label>
                                            <br></br>
                                            <br></br>
                                            <img
                                                src={working}
                                                width="80rem"
                                                alt="Imagen de Loading"
                                            ></img>
                                            <br></br>
                                            <br></br>
                                            <label className="Text-Message">
                                                Estamos procesando tus datos
                                            </label>
                                            <br></br>
                                            <label className="Text-Message">
                                                Pronto tendremos estadísticas para ti
                                            </label>
                                            </div>
                                        </div>
                                        <div></div>
                                        </div>
                                    </Col>
                                  */}
                                    </Row>
                                </div>

                                <div id="dashboardTime">
                                <Row>
                                  <Col lg={12}>
                                      <div className="form-inline">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                          >
                                            Grupo {localStorage.getItem("nameGroup")}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {this.state.groups}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                            >
                                            Cámara {this.state.tempName}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {this.state.cameras}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <Button
                                            id="buttonLoad"
                                            variant="success"
                                            className="Button-Success-Load-More"
                                        >
                                            Cargar más
                                        </Button> */}
                                    
                                        <div className="Div-Date">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                              variant="light"
                                              id="dropdown-basic"
                                              className="Camera-Button"
                                            >
                                              Últimos {this.state.dayFilter} días
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(1)}
                                              >
                                                Últimos 7 días
                                              </Dropdown.Item>
                                              <Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(2)}
                                              >
                                                Últimos 15 días
                                              </Dropdown.Item>
                                              <Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(3)}
                                              >
                                                Últimos 21 días
                                              </Dropdown.Item>
                                              <Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(4)}
                                              >
                                                Últimos 30 días
                                              </Dropdown.Item>
                                              <Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(5)}
                                              >
                                                Últimos 90 días
                                              </Dropdown.Item>
                                              {/*<Dropdown.Item 
                                                onClick={(e) => this.filterchartTime(6)}
                                              >
                                                Custom
                                              </Dropdown.Item>
                                              */}
                                            </Dropdown.Menu> 
                                          </Dropdown>
                                          <Modal
                                            show={this.state.showModalCustomFilter}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                          >
                                            <ModalBody className="px-5 py-3">
                                              <Row>
                                                <Col lg={3}>
                                                  <p>Fecha Inicio:</p>
                                                </Col>
                                                <Col lg={3}>
                                                  <p>Fecha Final:</p>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <DatePicker
                                                  id="customFilter"
                                                  className="Date-Picker"
                                                  selected={this.state.startDateDynamic}
                                                  onChange={(date) =>
                                                    this.setState({ startDateDynamic: date })
                                                  }
                                                  showTimeSelect
                                                  timeFormat="HH:mm"
                                                  timeIntervals={30}
                                                  timeCaption="Hora"
                                                  dateFormat="yyyy/MM/dd h:mm aa"
                                                />
                                                <DatePicker
                                                  className="Date-Picker"
                                                  selected={this.state.finishDateDynamic}
                                                  onChange={(date) =>
                                                    this.setState({ finishDateDynamic: date })
                                                  }
                                                  showTimeSelect
                                                  timeFormat="HH:mm"
                                                  timeIntervals={30}
                                                  timeCaption="Hora"
                                                  dateFormat="yyyy/MM/dd h:mm aa"
                                                />
                                              </Row>
                                              <Row style={{ justifyContent: "center"}}>
                                                <Button
                                                  variant="success"
                                                  className="Button-Apply-Filter"
                                                  onClick={this.applyFilterDynamic}
                                                >
                                                  Aplicar
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  className="Button-Close-Modal"
                                                  onClick={() => {
                                                    this.setState({showModalCustomFilter: false})
                                                  }}
                                                >
                                                  Cerrar
                                                </Button>
                                              </Row>
                                            </ModalBody>
                                          </Modal>
                                        </div>
                                      </div>
                                    </Col>
                                </Row>
                                <div className="Load">
                                  {this.state.loadingTime && (
                                    <div className="loader"></div>
                                  )}
                                </div>
                                <Row>
                                  <Col lg={1}></Col>
                                  <Col lg={5} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "}Tiempo promedio por cámara</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.timeCamera} {" "}segundos</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                  <Col lg={5} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "} Tiempo mayor en cámara</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.timeMax} {" "}segundos</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                  <Col lg={1}></Col>
                                </Row>
                                <Row>
                                  <Col lg={1}></Col>
                                  <Col lg={5}></Col>
                                  <Col lg={5} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "}Tiempo menor por cámara</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.timeMin} {" "}segundos</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                  <Col lg={1}></Col>
                                </Row>
                              </div>

                                <div id="dashboardDirection">
                                <Row>
                                  <Col lg={12}>
                                        <div className="form-inline">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                          >
                                            Grupo {localStorage.getItem("nameGroup")}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {this.state.groups}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                            variant="light"
                                            id="dropdown-basic"
                                            className="Camera-Button"
                                            >
                                            Cámara {this.state.tempName}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {this.state.cameras}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <Button
                                            id="buttonLoad"
                                            variant="success"
                                            className="Button-Success-Load-More"
                                        >
                                            Cargar más
                                        </Button>  */}
                                        <div className="Div-Date">
                                            <DatePicker
                                            className="Date-Picker"
                                            selected={this.state.finishDateDynamic}
                                            onChange={(date) =>
                                                this.setState({ finishDateDynamic: date })
                                            }
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            timeCaption="Hora"
                                            dateFormat="yyyy/MM/dd h:mm aa"
                                            />
                                            <DatePicker
                                            className="Date-Picker"
                                            selected={this.state.startDateDynamic}
                                            onChange={(date) =>
                                                this.setState({ startDateDynamic: date })
                                            }
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            timeCaption="Hora"
                                            dateFormat="yyyy/MM/dd h:mm aa"
                                            />
                                        </div>
                                        <Button
                                            variant="success"
                                            className="Button-Apply-Filter"
                                            onClick={this.applyFilterDynamic}
                                        >
                                            Aplicar
                                        </Button>
                                        <Button
                                            variant="dark"
                                            className="Button-Delete-Filter"
                                            onClick={this.deleteFilterDynamic}
                                        >
                                            Eliminar
                                        </Button>
                                        </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={12}>
                                        <div className="Container-Layout">
                                          <div className="Load">
                                            {this.state.loadingStaticInLine && (
                                              <div className="loader"></div>
                                            )}
                                          </div>
                                          <canvas id="chartLineIn"></canvas>
                                          <div id="loadingLineIn">
                                            <div className="Div-Center">
                                              <label className="Title-Message">
                                                Tiempo promedio por zona
                                              </label>
                                              <br></br>
                                              <br></br>
                                              <img
                                                src={working}
                                                width="80rem"
                                                alt="Imagen de Loading"
                                              ></img>
                                              <br></br>
                                              <br></br>
                                              <label className="Text-Message">
                                                Estamos procesando tus datos
                                              </label>
                                              <br></br>
                                              <label className="Text-Message">
                                                Pronto tendremos estadísticas para ti
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                
                                <Row>
                                  <Col lg={1}></Col>
                                  <Col lg={5} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "}Dirección a la que fueron mayor número de personas</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.MoreDirection}</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                  <Col lg={5} className="pt-4 pl-4">
                                    <Row style={{margin:"auto"}}>
                                      <Col lg={10} className="Card-insight">
                                        <Row style={{minHeight: "7rem"}}>
                                          <Col lg={12} className="mx-2">
                                            <Row className="pt-3">
                                              <label className="label-Option-Dashboard">{" "}Dirección a la que fueron menor número de personas</label>
                                            </Row>
                                            <Row>
                                              <div style={{ margin: "auto"}}>
                                                <label className="Insight-Home">{this.state.LessDirection}</label>
                                              </div> 
                                            </Row>
                                          </Col>  
                                        </Row>            
                                      </Col>
                                    </Row>   
                                  </Col>
                                  <Col lg={1}></Col>
                                </Row>
                               
                                </div>
                              </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </div>
      </div>
    );
  }
}

export default DashboardBehaviour;