//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";
import { Row, Col, Button } from "react-bootstrap";
import API from "../../api/Main";

//Import images
import iconoEmail from "../../imgs/mail.png";
import iconoUser from "../../imgs/user.png";
import iconoTrash from "../../imgs/trash.png";
import imgSuccess from "../../imgs/success.png";
import imgEquis from "../../imgs/equis.png";
import imgFail from "../../imgs/failed.png";

//Import styles
import "../../styles/Sympthoms/addEmailSymptoms.css";

var validator = require("validator");

class AddEmailSymptoms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      email: "",
      data: { info_to_send: [], last_send: "" },
      listUsersToShow: [],
      listUsersBDToShow: [],
      toDelete: "",
      loading: false,
    };
  }

  handleChangeUserName = (e) => {
    this.setState({ userName: e.target.value });
    document.getElementById("errorMessage").style.display = "none";
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    document.getElementById("errorMessage").style.display = "none";
  };

  handleButtonClick = () => {
    let tempInfo = this.state.data;
    let tempInfoToShow = this.state.listUsersToShow;
    let aux = -1;

    if (this.state.userName === "") {
      document.getElementById("errorMessage").style.display = "";
      document.getElementById("errorMessage").innerText =
        "Debes incluir Nombres y apellidos";
      this.setState({ email: "" });
    } else {
      //Evalúa si el array está vacío. Si lo está se añade la información.
      if (tempInfo.info_to_send.length === 0) {
        if (validator.isEmail(this.state.email)) {
          let tempUserInfo = {
            name: this.state.userName,
            email: this.state.email,
          };
          let tempUserInfoToShow = (
            <div key={this.state.email}>
              <label>
                <img
                  id={this.state.email}
                  src={iconoTrash}
                  alt="Icono trash"
                  width="30rem"
                  onClick={this.eraseEmail}
                  className="Img-Trash"
                ></img>
              </label>
              <label className="List-Info">{this.state.userName}</label>
              <label className="List-Info">{this.state.email}</label>
            </div>
          );
          tempInfo.info_to_send.push(tempUserInfo);
          tempInfoToShow.push(tempUserInfoToShow);
          this.setState({
            data: tempInfo,
            listUsersToShow: tempInfoToShow,
            userName: "",
            email: "",
          });
        } else {
          document.getElementById("errorMessage").style.display = "";
          document.getElementById("errorMessage").innerText =
            "Por favor ingrese un correo válido";
          this.setState({ email: "" });
        }
      } else {
        //Evalúa si el correo a ingresar ya está dentro del array. Cuando lo encuentre cambia el valor de aux y sale del ciclo.
        for (var i = 0; i < tempInfo.info_to_send.length; i++) {
          if (tempInfo.info_to_send[i].email === this.state.email) {
            aux = tempInfo.info_to_send[i].email.indexOf(this.state.email);
            break;
          }
        }
        if (aux !== -1) {
          document.getElementById("errorMessage").style.display = "";
          document.getElementById("errorMessage").innerText =
            "El correo ingresado ya se encuentra en la lista a enviar";
          this.setState({ email: "" });
        } else {
          if (validator.isEmail(this.state.email)) {
            let tempUserInfo = {
              name: this.state.userName,
              email: this.state.email,
            };
            let tempUserInfoToShow = (
              <div key={this.state.email}>
                <label>
                  <img
                    id={this.state.email}
                    src={iconoTrash}
                    alt="Icono trash"
                    width="30rem"
                    onClick={this.eraseEmail}
                    className="Img-Trash"
                  ></img>
                </label>
                <label className="List-Info">{this.state.userName}</label>
                <label className="List-Info">{this.state.email}</label>
              </div>
            );
            tempInfo.info_to_send.push(tempUserInfo);
            tempInfoToShow.push(tempUserInfoToShow);
            this.setState({
              data: tempInfo,
              listUsersToShow: tempInfoToShow,
              userName: "",
              email: "",
            });
          } else {
            document.getElementById("errorMessage").style.display = "";
            document.getElementById("errorMessage").innerText =
              "Por favor ingrese un correo válido";
            this.setState({ email: "" });
          }
        }
      }
    }
  };

  eraseEmail = (e) => {
    let tempId;
    for (var i = 0; i < this.state.listUsersToShow.length; i++) {
      if (this.state.listUsersToShow[i].key === e.target.id) {
        tempId = i;
        break;
      }
    }
    let tempArrayToShow = this.state.listUsersToShow;
    let tempDataArray = this.state.data;
    tempDataArray.info_to_send.splice(tempId, 1);
    tempArrayToShow.splice(tempId, 1);
    this.setState({ data: tempDataArray, listUsersToShow: tempArrayToShow });
  };

  saveInfo = () => {
    this.setState({ loading: true });
    if (this.state.data.info_to_send.length !== 0) {
      API.put(
        "https://dot",
        "/email",
        JSON.stringify({ data: this.state.data })
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              this.setState({ loading: false });
              document.getElementById("popUpAdded").style.display = "";
              document.getElementById("bodyAdd").style.filter = "blur(5px)";
              this.verifyEmailsAdded();
            });
          } else {
            response.json().then((data) => {
              this.setState({ loading: false });
              document.getElementById("popUpNoAdded").style.display = "";
              document.getElementById("bodyAdd").style.filter = "blur(5px)";
              document.getElementById("textErrorPopUp").innerText =
                data.message;
            });
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({ loading: false });
      document.getElementById("popUpNoAdded").style.display = "";
      document.getElementById("bodyAdd").style.filter = "blur(5px)";
      document.getElementById("textErrorPopUp").innerText =
        "No se ha enviado ningún correo";
    }
  };

  handleCloseAdded = () => {
    document.getElementById("popUpAdded").style.display = "none";
    document.getElementById("bodyAdd").style.filter = "none";
    let tempData = this.state.data;
    tempData = { info_to_send: [], last_send: "" };
    let tempDataToShow = this.state.listUsersToShow;
    tempDataToShow = [];
    this.setState({ data: tempData, listUsersToShow: tempDataToShow });
  };

  handleCloseNoAdded = () => {
    document.getElementById("popUpNoAdded").style.display = "none";
    document.getElementById("bodyAdd").style.filter = "none";
  };

  handleCloseDelete = () => {
    document.getElementById("popUpDelete").style.display = "none";
    document.getElementById("bodyAdd").style.filter = "none";
  };

  eraseEmailDB = (e) => {
    this.setState({ toDelete: e.target.id });
    document.getElementById("popUpDelete").style.display = "";
    document.getElementById("bodyAdd").style.filter = "blur(5px)";
  };

  handleButtonDelete = () => {
    let body = JSON.stringify({
      data: {
        email: this.state.toDelete
      },
    });
    API.delete("https://dot", "/email", body)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            document.getElementById("popUpDelete").style.display = "none";
            document.getElementById("bodyAdd").style.filter = "";
            this.verifyEmailsAdded();
          });
        } else {
          response.json().then((data) => {
            console.log(data);
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  verifyEmailsAdded() {
    this.setState({ loading: true });
    let tempListUsersBDToShow = [];
    API.get("https://dot", "/email")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            for (var i = 0; i < data.info_to_send.length; i++) {
              let tempInfoBD = (
                <div key={data.info_to_send[i].email}>
                  <label>
                    <img
                      id={data.info_to_send[i].email}
                      src={iconoTrash}
                      alt="Icono trash"
                      width="30rem"
                      onClick={this.eraseEmailDB}
                      className="Img-Trash"
                    ></img>
                  </label>
                  <label className="List-Info">
                    {data.info_to_send[i].name}
                  </label>
                  <label className="List-Info">
                    {data.info_to_send[i].email}
                  </label>
                </div>
              );
              tempListUsersBDToShow.push(tempInfoBD);
            }
            let tempData = { info_to_send: [], last_send: "" };
            this.setState({
              listUsersBDToShow: tempListUsersBDToShow,
              data: tempData,
              loading: false,
            });
          });
        } else {
          response.json().then((data) => {
            this.setState({ loading: false });
            console.log(data);
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  styleToActive() {
    /* document.getElementById("activeConfigSym").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeConfigSym").style.borderStyle = "dashed";
    document.getElementById("activeConfigSym").style.borderColor = "#84ac34";
    document.getElementById("activeConfigSym").style.borderRadius = "1rem";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeCamsBio").style.color = "";
    document.getElementById("activeCamsBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none"; */
  }

  componentDidMount() {
    document.title = "Switch AI | Añadir correos";
    this.styleToActive();
    document.getElementById("popUpAdded").style.display = "none";
    document.getElementById("popUpNoAdded").style.display = "none";
    document.getElementById("popUpDelete").style.display = "none";
    this.verifyEmailsAdded();
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Load">
            {this.state.loading && <div className="loader"></div>}
          </div>
          <div id="popUpAdded" className="Pop-Up-Emails">
            <div className="Div-Right">
              <img
                src={imgEquis}
                alt="Icono close"
                width="50rem"
                className="Equis"
                onClick={this.handleCloseAdded}
              ></img>
            </div>
            <div className="Div-Center">
              <img src={imgSuccess} alt="Icono success" width="120rem"></img>
              <br></br>
              <label className="Title-Pop-Up">Envío exitoso</label>
            </div>
          </div>
          <div id="popUpNoAdded" className="Pop-Up-Emails">
            <div className="Div-Right">
              <img
                src={imgEquis}
                alt="Icono close"
                width="50rem"
                className="Equis"
                onClick={this.handleCloseNoAdded}
              ></img>
            </div>
            <div className="Div-Center">
              <img src={imgFail} alt="Icono failed" width="100rem"></img>
              <br></br>
              <label className="Title-Pop-Up-Error">Ha ocurrido un error</label>
              <br></br>
              <label id="textErrorPopUp" className="Text-Error"></label>
            </div>
          </div>
          <div id="popUpDelete" className="Pop-Up-Delete">
            <div className="Div-Right">
              <img
                src={imgEquis}
                alt="Icono close"
                width="50rem"
                className="Equis"
                onClick={this.handleCloseDelete}
              ></img>
            </div>
            <div className="Div-Center">
              <label className="Title-Pop-Up-Delete">
                ¿Estás seguro que deseas eliminar este correo?
              </label>
              <br></br>
              <Button
                variant="danger"
                className="Delete-Button"
                onClick={this.handleButtonDelete}
              >
                Eliminar
              </Button>
            </div>
          </div>
          <div id="bodyAdd" className="Body-Add">
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <div className="Div-Center">
                  <label className="Title-Page">
                    Correos para envío de formulario de síntomas
                  </label>
                </div>
                <Row>
                  <Col lg={4}>
                    <div className="Card-Layout">
                      <Row>
                        <Col lg={12}>
                          <label className="Text-Input">
                            <img
                              src={iconoUser}
                              alt="Icono usuario"
                              width="28rem"
                            />{" "}
                            Nombres y apellidos <br></br>
                            <input
                              className="Input-Login"
                              type="text"
                              name="user"
                              onChange={this.handleChangeUserName}
                              value={this.state.userName}
                            />
                          </label>
                          <label className="Text-Input">
                            <img
                              src={iconoEmail}
                              alt="Icono usuario"
                              width="28rem"
                            />{" "}
                            Correo electrónico <br></br>
                            <input
                              className="Input-Login"
                              type="email"
                              name="email"
                              onChange={this.handleChangeEmail}
                              value={this.state.email}
                            />
                          </label>
                          <div className="Div-Center-Button">
                            <Button
                              variant="success"
                              className="Submit-Button-Single"
                              onClick={this.handleButtonClick}
                            >
                              Añadir
                            </Button>
                          </div>
                          <label
                            id="errorMessage"
                            className="Error-Message"
                          ></label>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="Card-Layout">
                      <label className="Title-List">
                        Nombres y correos guardados
                      </label>
                      <div>{this.state.listUsersBDToShow}</div>
                      <hr></hr>
                      <label className="Title-List">
                        Nombres y correos a añadir
                      </label>
                      <div>{this.state.listUsersToShow}</div>
                      <div className="Div-Center-Button">
                        <Button
                          variant="success"
                          className="Submit-Button"
                          onClick={this.saveInfo}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={1}></Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default AddEmailSymptoms;
