import React, { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import "styles/select.css";

export const Select = ({
    className,
    handleChange,
    isMulti = false,
    label,
    options = [],
    placeholder = "Selecciona",
    value,
    disabled = false,
    wrapperClassName = "",
}) => {
    const validateOptions = useCallback(() => {
        if (isMulti) {
            return value.length === options.length - 1 ? options.slice(1) : options;
        }
        return options;
    }, [isMulti, options, value]);

    const newOptions = useMemo(() => validateOptions(), [validateOptions]);

    return (
        <Form.Group className={`m-0 ${wrapperClassName}`}>
            {label && <Form.Label>{label}</Form.Label>}
            <ReactSelect
                className={className}
                isMulti={isMulti}
                noOptionsMessage={() => "No hay más opciones"}
                onChange={handleChange}
                options={newOptions}
                placeholder={placeholder}
                value={value}
                isDisabled={disabled}
            />
        </Form.Group>
    );
};
