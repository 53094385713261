import React, { useCallback, useEffect, useState } from "react";
import { PieChart } from "components/PieChart";
import { SEGMENTED_TRAFFIC_KEYS, countSegmentedData } from ".";

export const SegmentedCharts = ({ cameras, selectedCameras, traffic }) => {
    const [chartData, setChartData] = useState({ people: [], pets: [] });
    const [segmentedTraffic, setSegmentedTraffic] = useState({});

    const getChartsData = useCallback(() => {
        const chartData = countSegmentedData(selectedCameras, segmentedTraffic);
        setChartData(chartData);
    }, [selectedCameras, segmentedTraffic]);

    const getSegmentedData = useCallback(() => {
        const camerasWithData = [];
        const data = traffic.flatMap((traffic, index) => {
            const [isSegmented, cameraId] = [traffic.some(item => item.nMen), cameras[index]?.id];
            if (isSegmented) camerasWithData.push(cameraId);
            return isSegmented ? { data: traffic, cameraId } : [];
        });
        return { data, camerasWithData };
    }, [cameras, traffic]);

    const segmentTraffic = useCallback(data => {
        const total = {};
        data.forEach(({ data, cameraId }) => {
            total[cameraId] = { men: 0, women: 0, pets: 0 };
            data.forEach(item =>
                SEGMENTED_TRAFFIC_KEYS.forEach(
                    ({ key, dataKey }) => (total[cameraId][key] = total[cameraId][key] + item[dataKey])
                )
            );
        });
        return total;
    }, []);

    const getData = useCallback(() => {
        const { data } = getSegmentedData();
        setSegmentedTraffic(segmentTraffic(data));
    }, [getSegmentedData, segmentTraffic]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        getChartsData();
    }, [getChartsData]);

    return (
        <aside className="characterization-section">
            <h2 className="charts-title mb-0">
                Caracterización
                <i className="people-icon fa-solid fa-restroom" />
            </h2>
            <PieChart data={chartData.people} />
            <div className="cipher-text mt-4 w-max mx-auto">
                <p className="cipher-label">
                    Número de mascotas
                    <i className="dog-icon fa-solid fa-dog" />
                </p>
                <p className="total-value">{chartData.pets[0]?.total}</p>
            </div>
        </aside>
    );
};
