export { default } from "./Historical";

export const PERIOD_OPTIONS = [
    {
        id: 1,
        value: "Días",
        label: "Días",
        type: "days",
    },
    {
        id: 2,
        value: "Horas",
        label: "Horas",
        type: "hours",
    },
];

export const getVisitsPerHour = data => {
    const total = {};
    data.forEach(item => {
        const [hour, minutes] = item.date.split(":");
        total[hour] = (total[hour] ?? 0) + (item.value ?? 0);
    });
    const result = Object.entries(total).map(([key, value]) => ({
        date: key,
        value,
    }));

    return result.sort((a, b) => Number(a.date.split(":")[0]) - Number(b.date.split(":")[0]));
};

export const getTextsFromPeaks = filterByDays => {
    const unit = filterByDays ? "Día" : "Hora";
    const termination = filterByDays ? "o" : "a";
    return {
        maximum: `${unit} más visitad${termination}:`,
        minimum: `${unit} menos visitad${termination}:`,
    };
};
