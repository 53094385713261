import React, { useCallback, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { DateInput } from "components/DateInput";
import { dateToUnix, formatDate, setTimeToDate } from "utils/Date";

export const FilterModal = ({ enableTrafficQuery, event, filters, filterByDays, handleDateChange, toggleModal }) => {
    const { date } = filters;

    const applyFilter = () => {
        enableTrafficQuery();
        toggleModal();
    };

    const eventDays = (dateToUnix(formatDate(event.end)) - dateToUnix(formatDate(event.start))) / (86400 * 1000);

    const getDateLimits = useCallback(() => {
        const [maxDate, minDate] = [new Date(event?.end), new Date(event?.start)];
        return {
            ...(!eventDays && { minTime: setTimeToDate(minDate), maxTime: setTimeToDate(maxDate) }),
            minDate,
            maxDate,
        };
    }, [eventDays, event]);

    const dateLimits = useMemo(() => getDateLimits(), [getDateLimits]);

    const unit = filterByDays ? "Día" : "Hora";

    return (
        <form autoComplete="off">
            <Modal animation={false} show>
                <Modal.Header closeButton onClick={() => toggleModal(true)}>
                    <Modal.Title>Escoger {unit.toLowerCase()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateInput
                        handleChange={date => handleDateChange(date, "start")}
                        label={`${unit} inicial:`}
                        value={date?.start}
                        includeHours={!filterByDays}
                        {...dateLimits}
                    />
                    <DateInput
                        handleChange={date => handleDateChange(date, "end")}
                        label={`${unit} final:`}
                        value={date?.end}
                        includeHours={!filterByDays}
                        {...dateLimits}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="close-button" onClick={() => toggleModal(true)}>
                        Cerrar
                    </Button>
                    <Button className="save-button" onClick={applyFilter}>
                        Aplicar
                    </Button>
                </Modal.Footer>
            </Modal>
        </form>
    );
};
