import { useCallback, useEffect, useState } from "react";
import { dateToUnix, formatDate } from "utils/Date";

export const useDates = (date = {}) => {
    const [formattedDate, setFormattedDate] = useState({ end: "", start: "" });

    const formatDates = useCallback(() => {
        if (date.start && date.end) {
            setFormattedDate({
                end: formatDate(new Date(date.end)),
                start: formatDate(new Date(date.start)),
            });
        }
    }, [date]);

    const getNewDates = ({ date, name}, filters) => {
        const newDate = { ...filters.date, [name]: date };
        const [start, end] = [dateToUnix(newDate.start), dateToUnix(newDate.end)];
        const isStart = name === "start";
        if (start > end) return { ...filters, date: { ...newDate, [isStart ? "end" : "start"]: newDate[name] } };
        return { ...filters, date: { ...filters.date, [name]: date } };
    };

    useEffect(() => {
        formatDates();
    }, [formatDates]);

    return {
        formattedDate,
        getNewDates,
    };
};
