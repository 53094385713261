import React from "react";
import { RadioButtonList } from "components/radio-button-list";
import { Input } from "./Input";
import { Card } from "./Card";
import { Select } from "components/Select";
import { PhoneInput } from "components/phone-input";
import { useOption } from "hooks/useOption";
import { COMMUNICATION_CHANNELS, CAMERA_OPTIONS } from ".";

export const ArmedPeopleCard = ({ activeAlerts, ...props }) => {
    const isChecked = activeAlerts.includes(props.type);
    const disabled = !isChecked;
    const { selectOption, selectedOptions } = useOption({ channel: "WhatsApp" });

    return (
        <Card {...props} isChecked={isChecked}>
            <div className="card__body">
                <Select
                    className="card__select"
                    placeholder="Seleccione las cámaras"
                    options={CAMERA_OPTIONS}
                    disabled={disabled}
                />
                <div className="card__contact">
                    <RadioButtonList
                        options={COMMUNICATION_CHANNELS}
                        disabled={disabled}
                        name="channel"
                        selectOption={selectOption}
                        selectedOptions={selectedOptions}
                    />
                    {selectedOptions.channel === "WhatsApp" || selectedOptions.channel === "SMS" ? (
                        <PhoneInput disabled={disabled} />
                    ) : (
                        <Input placeholder="Correo" iconName="fa-bell" type="email" disabled={disabled} />
                    )}
                </div>
            </div>
        </Card>
    );
};
