//Import main libraries and components
import React from "react";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import API from "../../api/Main";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";

//Import images
import imgEquis from "../../imgs/equis-2.png";
import notFound from "../../imgs/error.png";

//Import styles
import "../../styles/Biosecurity/events.css";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      infoEvents: [],
      lastEventLoaded: 0,
      elementToShow: [],
      loading: true,
    };
  }

  handleClickEvent = (e) => {
    document.getElementById("popUp").style.display = "";
    document.getElementById("bodyBlur").style.filter = "blur(5px)";
    let element = this.state.infoEvents[e.target.id];
    let elementHTML = (
      <div key={e.target.id}>
        <Row>
          <Col lg={12}>
            <div className="Div-Center-Image">
              <img src={element.file} alt="Imagen del evento" width="60%"></img>
              <br></br>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={2}></Col>
          <Col lg={8}>
            <div>
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{ maxWidth: "44rem" }}
              >
                <thead>
                  <tr>
                    <th>
                      <label className="Card-Title-Event">Fecha </label>
                    </th>
                    <th>
                      <label className="Card-Text-Element">
                        {new Date(element.timestamp * 1000).toLocaleString()}
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label className="Card-Title-Event">
                        Personas en riesgo por distanciamiento
                      </label>
                    </td>
                    <td>
                      <label className="Card-Text-Element">
                        {element.event.distance.riesgo}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="Card-Title-Event">
                        Personas en riesgo por no uso de máscara
                      </label>
                    </td>
                    <td>
                      <label className="Card-Text-Element">
                        {element.event.mask.riesgo}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="Card-Title-Event">
                        Personas seguras
                      </label>
                    </td>
                    <td>
                      <label className="Card-Text-Element">
                        {element.event.distance.NoRiesgo}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="Card-Title-Event">
                        Total de personas
                      </label>
                    </td>
                    <td>
                      <label className="Card-Text-Element">
                        {element.event.distance.total_p}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="Card-Title-Event">Aforo máximo</label>
                    </td>
                    <td>
                      <label className="Card-Text-Element"></label>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </div>
    );
    let tempEvent = [];
    tempEvent.push(elementHTML);
    this.setState({ elementToShow: tempEvent });
  };

  handleClose = () => {
    document.getElementById("popUp").style.display = "none";
    document.getElementById("bodyBlur").style.filter = "none";
    this.setState({ elementToShow: [] });
  };

  getEventsFromCameras() {
    let lastEvent = 0;
    let body = JSON.stringify({
      data: {
        pageSize: new Date().toString(),
      },
    });
    API.postForCameras(
      "https://dot",
      "/cameras/events/" + localStorage.getItem("CameraID"),
      body
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let tempInitEvents = [];
            let tempInfoEvents = [];
            let counter = 0;
            if (data.length !== 0) {
              data.forEach((element) => {
                document.getElementById("loadingEvents").style.display = "none";

                let event = (
                  <Card className="Card-Event" key={counter}>
                    <Card.Img variant="top" src={element.file} />
                    <Card.Body>
                      <Card.Title className="Card-Title-Element">
                        Evento {counter}
                      </Card.Title>
                      <Card.Text className="Card-Text-Element">
                        {new Date(element.timestamp * 1000).toString()}
                      </Card.Text>
                      <Button
                        variant="success"
                        id={counter}
                        onClick={this.handleClickEvent.bind(this)}
                        className="Button-Load-Event"
                      >
                        Ver más información
                      </Button>
                    </Card.Body>
                  </Card>
                );
                tempInitEvents.push(event);
                tempInfoEvents.push(element);
                lastEvent = element.timestamp;
                counter++;
              });
            } else {
              document.getElementById("loadMoreEvents").style.display = "none";
              document.getElementById("loadingEvents").style.display = "";
            }
            this.setState({
              events: tempInitEvents,
              infoEvents: tempInfoEvents,
              lastEventLoaded: lastEvent,
              loading: false,
            });
          });
        } else {
          console.log("Error");
        }
      })
      .catch((error) => console.log("error", error));
  }

  updateEvents = () => {
    document.getElementById("loadingEvents").style.display = "none";
    let lastEvent = 0;
    this.setState({ events: [], infoEvents: [], loading: true });
    let body = JSON.stringify({
      data: {
        pageSize: new Date().toString(),
      },
    });
    API.postForCameras(
      "https://dot",
      "/cameras/events/" + localStorage.getItem("CameraID"),
      body
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let tempUpdatedEvents = [];
            let tempInfoUpdatedEvents = [];
            let counter = 0;

            if (data.length !== 0) {
              document.getElementById("loadingEvents").style.display = "none";

              data.forEach((element) => {
                let event = (
                  <Card className="Card-Event">
                    <Card.Img variant="top" src={element.file} />
                    <Card.Body>
                      <Card.Title className="Card-Title-Element">
                        Evento {counter}
                      </Card.Title>
                      <Card.Text className="Card-Text-Element">
                        {new Date(element.timestamp * 1000).toString()}
                      </Card.Text>
                      <Button
                        variant="success"
                        id={counter}
                        onClick={this.handleClickEvent.bind(this)}
                        className="Button-Load-Event"
                      >
                        Ver más información
                      </Button>
                    </Card.Body>
                  </Card>
                );
                tempUpdatedEvents.push(event);
                tempInfoUpdatedEvents.push(element);
                lastEvent = element.timestamp;
                counter++;
              });
            } else {
              document.getElementById("loadingEvents").style.display = "";
            }

            this.setState({
              events: tempUpdatedEvents,
              infoEvents: tempInfoUpdatedEvents,
              lastEventLoaded: lastEvent,
              loading: false,
            });
          });
        } else {
          console.log("Error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  loadMoreEvents = () => {
    let lastEvent = 0;
    let body = JSON.stringify({
      data: {
        pageSize: new Date(this.state.lastEventLoaded * 1000).toString(),
      },
    });
    API.postForCameras(
      "https://dot",
      "/cameras/events/" + localStorage.getItem("CameraID"),
      body
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let tempMoreEvents = this.state.events;
            let tempMoreInfoEvents = this.state.infoEvents;
            let counter = this.state.infoEvents.length;
            data.forEach((element) => {
              let event = (
                <Card className="Card-Event">
                  <Card.Img variant="top" src={element.file} />
                  <Card.Body>
                    <Card.Title className="Card-Title-Element">
                      Evento {counter}
                    </Card.Title>
                    <Card.Text className="Card-Text-Element">
                      {new Date(element.timestamp * 1000).toString()}
                    </Card.Text>
                    <Button
                      variant="success"
                      id={counter}
                      onClick={this.handleClickEvent.bind(this)}
                      className="Button-Load-Event"
                    >
                      Ver más información
                    </Button>
                  </Card.Body>
                </Card>
              );
              tempMoreEvents.push(event);
              tempMoreInfoEvents.push(element);
              lastEvent = element.timestamp;
              counter++;
            });
            this.setState({
              events: tempMoreEvents,
              infoEvents: tempMoreInfoEvents,
              lastEventLoaded: lastEvent,
            });
          });
        } else {
          console.log("Error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  styleToActive() {
   /*  document.getElementById("activeCamsBio").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeCamsBio").style.borderStyle = "dashed";
    document.getElementById("activeCamsBio").style.borderColor = "#84ac34";
    document.getElementById("activeCamsBio").style.borderRadius = "1rem";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none"; */
  }

  componentDidMount() {
    document.title = "Switch AI | Eventos";
    this.getEventsFromCameras();
    this.styleToActive();
    document.getElementById("popUp").style.display = "none";
    document.getElementById("loadingEvents").style.display = "none";
  }

  closeSideBar(){   
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  } 
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Events">
            <div className="Load">
              {this.state.loading && <div className="loader"></div>}
            </div>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <div id="popUp" className="Pop-Up">
                  <div className="Div-Right">
                    <img
                      src={imgEquis}
                      alt="Icono close"
                      width="50rem"
                      className="Equis"
                      onClick={this.handleClose}
                    ></img>
                  </div>
                  <div>{this.state.elementToShow}</div>
                </div>
              </Col>
              <Col lg={2}></Col>
            </Row>
            <div id="bodyBlur">
              <div className="Div-Center">
                <Row>
                  <Col lg={6}>
                    <label className="Title-Name-Camera">
                      Eventos de la cámara
                    </label>
                    <label className="Name-Camera">
                      {localStorage.getItem("CameraName")}
                    </label>
                  </Col>
                  <Col lg={6}>
                    <Button
                      onClick={this.updateEvents}
                      variant="success"
                      className="Button-Update"
                    >
                      Actualizar
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                  <div className="Cameras-Body">
                    <div className="form-inline">{this.state.events}</div>
                    <div id="loadingEvents" className="Div-Center">
                      <label className="Card-Title-Event">
                        No hay eventos disponibles
                      </label>
                      <br></br>
                      <img
                        src={notFound}
                        width="300rem"
                        alt="Imagen de Loading"
                      ></img>
                    </div>
                  </div>
                  <Button
                    id="loadMoreEvents"
                    onClick={this.loadMoreEvents}
                    variant="success"
                    className="Button-More-Events"
                  >
                    Cargar más
                  </Button>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Event;
