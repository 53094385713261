export const FULL_DATE_PICKER_PROPS = {
    showTimeSelect: true,
    timeFormat: "HH:mm",
    timeIntervals: 30,
    timeCaption: "Hora",
    dateFormat: "yyyy/MM/dd h:mm aa",
};

export const TIME_PICKER_PROPS = {
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 60,
    timeCaption: "Hora",
    dateFormat: "h:mm aa",
};
