import React, { useState } from "react";
import { RadioButtonList } from "components/radio-button-list";
import { Input } from "./Input";
import { Card } from "./Card";
import { Select } from "components/Select";
import { DateInput } from "components/DateInput";
import { PhoneInput } from "components/phone-input";
import { useOption } from "hooks/useOption";
import { COMMUNICATION_CHANNELS, CAMERA_OPTIONS } from ".";

export const RestrictedAccessCard = ({ activeAlerts, ...props }) => {
    const [date, setDate] = useState({});
    const isChecked = activeAlerts.includes(props.type);
    const disabled = !isChecked;
    const { selectOption, selectedOptions } = useOption({ channel: "WhatsApp" });

    const handleDateChange = (value, name) => setDate({ ...date, [name]: value });

    return (
        <Card {...props} isChecked={isChecked}>
            <div className="card__body">
                <div className="gap-4 flex-center">
                    <Select
                        className="card__select"
                        placeholder="Seleccione las cámaras"
                        options={CAMERA_OPTIONS}
                        disabled={disabled}
                    />
                </div>
                <div className="card__contact">
                    <RadioButtonList
                        options={COMMUNICATION_CHANNELS}
                        disabled={disabled}
                        name="channel"
                        selectOption={selectOption}
                        selectedOptions={selectedOptions}
                    />
                    {selectedOptions.channel === "WhatsApp" || selectedOptions.channel === "SMS" ? (
                        <PhoneInput disabled={disabled} />
                    ) : (
                        <Input placeholder="Correo" iconName="fa-bell" type="email" disabled={disabled} />
                    )}
                </div>
            </div>
            <div className="card__hours mt-2">
                <DateInput
                    value={date?.start}
                    includeHours
                    label="Hora de inicio:"
                    handleChange={date => handleDateChange(date, "start")}
                    disabled={disabled}
                />
                <DateInput
                    value={date?.end}
                    includeHours
                    label="Hora de fin:"
                    handleChange={date => handleDateChange(date, "end")}
                    disabled={disabled}
                />
            </div>
        </Card>
    );
};
