import React, { useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { DateInput } from "components/DateInput";
import { Input } from "components/Input";
import { Select } from "components/Select";
import { handleDateChanges } from "utils/Date";
import { getNewOptions } from "utils/Select";
import { ALL_ACCESS, MODALS } from "..";
import { DURATION_OPTIONS } from ".";
import "styles/input.css";

export const EventModal = ({
    cameraOptions = [],
    deleteEvent,
    event,
    setEvent,
    handleEventChange,
    saveEvent,
    toggleForm = () => {},
}) => {
    const closeForm = () => toggleForm("");

    const handleChangingCameras = selectedOptions => {
        setEvent({
            ...event,
            cameras: getNewOptions({ allOptions: cameraOptions, selectedOptions, allKey: ALL_ACCESS }),
        });
    };

    const handleDateChange = (date, name) => {
        const { start, end } = event;
        setEvent({ ...event, ...handleDateChanges(date, { name, dates: { start, end } }) });
    };

    const disableSaved = !event.cameras.length || !event.title;

    const showTimeSelector = useMemo(() => event.duration?.type === "hours", [event.duration]);

    return (
        <Modal animation={false} show onHide={closeForm}>
            <Form>
                <Modal.Header closeButton onClick={closeForm}>
                    <Modal.Title>{event?.edit ? "Editar" : "Agregar"} evento</Modal.Title>
                </Modal.Header>
                <Modal.Body className="flex flex-col gap-2">
                    <Select
                        label="Duración del evento:"
                        options={DURATION_OPTIONS}
                        handleChange={option => setEvent({ ...event, duration: option })}
                        placeholder="Selecciona el tipo de duración"
                        value={event.duration}
                    />
                    <Select
                        label="Acceso:"
                        options={cameraOptions}
                        isMulti
                        handleChange={handleChangingCameras}
                        placeholder="Selecciona un acceso"
                        value={event?.cameras}
                    />
                    <DateInput
                        handleChange={date => handleDateChange(date, "start")}
                        name="startDate"
                        label="Fecha de inicio:"
                        value={event?.start}
                        includeHours={showTimeSelector}
                    />
                    <DateInput
                        handleChange={date => handleDateChange(date, "end")}
                        label="Fecha final:"
                        value={event?.end}
                        includeHours={showTimeSelector}
                    />
                    <Input label="Nombre de evento:" name="title" value={event?.title} handleChange={handleEventChange} />
                    <Input
                        label="Actividades:"
                        name="activityDescription"
                        value={event?.activityDescription}
                        handleChange={handleEventChange}
                    />
                    <Input
                        label="Descripción:"
                        name="generalDescription"
                        value={event?.generalDescription}
                        handleChange={handleEventChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {event.edit && (
                        <>
                            <Button className="close-button" onClick={deleteEvent}>
                                Eliminar
                            </Button>
                            <Button className="save-button" onClick={() => toggleForm(MODALS.CHARTS)}>
                                Ver gráficas
                            </Button>
                        </>
                    )}
                    <Button className="save-button" onClick={saveEvent} disabled={disableSaved}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
