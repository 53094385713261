import { isColina } from "utils/Customer";

export * from "./AnnualHistory";
export * from "./MonthlyHistory";
export * from "./MonthlySummary";

export const ANNUAL_OPTIONS = [
    {
        id: 101,
        value: "2022",
        label: "2022",
        type: "year",
    },
    {
        id: 102,
        value: "2023",
        label: "2023",
        type: "year",
    },
    {
        id: 103,
        value: "2024",
        label: "2024",
        type: "year",
    },
].slice(isColina ? 1 : 0, 3);

export const MONTHS = {
    "01": "Enero",
    "02": "Febrero",
    "03": "Marzo",
    "04": "Abril",
    "05": "Mayo",
    "06": "Junio",
    "07": "Julio",
    "08": "Agosto",
    "09": "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
};
