//Import main libraries and components
import React from "react";
import Cookies from "universal-cookie";
import API from "../api/Main";

class Validation extends React.Component {

  fetchPagination() {
    API.get("https://dot", "/cameras")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const cookies = new Cookies();
            cookies.set("pagination", data.count, {
              path: "/",
              maxAge: 31536000,
              sameSite: "None",
              secure: true,
            });
          });
        } else {
          console.log("Error" + response);
          window.location = "/";
        }
      })
      .catch((error) => console.log(error));
  }

  getModules() {

    API.get("https://dot", "/auth/modules").then((response) => {
      if (response.ok) {
        response.json().then((data) => {

          const cookies = new Cookies();
          cookies.set("modules", data.response, {
            path: "/",
            maxAge: 31536000,
            sameSite: "None",
            secure: true,
          });
          

          API.get("https://dot", "/auth/role").then((response_role) => {
            if (response_role.ok) {
              response_role.json().then((role) => {

                const cookies = new Cookies();
                cookies.set("role", role.response, {
                  path: "/",
                  maxAge: 31536000,
                  sameSite: "None",
                  secure: true,
                });
                
                let modules = cookies.get("modules");
                //window.location = "/home";
                if (role.response == "owner" && modules[0] === "item-retail") {
                  //window.location = "/marketing/general";
                  window.location = "/admin/home";
                } else {
                  if (data.response[0] === "item-security") {
                    window.location = "/admin/homeSecurityDash";
                  } else if (data.response[0] == "item-retail") {
                    //window.location = "/marketing/dashboard";
                    window.location = "/admin/home";
                  }
                } 
              });
            } else {
              console.log("error");
            }
          });
        });
      } else {
        console.log("error");
      }
    });
  }

  componentDidMount() {
    this.fetchPagination();
    this.getModules();
  }

  render() {
    return <div></div>;
  }
}

export default Validation;
