import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { BarChart } from "components/BarChart";
import { Select } from "components/Select";
import { ALL_ACCESS } from "pages/Schedule";
import { isColina } from "utils/Customer";
import { getNewOptions } from "utils/Select";
import { getTraffic, getValuePerPeriod } from "utils/Traffic";

export const AnnualHistory = ({ cameraOptions }) => {
    const [cameras, setCameras] = useState([]);
    const [trafficDates, setTrafficDates] = useState({ start: `${isColina ? "2023" : "2021"}-1-1`, end: "2024-12-31" });
    const [traffic, setTraffic] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [annualData, setAnnualData] = useState({});

    useEffect(() => {
        setCameras(cameraOptions.slice(1, cameraOptions.length));
    }, [cameraOptions]);

    useEffect(() => {
        updateTraffic();
    }, [cameras]);

    useEffect(() => {
        setAnnualData(getValuePerPeriod(traffic, true));
    }, [traffic]);

    const updateTraffic = async () => {
        setShowLoader(true);
        setTraffic(await getTraffic(cameras, trafficDates));
        setShowLoader(false);
    };

    const getChartProps = () => {
        const chartData = [];
        for (const key in annualData) {
            chartData.push({ year: key, value: annualData[key] });
        }
        return {
            data: chartData,
            settings: { barName: "Ingresos", dataKey: "value", xKey: "year" },
        };
    };

    const chartProps = useMemo(() => getChartProps(), [annualData]);

    return (
        <div className="annual-history">
            <Select
                className="historical__select--long"
                options={cameraOptions}
                isMulti
                handleChange={selectedOptions =>
                    setCameras(getNewOptions({ allOptions: cameraOptions, selectedOptions, allKey: ALL_ACCESS }))
                }
                placeholder="Accesos"
                value={cameras}
                wrapperClassName="historical__select--wrapper"
            />
            <br />
            {showLoader ? (
                <Spinner animation="border" role="status" style={{ color: "#87ad36", margin: "auto", display: "block" }} />
            ) : (
                <BarChart {...chartProps} />
            )}
        </div>
    );
};
