//Import main libraries and components
import Cookies from "universal-cookie";

class API {
  url;
  header;
  requestOptions;
  constructor() {
    this.url = ".switchai.co";//https://auth-kw7l2y7foa-uc.a.run.app
    this.header = new Headers();
    this.header.append("Content-Type", "application/json");
    this.requestOptions = {
      method: "",
      headers: this.header,
      body: null,
      redirect: "follow",
      credentials: "include",
    };
  }

  async post(subdomain, routeUrl, body) {
    const cookies = new Cookies();
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + cookies.get("token"));
    this.requestOptions = {
      method: "POST",
      headers: tempHeader,
      body: body,
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, this.requestOptions);
  }

  async put(subdomain, routeUrl, body) {
    const cookies = new Cookies();
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + cookies.get("token"));
    this.requestOptions = {
      method: "PUT",
      headers: tempHeader,
      body: body,
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, this.requestOptions);
  }

  async get(subdomain, routeUrl) {
    const cookies = new Cookies();
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + cookies.get("token"));
    let requestOptions = {
      method: "GET",
      headers: tempHeader,
      redirect: "follow",
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, requestOptions);
  }

  async postLogin(subdomain, routeUrl, body) {
    this.requestOptions.method = "POST";
    this.requestOptions.body = body;
    return await fetch(subdomain + this.url + routeUrl, this.requestOptions);
  }

  async postValidation() {
    const cookies = new Cookies();
    let body = JSON.stringify({
      token: cookies.get("token"),
    });
    let subdomain = "https://auth";
    let routeUrl = "/auth/verify/user";
    this.requestOptions.method = "POST";
    this.requestOptions.body = body;

    return await fetch(subdomain + this.url + routeUrl, this.requestOptions);
  }

  async postForCameras(subdomain, routeUrl, tempBody) {
    const cookies = new Cookies();
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + cookies.get("token"));
    let requestOptions = {
      method: "POST",
      headers: tempHeader,
      body: tempBody,
      redirect: "follow",
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, requestOptions);
  }

  async postForm(subdomain, routeUrl, token, tempBody) {
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "POST",
      headers: tempHeader,
      body: tempBody,
      redirect: "follow",
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, requestOptions);
  }

  async delete(subdomain, routeUrl, tempBody) {
    const cookies = new Cookies();
    let tempHeader = new Headers();
    tempHeader.append("Content-Type", "application/json");
    tempHeader.append("Authorization", "Bearer " + cookies.get("token"));
    let requestOptions = {
      method: "DELETE",
      headers: tempHeader,
      body: tempBody,
      redirect: "follow",
      credentials: "include",
    };
    return await fetch(subdomain + this.url + routeUrl, requestOptions);
  }
}

export default new API();
