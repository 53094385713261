//Import main libraries and components
import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//Import images
import logo from "../../imgs/logo-verde.png";
import iconoEmail from "../../imgs/mail.png";

//Import styles
import "../../styles/Register/remember.css";

class RememberPassw extends React.Component {
  handleLogoClick = () => {
    window.location = "https://switchai.co/";
  };

  render() {
    return (
      <Container>
        <div>
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <Container fluid className="Container-Remember">
                <img
                  src={logo}
                  alt="Logo Switch Up"
                  width="56%"
                  className="Logo-Remember"
                  onClick={this.handleLogoClick}
                />
                <br></br>
                <label className="Title-Form-Remember">RECORDAR DATOS</label>
                <div style={{ textAlign: "left", padding: "1rem" }}>
                  <Row>
                    <Col lg={5}>
                      <label className="Text-Email">
                        <img
                          src={iconoEmail}
                          alt="Icono contraseña"
                          width="22rem"
                        />{" "}
                        Correo electrónico
                      </label>
                    </Col>
                    <Col lg={7}>
                      <input
                        className="Input-Email"
                        type="email"
                        name="email"
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <label id="error" className="Error"></label>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="success"
                    className="Submit-Button"
                    onClick={this.handleButtonClick}
                  >
                    Enviar
                  </Button>
                  <div>
                    <Link to="/">
                      <label className="Text-ComeBack">
                        Volver a Inicio de Sesión
                      </label>
                    </Link>
                  </div>
                </div>
              </Container>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default RememberPassw;
