export const getCharacterizationData = data => {
    const { arrayDates, arrayY2InChartBarMen, arrayY2InChartBarWomen } = data;

    const result = Array.from({
        length: arrayY2InChartBarMen.length,
    }).map((_, index) => {
        const [totalMen, totalWomen] = [arrayY2InChartBarMen[index], arrayY2InChartBarWomen[index]];
        const total = totalMen + totalWomen;
        const [men, women] = [getPercentage(totalMen, total), getPercentage(totalWomen, total)];
        return {
            date: arrayDates[index],
            men: totalMen,
            women: totalWomen,
            "men%": `${men}%`,
            "women%": `${women}%`,
        };
    });
    return result;
};

const getPercentage = (value, total) => Math.round((value * 100) / total);
