import firebase from "firebase/app";
import "firebase/messaging";
import api from "./api/Main";
const keys = require("./keys/cloud-message.json");


export class FcmNotifications {
  static firebase = firebase.initializeApp(keys);
  
  static async getToken() {
    try {
      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
      }
      let messaging = null;
      if (this.firebase.messaging.isSupported()){
        messaging = this.firebase.messaging();
      }
      const token = await messaging.getToken();
      return token;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  static async sendTokenFirebase(token) {
    try {
      const body = {
        data: {
          device_token: token.toString(),
        },
      };
      const send = await api.post(
        "https://dot",
        "/notifications/action/save/devicetoken",
        JSON.stringify(body)
      );
      return await send.json();
    } catch (error) {
      return error;
    }
  }

  async deleteToken() {
    try {
      let messaging = null;
      if (this.firebase.messaging.isSupported()){
        messaging = this.firebase.messaging();
      }
      const drop = messaging.deleteToken();
      console.log(drop);
    } catch (error) {
      return error;
    }
  }
}
