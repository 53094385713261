//Import main libraries and components
import React from "react";
//import NavMenuBar from "../../components/NavMenuBar";
import NavMenuBar from "../../components/NavMenu";
import API from "../../api/Main";
import { Row, Col, Button, Dropdown, Alert, Modal } from "react-bootstrap";
import Footer from "../../components/Footer";
import Cookies from "universal-cookie";
import Schedules from "../../utils/HoursConfiguration";
import ConfigureMock from "../../utils/mocks/Configuration.Mock";

//Import images
import logoClock from "../../imgs/clock.png";
import close from "../../imgs/close.png";

//Import styles
import "../../styles/Biosecurity/configuration.css";

const _ = require("underscore");

class Configuration extends React.Component {
  utils = new Schedules();
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pagination: 1,
      counter: 0,
      cameras: [],
      idCameraConfigure: undefined,
      nameCameraConfigure: undefined,
      idxDropSchedule: 0,
      initHour: "00",
      initMinute: "00",
      finishHour: "11",
      finishMinute: "59",
      hourInit: "AM",
      hourFinish: "PM",
      scheduleToShow: [],
      schedulesSaved: [],
      maximumPeople: 0,
      minimumDistance: 2,
      showAlertSuccess: false,
      showAlertError: false,
      showAlertSchedule: false,
      areYouSure: false,
      actualConfiguration: ConfigureMock()["data"]["configuration"],
    };
  }

  handleClickLoad = () => {
    this.getListCameras(this.state.pagination + 1);
  };

  handleClickCamera = async (e) => {
    this.setState({ loading: true });
    await this.configureCamera(e.target.id, e.target.innerText);
    await this.showConfiguration();
  };

  clickChangeTimeInit = (e) => {
    this.setState({ hourInit: e.target.innerText });
  };

  clickChangeTimeFinish = (e) => {
    this.setState({ hourFinish: e.target.innerText });
  };

  handleChangeInitHour = (h) => {
    this.setState({ initHour: h.target.value });
  };

  handleChangeInitMinute = (i) => {
    this.setState({ initMinute: i.target.value });
  };

  handleChangeFinishHour = (h) => {
    this.setState({ finishHour: h.target.value });
  };

  handleChangeFinishMinute = (f) => {
    this.setState({ finishMinute: f.target.value });
  };

  handleChangeMinimumDistance = (m) => {
    this.setState({ minimumDistance: m.target.value });
  };

  handleChangeMaximumPeople = (m) => {
    this.setState({ maximumPeople: m.target.value });
  };

  async configureCamera(idCamera, nameCamera) {
    document.getElementById("idCamera").innerText = nameCamera;
    this.setState({
      idCameraConfigure: idCamera,
      nameCameraConfigure: nameCamera,
    });
  }

  sendInfoToBackend = async () => {
    if (this.state.actualConfiguration.schedule !== 0) {
      document.getElementById("idCamera").innerText = "";
      const norm = this.state.actualConfiguration;
      norm["models"] = [
        {
          name: "ML-D1",
          config: [
            {
              field: "max_people",
              value: this.state.maximumPeople,
            },
          ],
        },
        {
          name: "ML-D2",
          config: [
            {
              field: "min_distance",
              value: this.state.minimumDistance,
            },
          ],
        },
      ];
      const dif = JSON.stringify({
        data: {
          create_date: new Date(),
          update_date: new Date(),
          configuration: norm,
        },
      });
      await this.putConfiguration(dif);
    } else {
      this.setState({
        showAlertError: true,
      });
    }
  };

  saveHour = () => {
    let hoursFromNow = 0;
    let minutesFromNow = 0;
    let hoursToNow = 0;
    let minutesToNow = 0;
    ///------------------------
    let timespanFrom = {
      format: this.state.hourInit,
      hours: this.state.initHour,
      minutes: this.state.initMinute,
    };
    let timespanTo = {
      format: this.state.hourFinish,
      hours: this.state.finishHour,
      minutes: this.state.finishMinute,
    };

    let actualSchedule = {
      from: timespanFrom,
      to: timespanTo,
    };

    //asigned values to hours from
    if (!this.utils.isMorning(timespanFrom)) {
      hoursFromNow =
        parseInt(timespanFrom["hours"]) === 12
          ? parseInt(timespanFrom["hours"])
          : parseInt(timespanFrom["hours"]) + 12;
    } else {
      hoursFromNow = timespanFrom["hours"];
    }
    minutesFromNow = timespanFrom["minutes"];
    //asigned values to hours To
    if (!this.utils.isMorning(timespanTo)) {
      hoursToNow =
        parseInt(timespanTo["hours"]) === 12
          ? parseInt(timespanTo["hours"])
          : parseInt(timespanTo["hours"]) + 12;
    } else {
      hoursToNow = timespanTo["hours"];
    }
    minutesToNow = timespanTo["minutes"];
    //-------------------------------

    let allConfig = this.state.actualConfiguration;
    let schedule = allConfig["schedule"];

    let isValidate = this.utils.isRangeEqual(
      timespanFrom,
      timespanTo,
      schedule
    );

    //------------------------------------------------validation
    //Se verifica si el Array de Schedules tiene horarios
    if (_.size(schedule) <= 0) {
      //sino tiene horarios se hacen validaciones y se inserta el horario actual
      if (!_.isEqual(timespanFrom, timespanTo)) {
        let tmpHorarios = this.state.scheduleToShow;
        schedule.push({
          from: timespanFrom,
          to: timespanTo,
          isSaved: 0,
        });
        let currentIndex = schedule.length - 1;
        tmpHorarios.push(
          <div className="ElementSchedule" key={this.state.counter}>
            <label className="Title-Secondary-List">
              <img src={logoClock} alt="Icono viñeta" width="20rem"></img>
              Inicio : {timespanFrom["hours"]}:{timespanFrom["minutes"]}{" "}
              {timespanFrom["format"]} Fin : {timespanTo["hours"]}:
              {timespanTo["minutes"]} {timespanTo["format"]}{" "}
              <img
                src={close}
                alt="Icono viñeta"
                width="20rem"
                onClick={async () => {
                  await this.utils.indexSchedule(
                    {
                      from: timespanFrom,
                      to: timespanTo,
                      isSaved: 0,
                    },
                    this
                  );
                }}
              ></img>
            </label>
          </div>
        );
        this.incrementar();
        this.setState({
          scheduleToShow: tmpHorarios,
          maximumPeople: this.state.maximumPeople,
          minimumDistance: this.state.minimumDistance,
        });
      } else {
        this.setState({
          showAlertSchedule: true,
        });
      }
    } else {
      //Si tiene horarios se verifica si ya existe y se hacen validaciones
      console.log(this.state.actualConfiguration);
      if (!_.isEqual(timespanFrom, timespanTo)) {
        const exist = this.utils.existSchedule(actualSchedule, schedule);
        if (!exist) {
          /***
           * AQUI SE VERIFICA QUE NO SE CRUCE NINGUN HORARIO
           */
          if (isValidate) {
            let tmpHorarios = this.state.scheduleToShow;
            schedule.push({
              from: timespanFrom,
              to: timespanTo,
              isSaved: 0,
            });
            tmpHorarios.push(
              <div className="ElementSchedule" key={this.state.counter}>
                <label className="Title-Secondary-List">
                  <img src={logoClock} alt="Icono viñeta" width="20rem"></img>
                  Inicio : {timespanFrom["hours"]}:{timespanFrom["minutes"]}{" "}
                  {timespanFrom["format"]} Fin : {timespanTo["hours"]}:
                  {timespanTo["minutes"]} {timespanTo["format"]}{" "}
                  <img
                    src={close}
                    alt="Icono viñeta"
                    width="20rem"
                    onClick={async () => {
                      await this.utils.indexSchedule(
                        {
                          from: timespanFrom,
                          to: timespanTo,
                          isSaved: 0,
                        },
                        this
                      );
                    }}
                  ></img>
                </label>
              </div>
            );
            this.incrementar();
            this.setState({
              scheduleToShow: tmpHorarios,
              maximumPeople: this.state.maximumPeople,
              minimumDistance: this.state.minimumDistance,
            });
          } else {
            this.setState({
              showAlertEqualSchedule: true,
            });
          }
        } else {
          this.setState({
            showAlertSchedule: true,
          });
        }
      } else {
        this.setState({
          showAlertSchedule: true,
        });
      }
    }
  };

  async putConfiguration(body) {
    try {
      const res = await API.post(
        "https://dot",
        `/configurations/modify/${this.state.idCameraConfigure}`,
        body
      );
      if (res.ok) {
        this.setState({
          initHour: "00",
          initMinute: "00",
          finishHour: "11",
          finishMinute: "59",
          hourInit: "AM",
          hourFinish: "PM",
          schedule: {
            from: [],
            to: [],
          },
          scheduleToShow: [],
          tempArray: [],
          maximumPeople: 0,
          minimumDistance: 2,
          showAlertSuccess: true,
        });
      } else {
        console.log(res);
      }
      const tk = await res.text();
      return tk;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async showConfiguration() {
    const res = await API.get(
      "https://dot",
      `/configurations/${this.state.idCameraConfigure}`
    );
    const mock = ConfigureMock()["data"]["configuration"];
    //get backend configuration
    const configuration = await res.json();
    //states set
    if (configuration["models"].length === 0) {
      this.setState({
        maximumPeople: 0,
        minimumDistance: 2,
      });
    } else {
      _.each(configuration["models"], (value) => {
        if (value["name"] === "ML-D1") {
          mock["models"].push({
            name: "ML-D1",
            config: [
              {
                field: "max_people",
                value:
                  value["config"][0] === undefined
                    ? 0
                    : value["config"][0]["value"],
              },
            ],
          });
          this.setState({
            maximumPeople:
              value["config"][0] === undefined ? 0 : value["config"][0]["value"],
          });
        } else if (value["name"] === "ML-D2") {
          mock["models"].push({
            name: "ML-D2",
            config: [
              {
                field: "min_distance",
                value:
                  value["config"][0] === undefined
                    ? 0
                    : value["config"][0]["value"],
              },
            ],
          });
          this.setState({
            minimumDistance:
              value["config"][0] === undefined ? 0 : value["config"][0]["value"],
          });
        } else {
          return;
        }
      });
    }
    //iterate configuration
    _.each(configuration["schedule"], (ele) => {
      mock["schedule"].push({
        from: {
          format: ele["from"]["format"],
          hours: ele["from"]["hours"],
          minutes: ele["from"]["minutes"],
        },
        to: {
          format: ele["to"]["format"],
          hours: ele["to"]["hours"],
          minutes: ele["to"]["minutes"],
        },
        isSaved: 1,
      });
    });
    //actual configuration
    const actualConfiguration = mock;
    //create jsx for show configuration
    const horariosJSX = [];
    this.setState({ counter: 0 });
    _.each(actualConfiguration["schedule"], async (horario, key) => {
      horariosJSX.push(
        <div className="ElementSchedule">
          <label className="Title-Secondary-List">
            <img src={logoClock} alt="Icono viñeta" width="20rem"></img>
            Inicio: {horario["from"]["hours"]}:{horario["from"]["minutes"]}{" "}
            {horario["from"]["format"]} Fin: {horario["to"]["hours"]}:
            {horario["to"]["minutes"]} {horario["to"]["format"]}{" "}
            <img
              key={key}
              src={close}
              alt="Icono viñeta"
              width="20rem"
              onClick={async (e) =>
                await this.utils.indexSchedule(horario, this)
              }
            ></img>
          </label>
        </div>
      );
      this.incrementar();
    });
    this.setState({
      scheduleToShow: horariosJSX,
      actualConfiguration: actualConfiguration,
      loading: false,
    });
  }

  incrementar() {
    this.setState({
      counter: this.state.counter + 1,
    });
  }
  getListCameras(pagination) {
    API.get("https://dot", "/cameras/" + pagination)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickCamera}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                >
                  Cámara {element.name}
                </Dropdown.Item>
              );
              this.state.cameras.push(item);
            });
            const cookies = new Cookies();
            if (pagination === cookies.get("pagination")) {
              document.getElementById("ButtonLoad").style.display = "none";
            } else {
            }
            this.setState({
              pagination: pagination,
              idCameraConfigure: this.state.cameras[0].key,
              loading: false,
            });
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    document.title = "Switch AI | Configuración";
    this.getListCameras(this.state.pagination);
    this.styleToActive();
  }

  styleToActive() {
    /* document.getElementById("activeConfigBio").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeConfigBio").style.borderStyle = "dashed";
    document.getElementById("activeConfigBio").style.borderColor = "#84ac34";
    document.getElementById("activeConfigBio").style.borderRadius = "1rem";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none";
    document.getElementById("activeCamsBio").style.color = "";
    document.getElementById("activeCamsBio").style.borderStyle = "none";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none"; */
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Configuration">
            <div>
              <Alert show={this.state.showAlertSuccess} variant="success">
                <Alert.Heading className="Title-Alert">
                  Registro Exitoso
                </Alert.Heading>
                <p className="Text-Alert">
                  Los datos de configuración se registraron correctamente.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => this.setState({ showAlertSuccess: false })}
                    variant="outline-success"
                    className="Title-Button-Alert"
                  >
                    Cerrar
                  </Button>
                </div>
              </Alert>
            </div>
            <div>
              <Alert show={this.state.showAlertError} variant="danger">
                <Alert.Heading className="Title-Alert">Error</Alert.Heading>
                <p className="Text-Alert">Debes añadir un horario.</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => this.setState({ showAlertError: false })}
                    variant="outline-danger"
                    className="Title-Button-Alert"
                  >
                    Cerrar
                  </Button>
                </div>
              </Alert>
            </div>
            <div>
              <Alert show={this.state.showAlertSchedule} variant="danger">
                <Alert.Heading className="Tittle-Alert">Error</Alert.Heading>
                <p className="Text-Alert">
                  Las horas ingresadas coinciden o ya existen.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => this.setState({ showAlertSchedule: false })}
                    variant="outline-danger"
                    className="Title-Button-Alert"
                  >
                    Cerrar
                  </Button>
                </div>
              </Alert>
            </div>
            <div>
              <Modal
                show={this.state.showAlertEqualSchedule}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title className="Tittle-Alert">
                    Hora inválida
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    El horario que has ingresado coincide con otro que ya
                    existe.
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      this.setState({ showAlertEqualSchedule: false })
                    }
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div>
              <Modal
                show={this.state.areYouSure}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title className="Tittle-Alert">Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>¿Estás seguro de eliminar este horario?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.setState({ areYouSure: false })}
                  >
                    Cerrar
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.utils.dropSchedule(this);
                    }}
                  >
                    Aceptar
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <div>
                  <div className="Div-Justify">
                    <div className="form-inline">
                      <label className="Title-Text-Configuration">
                        Por favor selecciona la cámara que deseas configurar
                      </label>

                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="Dropdown-Button"
                        >
                          Cámaras
                        </Dropdown.Toggle>
                        <Dropdown.Menu>{this.state.cameras}</Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div>
                      <Button
                        id="ButtonLoad"
                        onClick={this.handleClickLoad}
                        variant="success"
                        className="Button-Success-Load-More"
                      >
                        Cargar más
                      </Button>
                    </div>
                    <br></br>
                    <label id="idCamera" className="Label-Text"></label>
                  </div>

                  <div id="Configuration" className="Container-Configuration">
                    <div className="Container-Box">
                      <Row>
                        <Col lg={12}>
                          <div className="Div-Center-Title">
                            <label className="Title-Black-Configuration">
                              Configuración de horario
                            </label>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          <div>
                            <label className="Title-Configuration">
                              Hora de inicio
                            </label>
                            <label className="Title-Secondary">(HH:MM)</label>
                            <div>
                              <label className="form-inline">
                                <input
                                  className="Input-Block"
                                  onChange={this.handleChangeInitHour}
                                  value={this.state.initHour}
                                ></input>
                                <label
                                  style={{
                                    marginLeft: "0.3rem",
                                    marginRight: "0.3rem",
                                  }}
                                >
                                  :
                                </label>
                                <input
                                  className="Input-Block"
                                  onChange={this.handleChangeInitMinute}
                                  value={this.state.initMinute}
                                ></input>
                                <Dropdown style={{ marginLeft: "1rem" }}>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="Title-Dropdown"
                                  >
                                    {this.state.hourInit}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      className="Item-Dropdown"
                                      onClick={this.clickChangeTimeInit}
                                    >
                                      AM
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="Item-Dropdown"
                                      onClick={this.clickChangeTimeInit}
                                    >
                                      PM
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </label>
                            </div>
                          </div>
                          <div>
                            <label className="Title-Configuration">
                              Hora fin
                            </label>
                            <label className="Title-Secondary">(HH:MM)</label>
                            <div>
                              <label className="form-inline">
                                <input
                                  className="Input-Block"
                                  onChange={this.handleChangeFinishHour}
                                  value={this.state.finishHour}
                                ></input>
                                <label
                                  style={{
                                    marginLeft: "0.3rem",
                                    marginRight: "0.3rem",
                                  }}
                                >
                                  :
                                </label>
                                <input
                                  className="Input-Block"
                                  onChange={this.handleChangeFinishMinute}
                                  value={this.state.finishMinute}
                                ></input>
                                <Dropdown style={{ marginLeft: "1rem" }}>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="Title-Dropdown"
                                  >
                                    {this.state.hourFinish}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      className="Item-Dropdown"
                                      onClick={this.clickChangeTimeFinish}
                                    >
                                      AM
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="Item-Dropdown"
                                      onClick={this.clickChangeTimeFinish}
                                    >
                                      PM
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </label>
                            </div>
                          </div>
                          <Button
                            className="More-Button"
                            variant="success"
                            onClick={this.saveHour}
                          >
                            Añadir
                          </Button>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={12}>
                              <div>
                                <label className="Title-Configuration">
                                  Horarios añadidos
                                </label>
                                <br></br>
                                <div>{this.state.scheduleToShow}</div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col lg={6}>
                        <div className="Container-Box">
                          <div className="Div-Center-Title">
                            <label className="Title-Black-Configuration">
                              Configuración de Aforo
                            </label>
                          </div>
                          <label className="Title-Configuration">
                            Aforo máximo
                          </label>
                          <label className="Title-Secondary">
                            (Número de personas)
                          </label>
                          <label>
                            <input
                              className="Input-Block"
                              value={this.state.maximumPeople}
                              onChange={this.handleChangeMaximumPeople}
                            ></input>
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="Container-Box">
                          <div className="Div-Center-Title">
                            <label className="Title-Black-Configuration">
                              Configuración de Distanciamiento
                            </label>
                          </div>
                          <label className="Title-Configuration">
                            Distancia mínima
                          </label>
                          <label className="Title-Secondary">(Metros)</label>
                          <label>
                            <input
                              className="Input-Block"
                              value={this.state.minimumDistance}
                              onChange={this.handleChangeMinimumDistance}
                            ></input>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={12}>
                      <div className="Div-Center">
                        <Button
                          className="Submit-Button"
                          variant="success"
                          onClick={this.sendInfoToBackend}
                        >
                          Guardar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="Load-Login">
                    {this.state.loading && <div className="loader"></div>}
                  </div>
                </div>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Configuration;
