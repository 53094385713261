import API from "api/Main";
import { searchItem } from "./Array";
import { MONTHS } from "constants/Date";

export const getCapacityPeaks = data => {
    const values = data.map(({ value }) => value);
    const [maximum, minimum] = [Math.max(...values), Math.min(...values)];
    return { maximum: searchItem(data, { value: maximum }), minimum: searchItem(data, { value: minimum }) };
};

export const getMostVisitedCameras = (data, cameras) => {
    if (!cameras.length) return [];
    const visits = {};
    data.forEach((items, index) =>
        items.forEach(item => {
            const name = cameras[index]?.name;
            visits[name] = (visits[name] ?? 0) + item.come_out;
        })
    );
    const values = Object.values(visits)
        .sort()
        .slice(cameras.length - 4, cameras.length);
    const total = [];

    for (const key in visits) {
        if (values.includes(visits[key]) && total.length < 4) total.push({ name: key, total: visits[key] });
    }
    return total;
};

export const getDailyTrafficUrl = (id, dates) => {
    return `/analytics/resume-traffic/?groupId=${id}&start=${dates.start}&end=${dates.end}`;
};

export const getPeakVisits = data => {
    const values = Object.values(data);
    const [maximum, minimum] = [Math.max(...values), Math.min(...values)];
    return { maximum, minimum };
};

export const getTraffic = async (cameras, dates) => {
    if (cameras.length && dates.start) {
        const traffic = await Promise.all(
            cameras.map(({ id }) => API.get("https://dot", getDailyTrafficUrl(id, dates)).then(res => res.json()))
        );
        const hasErrors = traffic.some(item => item?.errors);
        if (!hasErrors) return traffic;
    }
    return [];
};

export const getValuePerPeriod = (traffic, isYear = false) => {
    if (!traffic.length) return {};
    const total = {};
    traffic.flat().map(({ fecha: date, ...item }) => {
        const key = isYear ? date.split("-")[0] : MONTHS[date.split("-")[1]];
        total[key] = (total[key] ?? 0) + (item.come_out ?? 0);
    });
    return total;
};
