import React, { useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import Footer from "components/Footer";
import NavMenuBar from "components/NavMenuBar";
import {
    CapacityCard,
    TrafficCard,
    CardWithoutPeople,
    DailyTrafficCard,
    PeoplePeakCard,
    RunningPeopleCard,
    BicyclePeopleCard,
    ArmedPeopleCard,
    RestrictedAccessCard,
    LyingPersonCard,
    AgglomerationCard,
    ObjectMovementCard,
    PermanenceDetectionCard,
    ManagementCard,
    PetCard,
} from "./components";
import { ALERT } from "constants/Alert";
import "./Alerts.scss";

const Alerts = () => {
    const [activeAlerts, setActiveAlerts] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const toggleAlert = alert => {
        if (activeAlerts.includes(alert)) return setActiveAlerts(activeAlerts.filter(activeAlert => activeAlert !== alert));
        setActiveAlerts([...activeAlerts, alert]);
    };

    const toggleModal = () => setShowModal(!showModal);

    return (
        <div className="alerts">
            <NavMenuBar />
            <main className="mb-5" id="genericBody">
                <div className="alerts__header">
                    <h2 className="alerts__title">Configuración de alertas</h2>
                    <button className="alerts__button" style={{ margin: 0 }} onClick={toggleModal}>
                        Guardar cambios
                    </button>
                </div>
                <div className="alerts__content">
                    <CapacityCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.CAPACITY} />
                    <TrafficCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.TRAFFIC} />
                    <DailyTrafficCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.DAILY_TRAFFIC} />
                    <hr className="alerts__separator" />
                    <CardWithoutPeople activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.WITHOUT_PEOPLE} />
                    <PeoplePeakCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.PEOPLE_PEAK} />
                    <RunningPeopleCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.PEOPLE_RUNNING} />
                    <BicyclePeopleCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.PEOPLE_ON_BICYCLES} />
                    <ArmedPeopleCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.ARMED_PEOPLE} />
                    <RestrictedAccessCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.RESTRICTED_ACCESS} />
                    <PetCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.PETS} />
                    <LyingPersonCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.LYING_PERSON} />
                    <AgglomerationCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.AGGLOMERATION} />
                    <ObjectMovementCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.OBJECT_MOVEMENT} />
                    <PermanenceDetectionCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.DWELL_DETECTION} />

                    <hr className="alerts__separator" />
                    <ManagementCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.VISITOR_MANAGEMENT} />
                    <ManagementCard activeAlerts={activeAlerts} toggleAlert={toggleAlert} type={ALERT.CAMERA_MANAGEMENT} />
                </div>
            </main>
            <div className="alerts__buttons">
                <button className="alerts__button" onClick={toggleModal}>
                    Guardar cambios
                </button>
            </div>
            <Footer />
            {showModal && (
                <Modal animation={false} show onHide={toggleModal}>
                    <Modal.Header closeButton onClick={toggleModal}>
                        <Modal.Title>Se van a guardar los cambios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-center gap-4">
                            <Button className="close-button" onClick={toggleModal}>
                                Cancelar
                            </Button>
                            <Button className="save-button" onClick={() => alert("guardando")}>
                                Confirmar
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default Alerts;
