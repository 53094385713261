//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";

//Import images
import selection from "../../imgs/store.jpg"

//Import styles
import "../../styles/Configuration/pointsSelection.css";

class ZonesSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: "",
      cordinates: {
        zone1: [],
        zone2: [],
        zone3: [],
        zone4: [],
      },
      points: [],
      counter: 0,
    }
  }

  deletePoints = () => {
    let tempCordinate = this.state.cordinates;
    tempCordinate.zone1 = [];
    tempCordinate.zone2 = [];
    tempCordinate.zone3 = [];
    tempCordinate.zone4 = [];
    this.setState({ points: [], counter: 0, cordinates: tempCordinate });
  }

  savePoints = () => {
    console.log(this.state.cordinates);
  }

  findPositions(img) {
    if (typeof (img.offsetParent) !== "undefined") {
      for (var posX = 0, posY = 0; img; img = img.offsetParent) {
        posX += img.offsetLeft;
        posY += img.offsetTop;
      }
      return [posX, posY];
    }
    else {
      return [img.x, img.y];
    }
  }

  getCoordinates = (e) => {
    var posX = 0;
    var posY = 0;
    var imgPoints;
    imgPoints = this.findPositions(this.state.img);
    if (!e) var e = window.event;
    if (e.pageX || e.pageY) {
      posX = e.pageX;
      posY = e.pageY;
    }
    else if (e.clientX || e.clientY) {
      posX = e.clientX + document.body.scrollLeft
        + document.documentElement.scrollLeft;
      posY = e.clientY + document.body.scrollTop
        + document.documentElement.scrollTop;
    }
    posX = posX - imgPoints[0];
    posY = posY - imgPoints[1];
    document.getElementById("x").innerHTML = posX;
    document.getElementById("y").innerHTML = posY;

    //Save points
    let pointsArray = this.state.points;
    let objectCordinates = this.state.cordinates;
    let cordinate = [posX, posY];

    if (this.state.counter < 16) {

      if (this.state.counter < 4) {
        objectCordinates.zone1.push(cordinate);
        let point = (
          <div id={this.state.counter} onClick={this.deletePoint} style={{ left: 24 + posX, top: 67.7 + 24 + posY, width: "1rem", height: "1rem", backgroundColor: "#3CAB3F", borderRadius: "0.5rem", position: "absolute", zIndex: "1000", fontSize: "0.1rem" }}>{this.state.counter}</div>
        );
        pointsArray.push(point);
      } else if (this.state.counter >= 4 && this.state.counter < 8) {
        objectCordinates.zone2.push(cordinate);
        let point = (
          <div id={this.state.counter} onClick={this.deletePoint} style={{ left: 24 + posX, top: 67.7 + 24 + posY, width: "1rem", height: "1rem", backgroundColor: "#5944AB", borderRadius: "0.5rem", position: "absolute", zIndex: "1000", fontSize: "0.1rem" }}>{this.state.counter}</div>
        );
        pointsArray.push(point);
      } else if (this.state.counter >= 8 && this.state.counter < 12) {
        objectCordinates.zone3.push(cordinate);
        let point = (
          <div id={this.state.counter} onClick={this.deletePoint} style={{ left: 24 + posX, top: 67.7 + 24 + posY, width: "1rem", height: "1rem", backgroundColor: "#AB2260", borderRadius: "0.5rem", position: "absolute", zIndex: "1000", fontSize: "0.1rem" }}>{this.state.counter}</div>
        );
        pointsArray.push(point);
      } else if (this.state.counter >= 12) {
        objectCordinates.zone4.push(cordinate);
        let point = (
          <div id={this.state.counter} onClick={this.deletePoint} style={{ left: 24 + posX, top: 67.7 + 24 + posY, width: "1rem", height: "1rem", backgroundColor: "#AB9D2B", borderRadius: "0.5rem", position: "absolute", zIndex: "1000", fontSize: "0.1rem" }}>{this.state.counter}</div>
        );
        pointsArray.push(point);
      }

      let tempCounter = this.state.counter + 1;
      this.setState({ points: pointsArray, counter: tempCounter, cordinates: objectCordinates });
    }
  }

  getPoints() {
    let tempImg = document.getElementById("img");
    this.setState({ img: tempImg });
    tempImg.onmousedown = this.getCoordinates;
  }

  componentDidMount() {
    document.title = "Switch AI | Selección de ";
    this.getPoints();
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" className="Body-Points-Selection" onClick={this.closeSideBar}>
          <Row><div>{this.state.points}</div>
            <Col lg={8}>
              <img src={selection} id="img" style={{ marginLeft: "24px", marginTop: "24px" }}></img>

            </Col>
            <Col lg={4} className="mt-4">
              <div className="form-inline">
                <div style={{ width: "1rem", height: "1rem", backgroundColor: "#3CAB3F", borderRadius: "0.5rem", marginRight: "0.5rem" }}></div><input className="Input-Zone" placeholder="Nombre zona 1"></input>
              </div>
              <div className="form-inline">
                <div style={{ width: "1rem", height: "1rem", backgroundColor: "#5944AB", borderRadius: "0.5rem", marginRight: "0.5rem" }}></div><input className="Input-Zone" placeholder="Nombre zona 2"></input>
              </div><div className="form-inline">
                <div style={{ width: "1rem", height: "1rem", backgroundColor: "#AB2260", borderRadius: "0.5rem", marginRight: "0.5rem" }}></div><input className="Input-Zone" placeholder="Nombre zona 3"></input>
              </div><div className="form-inline">
                <div style={{ width: "1rem", height: "1rem", backgroundColor: "#AB9D2B", borderRadius: "0.5rem", marginRight: "0.5rem" }}></div><input className="Input-Zone" placeholder="Nombre zona 4"></input>
              </div>
              <Button onClick={this.deletePoints} variant="dark" className="Button-Reload">Reiniciar</Button>
              <Button onClick={this.savePoints} variant="success" className="Button-Save">Guardar</Button>
            </Col>
          </Row>
          <p className="d-none">X: <label id="x"></label></p>
          <p className="d-none">Y: <label id="y"></label></p><br></br>
        </div>
        <Footer></Footer>
      </div >
    );
  }
}

export default ZonesSelection;