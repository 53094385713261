const ConfigureMock = () => ({
  data: {
    create_date: new Date(),
    update_date: new Date(),
    configuration: {
      schedule: [],
      models: []
    },
  },
})

export default ConfigureMock