import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, Legend, ResponsiveContainer, Tooltip, YAxis, CartesianGrid, LabelList } from "recharts";
import { COLORS } from "constants/Colors";

export const StackedBarChart = ({ data = [], settings }) => {
    const { dataKey, xKey, keys, showTick = false } = settings;

    const maxDomain = useMemo(() => Math.max(...data.map(item => item?.[dataKey])), [data, dataKey]);

    return (
        <ResponsiveContainer width="100%" className="stacked-bar-chart">
            <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip keys={keys} />} />
                <XAxis dataKey={xKey} fontSize={10} {...(data.length <= 6 && { interval: 0 })} />
                <YAxis tickCount={20} domain={[0, maxDomain]} fontSize={12} tick={showTick} />
                <Legend
                    payload={keys.map(({ label }, index) => ({
                        id: label,
                        type: "square",
                        value: label,
                        color: COLORS[index],
                    }))}
                    align="center"
                    verticalAlign="top"
                    wrapperStyle={{
                        height: 20,
                        marginBottom: 30,
                        top: -10,
                    }}
                />
                {keys.map(({ key, label }, index) => (
                    <Bar key={key} dataKey={key} fill={COLORS[index]} name={label} stackId="a">
                        <LabelList dataKey={key} position="center" fill="white" fontSize={12} />
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

const CustomTooltip = ({ payload, label }) => (
    <div className="custom-tooltip">
        <span>{label}</span>
        {payload.map(({ dataKey, name, payload: item }, index) => (
            <p key={dataKey} style={{ color: COLORS[index] }}>
                {name}: {item[`${dataKey}%`]}
            </p>
        ))}
    </div>
);
