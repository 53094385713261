//Import main libraries and components
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//Import images
import logoDot from "../imgs/green-logo.png";

//Import styles
import "../styles/Shared/footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="Footer-Bar">
        <Container>
          <Row>
            <Col lg={12}>
              <label className="Footer-Text">
                <img
                  src={logoDot}
                  alt="Logo Switch AI"
                  className="Icon-Menu-Footer"
                ></img>
                2021 Switch AI ©Todos los derechos reservados
              </label>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
