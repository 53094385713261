//Import main libraries and components
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import API from "../../api/Main";
import Cookies from "universal-cookie";
import { FcmNotifications } from "../../init-fcm";

//Import images
import logo from "../../imgs/green-logo.png";
import iconoUser from "../../imgs/mail.png";
import iconoPassword from "../../imgs/passw.png";

//Import styles
import "../../styles/Login/login.css";

var validator = require("validator");

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
        };
    }

    handleLogoClick = () => {
        window.location = "https://switchai.co/";
    };

    handleChangeEmail = e => {
        this.setState({ email: e.target.value });
        document.getElementById("error").style.display = "none";
    };

    handleChangePassw = p => {
        this.setState({ password: p.target.value });
        document.getElementById("error").style.display = "none";
    };

    handleButtonClick = b => {
        this.fetchInfo();
    };

    onKeyUp = e => {
        if (e.charCode === 13) {
            this.handleButtonClick();
        }
    };

    fetchInfo = () => {
        this.setState({ loading: true });
        if (validator.isEmail(this.state.email)) {
            const cookies = new Cookies();
            let body = JSON.stringify({
                username: this.state.email,
                password: this.state.password,
                rememberMe: this.state.checked === "true",
            });
            API.postLogin("https://dot", "/auth/login", body)
                .then(response => {
                    if (response.ok) {
                        response.json().then(async data => {
                            this.setState({ loading: false });
                            let maxAgeTime = data.response.exp - data.response.iat;
                            cookies.set("token", data.response.token, {
                                path: "/",
                                maxAge: maxAgeTime,
                                sameSite: "None",
                                secure: true,
                            });
                            console.log("data response", data.response);
                            await this.sendToken();
                            localStorage.setItem("UserName", data.response.name);
                            localStorage.setItem("Email", this.state.email);
                            this.props.history.push("/validation/");
                        });
                    } else {
                        this.setState({ loading: false });
                        document.getElementById("error").style.display = "";
                        document.getElementById("error").innerText = "Datos de inicio de sesión incorrectos";
                    }
                })
                .catch(error => console.log("error", error));
        } else {
            this.setState({ loading: false });
            document.getElementById("error").style.display = "";
            document.getElementById("error").innerText = "Por favor ingresa un correo válido";
        }
    };

    async sendToken() {
        try {
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                // If it's okay let's create a notification
                const token = await FcmNotifications.getToken();
                await FcmNotifications.sendTokenFirebase(token);
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission(async permission => {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        const token = await FcmNotifications.getToken();
                        await FcmNotifications.sendTokenFirebase(token);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        document.title = "Switch AI | Login";
    }

    render() {
        return (
            <Container>
                <div>
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <Container fluid className="Container-Login">
                                <div className="Load-Login">{this.state.loading && <div className="loader"></div>}</div>
                                <img
                                    src={logo}
                                    alt="Logo Switch AI"
                                    width="56%"
                                    className="Logo-Login"
                                    onClick={this.handleLogoClick}
                                />
                                <br></br>
                                <label className="Title-Form">INICIO DE SESIÓN</label>
                                <div>
                                    <div className="Div-Login">
                                        <label className="Text-Login">
                                            <img src={iconoUser} alt="Icono usuario" width="24rem" /> Correo electrónico <br></br>
                                            <input
                                                className="Input-Login"
                                                type="email"
                                                name="email"
                                                onChange={this.handleChangeEmail}
                                                value={this.state.email}
                                                onKeyPress={this.onKeyUp}
                                            />
                                        </label>{" "}
                                        <br></br>
                                        <label className="Text-Login">
                                            <img src={iconoPassword} alt="Icono contraseña" width="28rem" /> Contraseña <br></br>
                                            <input
                                                className="Input-Login"
                                                type="password"
                                                name="password"
                                                id="password"
                                                onChange={this.handleChangePassw}
                                                value={this.state.password}
                                                onKeyPress={this.onKeyUp}
                                            />
                                        </label>{" "}
                                        <br></br>
                                    </div>
                                    <div>
                                        <label id="error" className="Error"></label>
                                    </div>
                                    <div className="Div-Center">
                                        <Button variant="success" className="Submit-Button" onClick={this.handleButtonClick}>
                                            Ingresar
                                        </Button>
                                    </div>
                                </div>
                                <div style={{ display: "none" }}>
                                    <Link to="/remember">
                                        <label className="Text-Options">¿Olvidaste tu contraseña?</label>
                                    </Link>
                                </div>
                                <div style={{ display: "none" }}>
                                    <Link to="/register">
                                        <label className="Text-Options">¿No tienes cuenta? Regístrate</label>
                                    </Link>
                                </div>
                            </Container>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default Login;
