import React, { useMemo } from "react";
import { BarChart as Chart, Bar, XAxis, Legend, ResponsiveContainer, Tooltip, YAxis, Cell } from "recharts";
import "styles/chart.css";

export const BarChart = ({ data = [], settings }) => {
    const { barName = "", dataKey, xKey } = settings;

    const maxDomain = useMemo(() => Math.max(...data.map(item => item?.[dataKey])), [data, dataKey]);

    const maximum = Math.max(...data.map(item => item?.[dataKey]));
    const minimum = Math.min(...data.map(item => item?.[dataKey]));
    return (
        <ResponsiveContainer width={settings?.width ?? 570} height={settings?.height ?? 300}>
            <Chart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <Tooltip itemStyle={{ fontSize: 12 }} labelStyle={{ fontSize: 12 }} />
                <XAxis dataKey={xKey} fontSize={10} {...(data.length <= 6 && { interval: 0 })} />
                <YAxis tickCount={20} domain={[0, maxDomain]} fontSize={12} />
                <Legend type="square" />
                <Bar dataKey={dataKey} fill="#87ad36" name={barName}>
                    {data.map((item, index) => {
                        const value = item?.[dataKey];
                        return (
                            <Cell
                                key={`cell-${index}`}
                                fill={value === maximum ? "#87ad36" : value === minimum ? "#c0223f" : "#606060"}
                            />
                        );
                    })}
                </Bar>
            </Chart>
        </ResponsiveContainer>
    );
};

// import React, { useMemo } from "react";
// import { BarChart as Chart, Bar, XAxis, Legend, ResponsiveContainer, Tooltip, YAxis } from "recharts";
// import "styles/chart.css";

// export const BarChart = ({ data = [], settings }) => {
//     const { barName = "", dataKey, xKey } = settings;

//     const maxDomain = useMemo(() => Math.max(...data.map(item => item?.[dataKey])), [data, dataKey]);

//     const maximum = Math.max(...data.map(item => item?.[dataKey]));
//     const minimum = Math.min(...data.map(item => item?.[dataKey]));
//     return (
//         <ResponsiveContainer width={settings?.width ?? 570} height={settings?.height ?? 300}>
//             <Chart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
//                 <Tooltip itemStyle={{ fontSize: 12 }} labelStyle={{ fontSize: 12 }} />
//                 <XAxis dataKey={xKey} fontSize={10} {...(data.length <= 6 && { interval: 0 })} />
//                 <YAxis tickCount={20} domain={[0, maxDomain]} fontSize={12} />
//                 <Legend type="square" />
//                 <Bar dataKey={dataKey} fill="#87ad36" name={barName} />
//             </Chart>
//         </ResponsiveContainer>
//     );
// };
