import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import API from "api/Main";
import { BarChart } from "components/BarChart";
import { Select } from "components/Select";
import { ONE_DAY_EVENT_TIME } from "constants/Date";
import { useDates } from "hooks/useDates";
import { searchItem, sumArray } from "utils/Array";
import { dateToUnix, formatDate, getHour } from "utils/Date";
import { getNewOptions } from "utils/Select";
import { addThousands } from "utils/Value";
import { CapacityCards } from "./CapacityCards";
import { FilterModal } from "./FilterModal";
import { SegmentedCharts } from "./SegmentedCharts";
import { ALL_ACCESS, createCameraOptions } from "..";
import { LineChart } from "components/LineChart";
import { addOneDay, DEFAULT_CAPACITY_PEAKS, getSpikesFromCameras, DEFAULT_CAMERA_PEAKS } from ".";

export const ChartsModal = ({ cameras = [], event, toggleForm }) => {
    const [cameraPeaks, setCameraPeaks] = useState(DEFAULT_CAMERA_PEAKS);
    const [capacityPeaks, setCapacityPeaks] = useState(DEFAULT_CAPACITY_PEAKS);
    const [consultTraffic, setConsultTraffic] = useState(true);
    const [traffic, setTraffic] = useState([]);
    const [filters, setFilters] = useState({ cameras: [], date: {}, type: "" });
    const [showFilterModal, setShowFilterModal] = useState(false);

    const filterByDays = useMemo(() => getHour(event.end)?.minutes === ONE_DAY_EVENT_TIME, [event.end]);

    const { formattedDate } = useDates(filters.date);

    const eventCameras = useMemo(() => createCameraOptions(event.cameras), [event]);

    const formatIncomeData = data => Object.entries(data).map(([date, value]) => ({ date, value }));

    const getIncomeData = useCallback(() => {
        const total = { comeIn: {}, comeOut: {} };
        traffic.flat().forEach(item => {
            const date = item?.fecha ? formatDate(item.fecha) : item?.event_date;
            total.comeIn[date] = (total.comeIn[date] ?? 0) + item.come_in;
            total.comeOut[date] = (total.comeOut[date] ?? 0) + item.come_out;
        });
        return {
            comeIn: formatIncomeData(total.comeIn),
            comeOut: formatIncomeData(total.comeOut),
        };
    }, [traffic]);

    const { comeOut: incomeData } = useMemo(() => getIncomeData(), [getIncomeData]);

    const getVisitors = useCallback(() => sumArray(incomeData), [incomeData]);

    const totalVisitors = useMemo(() => getVisitors(), [getVisitors]);

    const getCapacityPeaks = useCallback(() => {
        if (cameras.length) {
            const values = incomeData.map(({ value }) => value);
            const [maximum, minimum] = [Math.max(...values), Math.min(...values)];
            return { maximum: searchItem(incomeData, { value: maximum }), minimum: searchItem(incomeData, { value: minimum }) };
        }
        return DEFAULT_CAPACITY_PEAKS;
    }, [cameras, incomeData]);

    const getTrafficUrl = useCallback(
        id => {
            if (filterByDays) {
                return `/analytics/resume-traffic/?groupId=${id}&start=${formattedDate.start}&end=${addOneDay(
                    formattedDate.end
                )}`;
            }
            const [startDate, endDate] = [dateToUnix(filters.date.start), dateToUnix(filters.date.end)];
            return `/analytics/ingress?start=${startDate}&end=${endDate}&groupId=${id}`;
        },
        [filters.date, filterByDays, formattedDate]
    );

    const getTraffic = useCallback(async () => {
        const cameras = filters.cameras;
        if (consultTraffic && cameras.length && formattedDate.start) {
            const traffic = await Promise.all(
                cameras.map(({ id }) => API.get("https://dot", getTrafficUrl(id)).then(res => res.json()))
            );
            const hasErrors = traffic.some(item => item?.errors);
            if (!hasErrors) setTraffic(traffic);
            setConsultTraffic(false);
        }
    }, [consultTraffic, getTrafficUrl, filters.cameras, formattedDate]);

    useEffect(() => {
        setFilters({ cameras: event.cameras, date: { start: new Date(event.start), end: new Date(event.end) } });
    }, [event]);

    useEffect(() => {
        getTraffic();
    }, [getTraffic]);

    useEffect(() => {
        setCapacityPeaks(getCapacityPeaks());
    }, [getCapacityPeaks]);

    useEffect(() => {
        setCameraPeaks(getSpikesFromCameras(traffic, cameras));
    }, [cameras, traffic]);

    const closeModal = () => toggleForm("");

    const enableTrafficQuery = () => {
        setConsultTraffic(true);
        setTraffic([]);
    };

    const handleChangingCameras = selectedOptions => {
        setFilters({
            ...filters,
            cameras: getNewOptions({ allOptions: eventCameras, selectedOptions, allKey: ALL_ACCESS }),
        });
        setConsultTraffic(true);
        setTraffic([]);
    };

    const handleDateChange = (date, name) => {
        const newDate = { ...filters.date, [name]: date };
        const [start, end] = [dateToUnix(newDate.start), dateToUnix(newDate.end)];
        const isStart = name === "start";
        if (start > end) return setFilters({ ...filters, date: { ...newDate, [isStart ? "end" : "start"]: newDate[name] } });
        setFilters({ ...filters, date: { ...filters.date, [name]: date } });
    };

    const hasSegmentedData = useMemo(() => traffic.flat().some(item => item.nMen), [traffic]);

    const toggleFilterModal = (resetDate = false) => {
        setShowFilterModal(!showFilterModal);
        if (resetDate) setFilters({ ...filters, date: { start: new Date(event.start), end: new Date(event.end) } });
    };

    return showFilterModal ? (
        <FilterModal
            enableTrafficQuery={enableTrafficQuery}
            event={event}
            filters={filters}
            handleDateChange={handleDateChange}
            toggleModal={toggleFilterModal}
            filterByDays={filterByDays}
        />
    ) : (
        <Modal animation={false} show className="charts-modal" size="xl" onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Resumen</Modal.Title>
            </Modal.Header>
            <Modal.Body className="charts-modal__content">
                <section className="charts-filters">
                    <Select
                        className="multiple-select"
                        options={eventCameras}
                        isMulti
                        handleChange={handleChangingCameras}
                        placeholder="Selecciona un acceso"
                        value={filters.cameras}
                    />
                    <Button className="filter-button" onClick={() => setShowFilterModal(true)}>
                        Filtrar
                    </Button>
                </section>
                {traffic.length ? (
                    <div className="charts-content">
                        <section className="summary-section">
                            <h2 className="charts-title">
                                ¿Cuántos visitantes han ingresado en&nbsp;
                                {filterByDays ? "los días seleccionados" : "las horas seleccionadas"}?
                            </h2>
                            <div className="summary-section__content flex items-center">
                                {filterByDays ? (
                                    <BarChart
                                        data={incomeData}
                                        settings={{ barName: "Ingresos", dataKey: "value", xKey: "date" }}
                                    />
                                ) : (
                                    <LineChart
                                        data={incomeData}
                                        settings={{ lineName: "Ingresos", lineKey: "value", xKey: "date" }}
                                    />
                                )}
                                <div className="cipher-text">
                                    <p className="cipher-label">Acceso total:</p>
                                    <p className="total-value">{addThousands(totalVisitors)}</p>
                                </div>
                            </div>
                            <CapacityCards cameraPeaks={cameraPeaks} capacityPeaks={capacityPeaks} filterByDays={filterByDays} />
                        </section>
                        {hasSegmentedData && (
                            <SegmentedCharts cameras={cameras} selectedCameras={filters.cameras} traffic={traffic} />
                        )}
                    </div>
                ) : (
                    <Spinner animation="border" role="status" style={{ color: "#87ad36", margin: "auto", display: "block" }} />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="save-button" onClick={closeModal}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
