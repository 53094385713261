//Import main libraries and components
import React from "react";
import { Container, Row, Col, Button, Dropdown, Alert } from "react-bootstrap";
import { Link } from 'react-router-dom';

//Import images
import logo from "../../imgs/logo-verde.png";
import iconUser from "../../imgs/user.png";
import iconPassword from "../../imgs/passw.png";
import iconEmail from "../../imgs/mail.png";
import iconPhoneNumber from "../../imgs/phoneNumber.png";
import iconCountry from "../../imgs/country.png";
import iconDepartment from "../../imgs/department.png";
import iconPostalCode from "../../imgs/postalCode.png";
import iconAddress from "../../imgs/address.png";
import API from "../../api/Main";

//Import styles
import "../../styles/Register/register.css";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      direction: {
        country: "Selecciona tu país",
        state: "",
        zip: "",
        street: "",
      },
      password: "",
      passwordConfirm: "",
      showAlertSuccess: false,
      token: this.props.location.search.split("&")[1].split("=")[1]
    }
  }

  handleLogoClick = () => {
    window.location = "https://app.switchai.co/";
  };

  handleChangeFirstName = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ firstName: e.target.value });
  }

  handleChangeLastName = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ lastName: e.target.value });
  }

  handleChangeEmail = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ email: e.target.value });
  }

  handleChangePhoneNumber = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ contact: e.target.value });
  }

  handleChangeCountry = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    let tempAddress = this.state.direction;
    tempAddress.country = e.target.innerText;
    this.setState({ direction: tempAddress });
  }

  handleChangeDepartment = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    let tempAddress = this.state.direction;
    tempAddress.state = e.target.value;
    this.setState({ direction: tempAddress });
  }

  handleChangePostalCode = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    let tempAddress = this.state.direction;
    tempAddress.zip = e.target.value;
    this.setState({ direction: tempAddress });
  }

  handleChangeAddress = (e) => {
    document.getElementById("errorToRegister").style.display = "none";
    let tempAddress = this.state.direction;
    tempAddress.street = e.target.value;
    this.setState({ direction: tempAddress });
  }

  handleChangePassword = (e) => {
    document.getElementById("errorPasswords").style.display = "none";
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ password: e.target.value });
  }

  handleChangePasswordConfirm = (e) => {
    document.getElementById("errorPasswords").style.display = "none";
    document.getElementById("errorToRegister").style.display = "none";
    this.setState({ passwordConfirm: e.target.value });
  }

  handleClickPage(){
    window.location.assign('https://app.switchai.co/');
  }

  handleButtonSubmit = (e) => {
    if (this.state.firstName !== "" && this.state.lastName !== "" && this.state.contact !== ""
      && this.state.email !== "" && this.state.direction.country !== "" && this.state.direction.state !== ""
      && this.state.direction.street !== "" && this.state.password !== "" && this.state.passwordConfirm !== "") {
      if (this.state.password === this.state.passwordConfirm) {

        API.postForm('https://dot', "/client/register/invitated", this.state.token,
          JSON.stringify({
            token: this.state.token,
            fields: {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              email: this.state.email,
              contact: this.state.contact,
              direction: {
                countryCode: this.state.direction.country,
                stateCode: this.state.direction.state,
                zip: this.state.direction.zip,
                street: this.state.direction.street
              },
              role: {
                _id: "a119caa8-a935-4550-9bfc-60c55a949a42"
              },
              password: this.state.password

            }
          })
        )
        this.setState({ showAlertSuccess: true })

      } else {
        document.getElementById("errorPasswords").style.display = "";
      }
    } else {
      document.getElementById("errorToRegister").style.display = "";
    }

  }

  componentDidMount() {
    document.getElementById("errorToRegister").style.display = "none";
    document.getElementById("errorPasswords").style.display = "none";
  }

  render() {
    return (
      <Container>
        <div>
          <Row>
            <Col lg={2}></Col>
            <Col lg={8}>
              <Container fluid className="Container-Register">
                <img
                  src={logo}
                  alt="Logo Switch Up"
                  width="56%"
                  className="Logo-Register"
                  onClick={this.handleLogoClick}
                />
                <br></br>
                <label className="Title-Form-Register">REGISTRO</label>
                <div>
                  <div style={{ textAlign: "left", padding: "1rem" }}>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconUser}
                            alt="Icono contraseña"
                            width="22rem"
                          />{" "}
                          Nombres*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="text"
                          name="nameUser"
                          onChange={this.handleChangeFirstName}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconUser}
                            alt="Icono contraseña"
                            width="22rem"
                          />{" "}
                          Apellidos*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="text"
                          name="lastnameUser"
                          onChange={this.handleChangeLastName}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconEmail}
                            alt="Icono contraseña"
                            width="20rem"
                          />{" "}
                          Correo electrónico*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="email"
                          name="email"
                          onChange={this.handleChangeEmail}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconPhoneNumber}
                            alt="Icono contraseña"
                            width="22rem"
                          />{" "}
                          Número de contacto*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="text"
                          name="phone"
                          onChange={this.handleChangePhoneNumber}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconCountry}
                            alt="Icono contraseña"
                            width="24rem"
                          />{" "}
                          País*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic" className="Dropdown-Countries">
                            {this.state.direction.country}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item className="Item-Countries" onClick={this.handleChangeCountry}>Colombia</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconDepartment}
                            alt="Icono contraseña"
                            width="22rem"
                          />{" "}
                          Departamento*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="text"
                          name="department"
                          onChange={this.handleChangeDepartment}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconPostalCode}
                            alt="Icono contraseña"
                            width="22rem"
                          />{" "}
                          Código postal
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="number"
                          name="postalCode"
                          onChange={this.handleChangePostalCode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconAddress}
                            alt="Icono contraseña"
                            width="24rem"
                          />{" "}
                          Dirección*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="text"
                          name="address"
                          onChange={this.handleChangeAddress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconPassword}
                            alt="Icono contraseña"
                            width="20rem"
                          />{" "}
                          Contraseña*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="password"
                          name="password"
                          onChange={this.handleChangePassword}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <label className="Text-Form-Register">
                          <img
                            src={iconPassword}
                            alt="Icono contraseña"
                            width="20rem"
                          />{" "}
                          Confirmar contraseña*
                        </label>
                      </Col>
                      <Col lg={7}>
                        <input
                          className="Input-Text"
                          type="password"
                          name="confirmPassword"
                          onChange={this.handleChangePasswordConfirm}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <label id="errorToRegister" className="Error">Por favor completa todos los campos</label>
                    <label id="errorPasswords" className="Error">Verifica que tus contraseñas coincidan</label>
                  </div>
                  <div>
                    <Alert show={this.state.showAlertSuccess} variant="success">
                      <Alert.Heading className="Title-Alert">
                        Registro Exitoso
                      </Alert.Heading>
                      <p className="Text-Alert">
                        Los datos se registraron correctamente. Puedes ingresar a <Link onClick={this.handleClickPage.bind(this)} >Switch</Link>
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => this.setState({ showAlertSuccess: false })}
                          variant="outline-success"
                          className="Title-Button-Alert"
                        >
                          Cerrar
                        </Button>
                      </div>
                    </Alert>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="success"
                      className="Register-Button"
                      onClick={this.handleButtonSubmit}
                    >
                      Registrar
                    </Button>
                  </div>
                </div>
              </Container>
            </Col>
            <Col lg={2}></Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Register;
