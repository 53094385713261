//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";
import { Row, Col, Button } from "react-bootstrap";
import API from "../../api/Main";

//Import images
import checkImage from "../../imgs/checked-checkbox.png";
import uncheckImage from "../../imgs/unchecked-checkbox.png";
import iconoTrash from "../../imgs/trash.png";
import iconoPencil from "../../imgs/pencil.png";
import imgEquis from "../../imgs/equis.png";

//Import styles
import "../../styles/ResourcesGroup/adminResourcesGroup.css";

var validator = require("validator");

class AdminResourcesGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            idRole: "",
            idResourcesGroup: "",
            listIdResources: [],
            listIdUser: [],
            nameGroup: "",
            nameStore: "",
            manager: 1,
            members: [],
            eliminar: [],
            resources: [],
            resourcesList: [],
            loadingMembers: false,
            loadingResources: false,
            loadingAdd: false,
            todelete: "",
            toEdit: "",
            idRoles: ["a119caa8-a935-4550-9bfc-60c55a949a42", "161a16bd-1d84-4457-ae46-ec92d928d457"],
            Role: "",
            data: { info_to_send: [], last_send: "" },
            listUsersBDToShow: [],
        }
    }

    changeCheckGuest = () => {
        this.verifyUser();
    }

    changeCheckManager = () => {
        this.verifyUser();
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    handleChangeName = (e) => {
        this.setState({ nameGroup: e.target.value });
    }

    handleChangeStore = (e) => {
        this.setState({ nameStore: e.target.value });
    }

    changeResourcesGroup = (e) => {
        this.reviewStyles();
        document.getElementById(e.target.id).style.backgroundColor = "#84ac3480";
        this.setState({ idResourcesGroup: e.target.name });
    }

    EditStore = (e) =>{
        console.log("editar: ", e);
        this.setState({ toEdit: e});
        document.getElementById("popUpEditStore").style.display = "";
        document.getElementById("bodyAdminResources").style.filter = "blur(5px)";
    }

    DeleteStore = (e) => {
        this.setState({ todelete: e});
        document.getElementById("popUpDeleteStore").style.display = "";
        document.getElementById("bodyAdminResources").style.filter = "blur(5px)";
    };

    DeleteUser = (e) => {
        console.log("es", e);
        this.setState({ todelete: e});
        document.getElementById("popUpDeleteUser").style.display = "";
        document.getElementById("bodyAdminResources").style.filter = "blur(5px)";
    };

    handleClosePopUpEditStore = () => {
        document.getElementById("popUpEditStore").style.display = "none";
        document.getElementById("bodyAdminResources").style.filter = "none";
    }

    handleClosePopUpStore = () => {
        document.getElementById("popUpDeleteStore").style.display = "none";
        document.getElementById("bodyAdminResources").style.filter = "none";
    }

    handleClosePopUpUser = () => {
        document.getElementById("popUpDeleteUser").style.display = "none";
        document.getElementById("bodyAdminResources").style.filter = "none";
    }

    verifyUser() {
        if (this.state.manager === 1) {
            document.getElementById("uncheckManager").style.display = "none";
            document.getElementById("checkManager").style.display = "";
            document.getElementById("uncheckGuest").style.display = "";
            document.getElementById("checkGuest").style.display = "none";
            let id = this.state.idRoles[0];
            this.setState({ manager: 0, idRole: id });
        } else if (this.state.manager === 0) {
            document.getElementById("uncheckManager").style.display = "";
            document.getElementById("checkManager").style.display = "none";
            document.getElementById("uncheckGuest").style.display = "none";
            document.getElementById("checkGuest").style.display = "";
            let id = this.state.idRoles[1];
            this.setState({ manager: 1, idRole: id });
        }
    }

    reviewStyles() {
        for (var i = 0; i < this.state.listIdResources.length; i++) {
            document.getElementById(this.state.listIdResources[i]).style.backgroundColor = "#f8f9fa";
        }
    }

    loadInfo() {
        let members = [];
        let resourcesGroup = [];
        let listResourcesGroup = [];
        let listIdResources = [];
        let listIdUser = [];

        this.setState({ loadingMembers: true, loadingResources: true });

        API.get("https://dot", "/resource_group/users")
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        data.response.forEach((element) => {
                            let member;
                            if(this.state.Role === 0){
                                member = (
                                    <div key={element.email} className="Card-Members">
                                        <Col lg={10} style={{display: 'inline-block'}}>
                                        <label className="Text-Member">{element.first_name}&nbsp;{element.last_name}</label><br></br>
                                        <label className="Text-Member">{element.email}</label>
                                        </Col>
                                        
                                        <Col lg={2} style={{display: 'inline-block'}}>
                                            <img
                                            id={element.email}
                                            src={iconoTrash}
                                            alt="Icono trash"
                                            width="30rem"
                                            onClick={()=>{
                                                this.DeleteUser(element.email);
                                            }}
                                            className="Img-Trash"
                                            ></img>
                                        </Col>                                        
                                    </div>
                                    
                                );
                            }else if (this.state.Role === 1){
                                member = (
                                    <div key={element.email} className="Card-Members">
                                        <Col lg={10} style={{display: 'inline-block'}}>
                                        <label className="Text-Member">{element.first_name}&nbsp;{element.last_name}</label><br></br>
                                        <label className="Text-Member">{element.email}</label>
                                        </Col>                                        
                                    </div>
                                    
                                );
                            }
                            listIdUser.push(element);
                            members.push(member);
                        });
                        this.setState({ members: members, listIdUser: listIdUser,loadingMembers: false });
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch((error) => console.log(error));

        API.get("https://dot", "/auth/role").then((response) => {
            if (response.ok) {
                response.json().then((role) => {
                    if (role.response === "admin") {
                        document.getElementById("createResourcesGroup").style.display = "none";
                        this.setState({ Role: 1});
                        console.log("role 1: No admin");
                    } else {
                        document.getElementById("createResourcesGroup").style.display = "";
                        this.setState({ Role: 0});
                        console.log("role 0: admin");
                    }
                });
            } else {
                console.log("error");
            }
        }).catch((error) => console.log(error));

        API.get("https://dot", "/resource_group").then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    data.forEach((element) => {
                        let group = (
                            <div key={element.id} className="Card-Members">
                                    <Col lg={12} style={{display: 'inline-block'}}>
                                        <label className="Text-Member">{element.name}</label><br></br>
                                        <label className="Text-Member">{element.description}</label>
                                    </Col>
                                        
                                    {/* <Col lg={1} style={{display: 'inline-block'}}>
                                        <img
                                            id={element.id}
                                            src={iconoPencil}
                                            alt="Icono pencil"
                                            width="30rem"
                                            onClick={()=>{
                                                this.EditStore(element.name);
                                            }}
                                            className="Img-Trash"
                                        ></img>
                                    </Col>  

                                    <Col lg={1} style={{display: 'inline-block'}}>
                                        <img
                                            id={element.id}
                                            src={iconoTrash}
                                            alt="Icono trash"
                                            width="30rem"
                                            onClick={()=>{
                                                this.DeleteStore(element.name);
                                            }}
                                            className="Img-Trash"
                                        ></img>
                                    </Col>    */}
                            </div>
                        );
                        let group_v2 = (
                            <Button variant="light" key={element.id} name={element.id} className="Button-List" onClick={this.changeResourcesGroup} id={element.id}>{element.name}</Button>
                        );
                        resourcesGroup.push(group);
                        listResourcesGroup.push(group_v2);
                        listIdResources.push(element.id);
                    });
                    this.setState({ resources: resourcesGroup, loadingResources: false, resourcesList: listResourcesGroup, listIdResources: listIdResources });
                });
            } else {
                console.log("error");
            }
        }).catch((error) => console.log(error));
    }

    handleButtonDeleteUser = () => {
        let correo =  this.state.todelete;
        let body = {
          email: correo,
        };
        console.log(body);
        API.post("https://dot", "/client/delete/guest", JSON.stringify(body))
          .then((response) => {
            if (response.ok) {
                this.loadInfo();
                document.getElementById("popUpDeleteUser").style.display = "none";
                document.getElementById("bodyAdminResources").style.filter = "";
              
            } else {
              response.json().then((data) => {
                console.log(data);
              });
            }
          })
          .catch((error) => console.log("error", error));
    };

    sendInvitation = () => {
        this.setState({ loadingAdd: true });
        if (validator.isEmail(this.state.email)) {  
            let body = JSON.stringify({
                "fields": {
                    "group": this.state.idResourcesGroup,
                    "role": this.state.idRole,
                    "emails": [
                        this.state.email,
                    ]
                }
            });
            API.post("https://dot", "/client/utils/send/invitation", body).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        document.getElementById("success-text").style.display= "";
                        this.setState({ loadingAdd: false, idRole: "", email: "", idResourcesGroup: "" });
                        this.reviewStyles();
                    });
                } else {
                    this.setState({ loadingAdd: false });
                    document.getElementById("error-text").style.display= "";
                    console.log("error");
                }
            }).catch((error) => console.log(error));
        }
    }

    createGroup = () => {
        this.setState({ loadingResources: true });
        if (this.state.nameGroup) {
            let body = JSON.stringify({
                "name": this.state.nameGroup,
                "description": " "
            });
            API.post("https://dot", "/resource_group/create", body).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        this.setState({ loadingResources: false, nameGroup: "" });
                        window.location = "/admin/admin-resources-group";
                    });
                } else {
                    console.log("error");
                    this.setState({ loadingResources: false });
                }
            }).catch((error) => console.log(error));
        }
    }

    componentDidMount() {
        document.title = "Switch AI | Admin Grupo de Recursos";
        this.styleToActive();
        this.verifyUser();
        this.loadInfo();
        document.getElementById("success-text").style.display= "none";
        document.getElementById("error-text").style.display= "none";
        document.getElementById("popUpDeleteUser").style.display = "none";
        document.getElementById("popUpDeleteStore").style.display = "none";
        document.getElementById("popUpEditStore").style.display = "none";
        document.getElementById("bodyAdminResources").style.filter = "none";
    }

    styleToActive() {
       /*  document.getElementById("activeAdmin").style.color = "rgb(70, 67, 67)";
        document.getElementById("activeAdmin").style.borderStyle = "dashed";
        document.getElementById("activeAdmin").style.borderColor = "#84ac34";
        document.getElementById("activeAdmin").style.borderRadius = "1rem";
        document.getElementById("activeConfigBio").style.color = "";
        document.getElementById("activeConfigBio").style.borderStyle = "none";
        document.getElementById("activeDashBio").style.color = "";
        document.getElementById("activeDashBio").style.borderStyle = "none";
        document.getElementById("activeCamsBio").style.color = "";
        document.getElementById("activeCamsBio").style.borderStyle = "none";
        document.getElementById("item-dashboard-manager").style.color = "";
        document.getElementById("item-dashboard-manager").style.borderStyle = "none";
        document.getElementById("activeDashSym").style.color = "";
        document.getElementById("activeDashSym").style.borderStyle = "none";
        document.getElementById("activeAnsSym").style.color = "";
        document.getElementById("activeAnsSym").style.borderStyle = "none";
        document.getElementById("activeConfigSym").style.color = "";
        document.getElementById("activeConfigSym").style.borderStyle = "none"; */
    }

    closeSideBar(){
        document.getElementById("lateralMenu").style.display = "none";
        document.getElementById("genericBody").style.filter = "none";
        document.getElementById("sub-menu").style.display = "none";
        document.getElementById("menuIcon2").style.display = "none";
        document.getElementById("menuIcon1").style.display = "";
        document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
    }

    render() {
        let borrar = [];
        return (
            <div>
                <NavMenuBar></NavMenuBar>
                <div id="genericBody" onClick={this.closeSideBar}>
                    <div id="popUpDeleteUser" className="Pop-Up-Delete">
                        <div className="Div-Right">
                            <img
                                src={imgEquis}
                                alt="Icono close"
                                width="50rem"
                                className="Equis"
                                onClick={this.handleClosePopUpUser}
                            ></img>
                        </div>
                        <div className="Div-Center">
                            <label className="Title-Pop-Up-Delete">
                                ¿Estás seguro que deseas eliminar esta persona?
                            </label>
                            <br></br>
                            <Button
                                variant="danger"
                                className="Delete-Button"
                                onClick={this.handleButtonDeleteUser}
                            >
                                Eliminar
                            </Button>
                        </div>
                    </div>
                    <div id="popUpDeleteStore" className="Pop-Up-Delete">
                        <div className="Div-Right">
                            <img
                                src={imgEquis}
                                alt="Icono close"
                                width="50rem"
                                className="Equis"
                                onClick={this.handleClosePopUpStore}
                            ></img>
                        </div>
                        <div className="Div-Center">
                            <label className="Title-Pop-Up-Delete">
                                ¿Estás seguro que deseas eliminar esta tienda?
                            </label>
                            <br></br>
                            <Button
                                variant="danger"
                                className="Delete-Button"
                                onClick={this.handleButtonDeleteStore}
                            >
                                Eliminar
                            </Button>
                        </div>
                    </div>
                    <div id="popUpEditStore" className="Pop-Up-Delete">
                        <div className="Div-Right">
                            <img
                                src={imgEquis}
                                alt="Icono close"
                                width="50rem"
                                className="Equis"
                                onClick={this.handleClosePopUpEditStore}
                            ></img>
                        </div>
                        <div className="Div-Center">
                            <label className="Title-Pop-Up-Edit">
                                Ingresa el nuevo nombre de la tienda: {this.state.toEdit}
                            </label>
                            <br></br>
                            <input 
                                placeholder="Nombre de la tienda" className="Input-Value" onChange={this.handleChangeStore} value={this.state.nameStore}
                            >                                
                            </input>
                            <br></br>
                            <Button
                                variant="success"
                                className="Edit-Button"
                                onClick={this.handleButtonEditStore}
                            >
                                Editar
                            </Button>
                        </div>
                    </div>
                    <div className="Body-Admin-Resources-Group" id="bodyAdminResources">
                        <Row>
                            <Col lg={12}>
                                <div className="Div-Center-Title">
                                    <label className="Title-Window">
                                        GESTIÓN DE ZONAS
                                    </label>
                                </div>
                                <Row>
                                    <Col lg={6}>
                                        <div className="Container-Box-Create">
                                            <div className="Loader-Members">
                                                {this.state.loadingMembers && (
                                                    <div className="loader"></div>
                                                )}
                                            </div>
                                            <div className="Contner-Center">
                                                <label className="Title-Resources-Card">
                                                    Miembros de tu equipo
                                                </label>
                                            </div>
                                            <br></br>
                                            {this.state.members}                                             
                                        </div>
                                        <div className="Container-Box-Create">
                                            <div className="Loader-Resources">
                                                {this.state.loadingResources && (
                                                    <div className="loader"></div>
                                                )}
                                            </div>
                                            <div className="Container-Center">
                                                <label className="Title-Resources-Card">
                                                    Zonas
                                                </label>
                                            </div>
                                            <br></br>
                                            <div id="createResourcesGroup">
                                                <input
                                                    placeholder="Nombre del grupo de recursos" className="Input-Value" onChange={this.handleChangeName} value={this.state.nameGroup}
                                                ></input>
                                                <Button variant="success" className="Button-Save" onClick={this.createGroup}>Crear</Button>
                                            </div>
                                            {this.state.resources}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="Container-Box-Resources">
                                            <div className="Loader-Add">
                                                {this.state.loadingAdd && (
                                                    <div className="loader"></div>
                                                )}
                                            </div>
                                            <div className="Container-Center">
                                                <label className="Title-Resources-Card">
                                                    Añadir miembros
                                            </label>
                                            </div>
                                            <label className="Text-Black-Card">Invita personas a tu equipo</label>
                                            <br></br>
                                            <input
                                                placeholder="Correo electrónico" className="Input-Value" onChange={this.handleChangeEmail} value={this.state.email}
                                            ></input>
                                            <hr></hr>
                                            <label className="Text-Black-Card">Escoge un rol</label>
                                            <br></br>
                                            <img
                                                className="Check"
                                                id="uncheckManager"
                                                src={uncheckImage}
                                                alt="Checkbox"
                                                width="30rem"
                                                onClick={this.changeCheckManager}
                                            ></img>
                                            <img
                                                className="Check"
                                                id="checkManager"
                                                src={checkImage}
                                                alt="Checkbox"
                                                width="30rem"
                                            ></img><label > Usuario manager</label>
                                            <br></br>
                                            <label className="Text-Card">Recomendado para usuarios que administran y configuran un grupo de recursos</label>
                                            <br></br>
                                            <img
                                                className="Check"
                                                id="uncheckGuest"
                                                src={uncheckImage}
                                                alt="Checkbox"
                                                width="30rem"
                                                onClick={this.changeCheckGuest}
                                            ></img>
                                            <img
                                                className="Check"
                                                id="checkGuest"
                                                src={checkImage}
                                                alt="Checkbox"
                                                width="30rem"
                                            ></img><label> Usuario invitado</label>
                                            <br></br>
                                            <label className="Text-Card">Recomendado para usuarios que no tienen permisos de configuración</label>
                                            <br></br>
                                            <hr></hr>
                                            <label className="Text-Black-Card">Selecciona un grupo de recursos</label>
                                            <br></br>
                                            <div>
                                                {this.state.resourcesList}
                                            </div>
                                            <div className="Container-Center">
                                                <Button variant="success" className="Button-Save" onClick={this.sendInvitation}>Añadir</Button>
                                            </div>
                                            <p id="success-text" style={{fontSize:"17px", color:"green", fontWeight: "bold", textAlign: "center"}}>Se ha enviado un correo de invitación al email que ingresaste</p>
                                            <p id="error-text" style={{fontSize:"17px", color:"red", fontWeight: "bold", textAlign: "center"}}>Ha ocurrido un error, por favor, ingrese nuevamente los datos</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    };

}

export default AdminResourcesGroup;