import { COLORS } from "constants/Colors";
import { sumArray } from "utils/Array";
import { setTimeToDate } from "utils/Date";
import { addThousands } from "utils/Value";

export * from "./Calendar";
export * from "./ChartsModal";
export * from "./CapacityCards";
export * from "./EventModal";
export * from "./FilterModal";
export * from "./SegmentedCharts";

export const calendarProps = {
    initialView: "dayGridMonth",
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listYear",
    },
    locale: "es",
    longPressDelay: 1000,
    eventLongPressDelay: 1000,
    selectLongPressDelay: 1000,
    selectable: true,
    editable: true,
    allDaySlot: false,
    buttonText: {
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
        list: "Lista",
    },
    views: {
        dayGridMonth: {
            buttonText: "Mes",
            eventDisplay: "list-item",
        },
        timeGridWeek: {
            buttonText: "Semana",
        },
        timeGridDay: {
            buttonText: "Día",
        },
        listYear: {
            buttonText: "Año",
            listYearFormat: "YYYY",
        },
    },
};

export const DURATION_OPTIONS = [
    {
        id: 100,
        value: "Días específicos",
        label: "Días específicos",
        type: "days",
    },
    {
        id: 101,
        value: "Horas específicas",
        label: "Horas específicas",
        type: "hours",
    },
];

export const MAIN_ENTRANCES = [
    {
        ids: ["a9211a1f-1b58-415a-9354-edf514315d52"],
        location: "Nivel 1 - Puerta 3",
    },
    {
        ids: ["977c040f-25ae-40b7-9374-4f53570261ce", "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651"],
        location: "Nivel 2 - Puerta 1",
    },
    {
        ids: ["8b5890e6-7760-4ea4-ab13-ffdedf212d0d", "f702432e-e713-437e-ad31-87daf772163e"],
        location: "Nivel 2 - Puerta 2",
    },
    {
        ids: ["b7f160d1-cc0c-4f42-81e8-9f4999e1d13e", "42ab832f-289a-418b-9986-a4d22a607243"],
        location: "Nivel 3 - Puerta 4",
    },
];

export const DEFAULT_EVENT = {
    activityDescription: "",
    cameras: [],
    client_id: "9973e971-3b17-43e7-bd5f-5e64a658b3c3",
    generalDescription: "",
    name: "",
    duration: DURATION_OPTIONS[0],
};

export const SEGMENTED_TRAFFIC_KEYS = [
    {
        key: "men",
        dataKey: "nMen",
    },
    {
        key: "women",
        dataKey: "nWomen",
    },
    {
        key: "pets",
        dataKey: "nPets",
    },
];

export const MODALS = {
    FORM: "FORM",
    CHARTS: "CHARTS",
};

export const DEFAULT_CAPACITY_PEAKS = {
    maximum: { date: "", value: "" },
    minimum: { date: "", value: "" },
};

export const formatEvents = (events = []) =>
    events.map(({ name: title, subscription_group_ids: cameras, startDate, endDate, ...item }, index) => ({
        ...item,
        cameras: cameras ?? [],
        end: new Date(endDate.slice(0, -1)),
        start: new Date(startDate.slice(0, -1)),
        title,
        color: COLORS[index % 2],
    }));

export const SWAL_PROPS = {
    position: "center",
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
};

export const countSegmentedData = (selectedCameras, segmentedTraffic) => {
    const total = { men: 0, women: 0, pets: 0 };

    selectedCameras.forEach(({ id }) => {
        const data = segmentedTraffic[id];
        if (data) SEGMENTED_TRAFFIC_KEYS.forEach(({ key }) => (total[key] = total[key] + data[key]));
    });

    return {
        people: [
            { name: "Hombres", total: total.men },
            { name: "Mujeres", total: total.women },
        ],
        pets: [{ name: "Mascotas", total: total.pets }],
    };
};

export const formatDateBeforeReturningString = date => {
    const newDate = new Date(date);
    return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toJSON();
};

export const DEFAULT_CAMERA_PEAKS = {
    maximum: {
        name: "",
        value: 0,
    },
    minimum: {
        name: "",
        value: 0,
    },
};

const getCameraIndex = (totals, value) => totals.findIndex(item => item === value);

export const getSpikesFromCameras = (traffic, cameras) => {
    if (cameras.length && traffic.length) {
        const totals = traffic.flatMap(item => sumArray(item, "come_out") || []);
        const [maximum, minimum] = [Math.max(...totals), Math.min(...totals)];
        const [maximumIndex, minimumIndex] = [getCameraIndex(totals, maximum), getCameraIndex(totals, minimum)];
        return {
            maximum: { name: cameras[maximumIndex]?.name, value: addThousands(maximum) },
            minimum: { name: cameras[minimumIndex]?.name, value: addThousands(minimum) },
        };
    }
    return DEFAULT_CAMERA_PEAKS;
};

export const getEventDates = event => {
    if (event.duration.type === "days") {
        return {
            endDate: formatDateBeforeReturningString(setTimeToDate(event.end, true)),
            startDate: formatDateBeforeReturningString(setTimeToDate(event.start, true)),
        };
    }
    return { endDate: formatDateBeforeReturningString(event.end), startDate: formatDateBeforeReturningString(event.start) };
};

export const CARD_TITLES = {
    days: {
        maximum: "Día más visitado",
        minimum: "Día menos visitado",
    },
    hours: {
        maximum: "Hora más visitada",
        minimum: "Hora menos visitada",
    },
};

// Temporary function to solve bug of the endpoint that brings the data
export const addOneDay = date => {
    const newDate = date.split("-");
    return `${newDate.slice(0, 2).join("-")}-${Number(newDate[2]) + 1}`;
};