import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import API from "api/Main";
import { Select } from "components/Select";
import { ALL_ACCESS } from "pages/Schedule";
import { getNewOptions } from "utils/Select";
import { getDailyTrafficUrl, getPeakVisits, getValuePerPeriod } from "utils/Traffic";
import { ANNUAL_OPTIONS, MonthlySummary, MONTHS } from ".";

export const MonthlyHistory = ({ cameraOptions }) => {
    const [filters, setFilters] = useState({ cameras: [], year: ANNUAL_OPTIONS[ANNUAL_OPTIONS.length - 1] });
    const [trafficDates, setTrafficDates] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [monthlyData, setMonthlyData] = useState({});
    const [peakVisits, setPeakVisits] = useState({});
    const { cameras, year } = filters;

    useEffect(() => {
        setFilters({ ...filters, cameras: cameraOptions.slice(1, cameraOptions.length) });
    }, [cameraOptions]);

    useEffect(() => {
        updateTrafficDates();
    }, [year]);

    useEffect(() => {
        getTraffic();
    }, [trafficDates, cameras]);

    useEffect(() => {
        setPeakVisits(getPeakVisits(monthlyData));
    }, [monthlyData]);

    const updateTrafficDates = () => setTrafficDates({ start: `${year.value}-1-1`, end: `${year.value}-12-31` });

    const getTraffic = async () => {
        if (cameras.length && trafficDates.start) {
            setShowLoader(true);
            const traffic = await Promise.all(
                cameras.map(({ id }) => API.get("https://dot", getDailyTrafficUrl(id, trafficDates)).then(res => res.json()))
            );
            const hasErrors = traffic.some(item => item?.errors);
            if (!hasErrors) setMonthlyData(getValuePerPeriod(traffic));
            setShowLoader(false);
        }
    };

    const getTotalByMonths = data => {
        const total = {};
        data.flat().map(item => {
            const month = MONTHS[item.fecha.split("-")[1]];
            total[month] = (total[month] ?? 0) + item.come_out;
        });
        return total;
    };

    const handleChangingCameras = selectedOptions => {
        setFilters({
            ...filters,
            cameras: getNewOptions({ allOptions: cameraOptions, selectedOptions, allKey: ALL_ACCESS }),
        });
    };

    return (
        <div className="monthly-history">
            <div className="historical__filters">
                <Select
                    className="historical__select--long"
                    options={cameraOptions}
                    isMulti
                    handleChange={handleChangingCameras}
                    placeholder="Accesos"
                    value={cameras}
                    wrapperClassName="historical__select--wrapper"
                />
                <Select
                    className="historical__select--short"
                    options={ANNUAL_OPTIONS}
                    handleChange={option => setFilters({ ...filters, year: option })}
                    placeholder="Año"
                    value={year}
                />
            </div>
            {showLoader ? (
                <Spinner animation="border" role="status" style={{ color: "#87ad36", margin: "auto", display: "block" }} />
            ) : (
                <div className="monthly-history__values">
                    {Object.entries(monthlyData).map((item, key) => (
                        <MonthlySummary key={key} item={item} peakVisits={peakVisits} />
                    ))}
                </div>
            )}
        </div>
    );
};
