import React from "react";
import { PieChart as Chart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { COLORS, GRAYS } from "constants/Colors";
import { RADIAN } from "constants/Chart";
import { addThousands } from "utils/Value";
import "styles/chart.css";

const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fontSize={14}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export const PieChart = ({ data = [], settings, className }) => {
    const maximum = Math.max(...data.map(item => item.total));
    const minimum = Math.min(...data.map(item => item.total));

    const getColor = (total, index) => {
        return total === maximum ? "#87ad36" : total === minimum ? "#c0223f" : GRAYS[index];
    };

    const newData = [...data].sort((a, b) => b.total - a.total);

    return (
        <ResponsiveContainer className={className} width={settings?.width ?? 200} height={settings?.height ?? 250}>
            <Chart>
                <Legend
                    iconType="circle"
                    {...settings?.legend}
                    payload={newData.map(({ total, name }, index) => ({
                        id: name,
                        value: (
                            <span>
                                {name}: <span className="bold">{addThousands(total)}</span>
                            </span>
                        ),
                        color: getColor(total, index),
                    }))}
                />
                <Tooltip formatter={value => addThousands(value)} itemStyle={{ fontSize: 12 }} />
                <Pie
                    data={newData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={customLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="total"
                    className="pie-chart"
                >
                    {newData.map(({ total }, index) => (
                        <Cell className="pie-chart-cell" key={`cell-${index}`} fill={getColor(total, index)} />
                    ))}
                </Pie>
            </Chart>
        </ResponsiveContainer>
    );
};
