//Import main libraries and components
import React from "react";

//Import images
import error from "../../imgs/error.png";

//Import styles
import "../../styles/Shared/notfound.css";

function NotFound() {
  return (
    <div className="Div-Error">
      <img src={error} alt="Error" width="250rem"></img>
      <br></br>
      <label className="Text-Error-404">404 Tenemos problemas en el universo de los unicornios</label>
    </div>
  );
}

export default NotFound;
