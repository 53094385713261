import React from "react";
import "./RadioButtonList.scss";

export const RadioButtonList = ({ disabled, options, selectedOptions, selectOption, name }) => {
    return (
        <div className="radio-list">
            {options.map(option => {
                const isActive = selectedOptions?.[name] === option && !disabled;
                return (
                    <div
                        className={`radio-list__option ${disabled ? "disabled" : ""}`}
                        key={option}
                        onClick={() => !disabled && selectOption(option, name)}
                    >
                        <button
                            className={`radio-list__button radio-list__button--${isActive ? "active" : ""} ${
                                disabled ? "disabled" : ""
                            }`}
                        />
                        <p>{option}</p>
                    </div>
                );
            })}
        </div>
    );
};
