import React from "react";
import { addThousands } from "utils/Value";

export const MonthlySummary = ({ item, peakVisits }) => {
    const [month, value] = item;

    const getValueModifier = () => {
        if (value === peakVisits?.maximum) return "monthly-summary__value--maximum";
        if (value === peakVisits?.minimum) return "monthly-summary__value--minimum";
    };
    return (
        <div className="monthly-summary">
            <p className={`monthly-summary__value ${getValueModifier()}`}>{addThousands(value)}</p>
            <p className="monthly-summary__month">Visitantes en {month}</p>
        </div>
    );
};
