import moment from "moment";
import { ONE_DAY_EVENT_TIME } from "constants/Date";

export const dateToUnix = date => Math.floor(new Date(date).getTime());

export const formatDate = (date = new Date()) => moment(date).utc().format("YYYY-MM-DD");

export const getUnix = () => new Date().getTime();

export const convertEndDate = (date, value = -1) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + value);
    return newDate;
};

export const getHour = date => {
    const [hour, minutes] = [new Date(date).getHours(), new Date(date).getMinutes()];
    return {
        hour,
        minutes,
    };
};

export const setTimeToDate = (date, changeHour) => {
    const { hour, minutes } = getHour(date);
    let newDate = new Date(date).setHours(changeHour ? ONE_DAY_EVENT_TIME : hour);
    newDate = new Date(newDate).setMinutes(changeHour ? ONE_DAY_EVENT_TIME : minutes);
    return new Date(newDate);
};

export const addDays = (date, days = 1) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const handleDateChanges = (date, { name, dates }) => {
    const newDates = { ...dates, [name]: date };
    const [start, end] = [dateToUnix(newDates.start), dateToUnix(newDates.end)];
    const isStart = name === "start";
    if (start > end) return { ...newDates, [isStart ? "end" : "start"]: newDates[name] };
    return { ...newDates, [name]: date };
};

export const getHoursDifference = (startData, endDate) => {
    const minutes = moment(endDate).diff(moment(startData), "minutes");
    return minutes / 60;
};
