import React from "react";
import { addThousands } from "utils/Value";
import { CARD_TITLES } from ".";

export const CapacityCards = ({ cameraPeaks, capacityPeaks, filterByDays }) => {
    const title = CARD_TITLES[filterByDays ? "days" : "hours"];

    return (
        <div className="capacity-cards">
            <div className="flex-1 items-center flex flex-col gap-4">
                <div className="capacity-card">
                    <p className="capacity-date">
                        {title.maximum}: {capacityPeaks.maximum?.date}
                    </p>
                    <p className="capacity-value"> Total: {addThousands(capacityPeaks.maximum?.value)}</p>
                </div>
                <div className="capacity-card">
                    <p className="capacity-date">
                        {title.minimum}: {capacityPeaks.minimum?.date}
                    </p>
                    <p className="capacity-value"> Total: {addThousands(capacityPeaks.minimum?.value)}</p>
                </div>
            </div>
            <div className="flex-1 items-center flex flex-col gap-4">
                <div className="capacity-card">
                    <p className="capacity-date">Acceso más visitado</p>
                    <p className="capacity-value">
                        {cameraPeaks.maximum.name ?? ""} - {cameraPeaks.maximum.value}
                    </p>
                </div>
                <div className="capacity-card">
                    <p className="capacity-date">Acceso menos visitado</p>
                    <p className="capacity-value">
                        {cameraPeaks.minimum.name ?? ""} - {cameraPeaks.minimum.value}
                    </p>
                </div>
            </div>
        </div>
    );
};
