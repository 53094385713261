import React from "react";
import { v4 as uuid } from "uuid";
import { Form } from "react-bootstrap";
import "./Switch.scss";

export const Switch = ({ checked, handleChange, disabled }) => {
    return (
        <Form>
            <Form.Check type="switch" label="" id={uuid()} checked={checked} onChange={handleChange} />
        </Form>
    );
};
