//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import LateralMenu from "../../components/LateralMenu";
import { Row, Col, Card, Button, Dropdown, Nav, Table } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'

//Import images
import iconHand from "../../imgs/manita.png";
import working from "../../imgs/Recurso10.png";
import chat from "../../imgs/icon-chat.png";
import imagenPrueba from "../../imgs/No-funcionalidd.png";
import imagenPrueba2 from "../../imgs/graficaNoDatos.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";
import "react-datepicker/dist/react-datepicker.css";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

class DashboardMarketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchChat: 0,
      graphic: [],
      lastEventsPartOne: [],
      lastEventsPartTwo: [],
      pagination: 1,
      cameras: [],
      cameras2: [],
      groups: [],
      lineGraphic: {},
      lineGraphic_2: {},
      barGraphic: {},
      barGraphic_2: {},
      pieGraphic: {},
      loading: true,
      loadingEvents: true,
      loadingBargraphic: false,
      loadingPieGraphic: false,
      loadingLineGraphic: false,
      loadingBarZones: false,
      tempId: "",
      tempId2: "",
      tempName: "",
      tempName2: "",
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 3600000),
      aforo: 0,
      aforoZonas: 0,
      zona: "",
      store: "",
      data: [],
      /*
      columns: [{ title: "Tienda", field: "name", align: "center"},
      { title: "Entrada (personas)", field: "in", align: "center" },
      { title: "Salida (personas)", field: "out", align: "center" },
      { title: "Hombres (personas)", field: "men", align: "center" },
      { title: "Mujeres (personas)", field: "women", align: "center" },
      { title: "Niños (personas)", field: "boys", align: "center" },
      { title: "Niñas (personas)", field: "girls", align: "center" },
      ],*/
      columns: [{ title: "Tienda", field: "name", align: "center"},
      { title: "Entrada (personas)", field: "in", align: "center" },
      { title: "Salida (personas)", field: "out", align: "center" },
      ],

    };
  }

  handleClickLoad = () => {
   
    this.getListCities();
  };

  handleClickCities = (e) => {
    
    this.setState({
      loading: true,
      loadingEvents: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    this.getListCities();
    

  };



  getListGroups() {
    this.state.groups = []
    API.get("https://dot", "/resource_group_specific/?model=ML-D7")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickGroup}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  Grupo {element.name}
                </Dropdown.Item>
              );
              this.state.groups.push(item);
            });
            
            this.setState({
              loading: false,
            });

            if (data.length != 0){
              localStorage.setItem("idGroup", this.state.groups[0].key);
              localStorage.setItem("nameGroup", this.state.groups[0].props.name);
              
              

              this.getListCameras();
              this.updateDashboard(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                false,
                new Date().getTime(),
                new Date().getTime() - (7 * 24 * 60 * 60 * 1000)
              );
            }
            
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  handleClickCamera = (e) => {
    this.setState({
      loading: true,
      loadingEvents: true,
      tempId2: e.target.id,
      tempName2: e.target.name,
    });
    
    this.updateDashboard2(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
  };

  handleClickGroup = (e) => {
    
    localStorage.setItem("idGroup", e.target.id);
    localStorage.setItem("nameGroup", e.target.name);
    this.getListCameras();
    
    
  };

  

  updateDashboard(id, name, isUpdate, startDate, finishDate) {
    this.hideObjects();
    this.showGraphics(isUpdate, startDate, finishDate);
  }

  updateDashboard2(id, name, isUpdate, startDate, finishDate) {
    this.hideObjects();
    
    this.showGraphicsZones(id, name, isUpdate, startDate, finishDate);
  }

  getListCities(pagination) {
    this.state.cameras = []
    API.get("https://dot", "/cameras/filters/cities")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            for (var i = 0; i < data.response.length; i++) {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickCities}
                  className="Camera"
                  id={data.response[i]}
                  key={data.response[i]}
                  name={data.response[i]}
                >
                  {data.response[i]}
                </Dropdown.Item>
              );
              this.state.cameras.push(item);
            }
            
            const cookies = new Cookies();
            this.setState({
              pagination: pagination,
              loading: false,
              tempId: this.state.cameras[0].key,
              tempName: this.state.cameras[0].key,
            });
            this.updateDashboard(
              localStorage.getItem("idGroup"),
              localStorage.getItem("nameGroup"),
              false,
              new Date().getTime(),
              new Date().getTime() - 1 * 3600 * 1000
            );
             
           
           
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  getListCameras() {
    this.setState({ cameras2: [], tempId2: "", tempName2: "" });
    API.get("https://dot", "/cameraSpecific/1" + "?groupId=" + localStorage.getItem("idGroup") + "&model=ML-D7")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length != 0) {
              data.forEach((element) => {
                let item = (
                  <Dropdown.Item
                    onClick={this.handleClickCamera}
                    className="Camera"
                    id={element.id}
                    key={element.id}
                    name={element.name}
                  >
                    Cámara {element.name}
                  </Dropdown.Item>
                );
                this.state.cameras2.push(item);
              });
              this.setState({
                //loading: false,
                tempId2: this.state.cameras2[0].key,
                tempName2: this.state.cameras2[0].props.name,
              });
              
              this.updateDashboard2(
                this.state.cameras2[0].key,
                this.state.cameras2[0].props.name,
                false,
                new Date().getTime(),
                new Date().getTime() - 1 * 3600 * 1000
              );

            } else {
            }
          });
            } else {
              console.log("Error" + response);
            }
          })
      .catch((error) => console.log(error));
  }

  showGraphics(isUpdate, startDate, finishDate) {
    this.setState({ loading: true, loadingBargraphic: true, loadingPieGraphic: true, loadingLineGraphic: true});
    let rows = [];
    let arrayX2DateChartBar = [];
    let arrayY2InChartBar = [];
    let arrayXLabelChartBar_2 = [];
    let arrayYChartBar_2 = [];
    let arrayY2ChartBar_2 = [];
    let counter = 0;
    let maximo = 0;
    let aforoStores = 0;
    let position;
    let store;
    let startDateLastWeek =  new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
    let medianoche = new Date().setHours(0,0,0,0);
    let WomenPie;
    let totalWomen = [];
    let MenPie;
    let totalMen = [];
    let configStateBar;
    let configStateBar_2;
    let configStatePie;
    
/*
    do {
      console.log("Ciudad", this.state.tempName.length)
    } while (this.state.tempName.length === 0);
  */  
    
    /* API grafica de barra y circular de caracterizacion */
    API.get("https://dot", "/analytics/general?start=" + medianoche + "&end=" + startDate + "&city=" + this.state.tempName)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if(data.response.length !==0){
              const cookies = new Cookies();
              let menu = cookies.get("modules");
              document.getElementById("chartBarCapacity").style.display = "";
              document.getElementById("loadingBarCapacity").style.display = "none";
              var nCountIn = 0
              var nCountOut = 0
              data.response.forEach((element) => {
                arrayX2DateChartBar.push(element.name);                 

                arrayY2InChartBar.push({
                  x: element.name,
                  y: element.data.Entrada,
                });  
                //let row = { id: counter, name: element.name, in: element.data.Salida, out: element.data.Entrada, men: element.data.Hombres, women: element.data.Mujeres, boys: element.data.Niños, girls: element.data.Niñas };
                let row = { id: counter, name: element.name, in: element.data.Entrada, out: element.data.Salida};
                nCountIn += element.data.Entrada;
                nCountOut += element.data.Salida

                rows.push(row);
                counter++;

                WomenPie = element.data.Mujeres;
                totalWomen.push(WomenPie);
                MenPie = element.data.Hombres;
                totalMen.push(MenPie);
                
                arrayXLabelChartBar_2.push(element.name);  
                arrayYChartBar_2.push({
                  x: data.response.indexOf(element),
                  y: element.data.Mujeres,
                });
                arrayY2ChartBar_2.push({
                  x: data.response.indexOf(element),
                  y: element.data.Hombres,
                });

              });
              let row = { id: counter, name: "Resumen de todos los accesos", in: nCountIn, out: nCountOut};
              rows.push(row);
              this.setState({ data: rows, loading: false });


              for(let i=0; i<arrayY2InChartBar.length; i++){

                aforoStores = aforoStores + arrayY2InChartBar[i].y;

                if(arrayY2InChartBar[i].y > maximo){
                  maximo = arrayY2InChartBar[i].y;
                  position = i;
                }
              } 

              if((position === null) || (position === undefined)){
                store = "En espera de datos";
              }else{
                store = arrayY2InChartBar[position].x;
              }                

              this.setState({ aforo: aforoStores, store: store});

              for(let i=0; i<menu.length; i++){
                if(menu[i] == "item-characterization"){

                  document.getElementById("pruebaimagen").style.display= "none";
                  document.getElementById("pruebaimagen2").style.display= "none";
                  
                  if(data.response.length !==0){
                    document.getElementById("chartPie").style.display = "";
                    document.getElementById("loadingPie").style.display = "none";
                    WomenPie = 0;
                    MenPie = 0;

                    totalWomen.forEach ((number) => {
                      WomenPie += number;
                    });
                    totalMen.forEach ((number) => {
                      MenPie += number;
                   });
                  } else{
                    document.getElementById("chartPie").style.display = "none";
                    document.getElementById("loadingPie").style.display = "";
                  }

                }else{
                    document.getElementById("pruebaimagen").style.display= "";
                    document.getElementById("pruebaimagen2").style.display= "";
                    document.getElementById("chartPie").style.display = "none";
                    document.getElementById("chartBar_2").style.display="none";
                }
               
              }
            } else{
              document.getElementById("chartBarCapacity").style.display = "none";
              document.getElementById("loadingBarCapacity").style.display = ""; 
            
              this.setState({ loading: false });
              document.getElementById("pruebaimagen").style.display= "none";
              document.getElementById("pruebaimagen2").style.display= "none";
              document.getElementById("chartPie").style.display = "none";
              document.getElementById("loadingPie").style.display = "";
              document.getElementById("loadingLine").style.display = "";
            }

            configStatePie = {
              type: "doughnut",
              options: {
                maintainAspectRatio: false,
                responsive: true,
              },
              
              data: {
                labels: ["Mujeres", "Hombres"],
                datasets: [
                  { 
                    data:[WomenPie, MenPie],
                    backgroundColor: ["#84ac34", "#1A3D5A"],
                    borderColor: ["#84ac34", "#1A3D5A"],
                    borderWidth: 3,
                  },
                ],
              },
            };
            
            configStateBar_2 = {
              type: "bar",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                        },
                      },
                    ],
                  },
                },
                
                data: {
                  labels: arrayXLabelChartBar_2,
                  datasets: [
                    {
                      label: ["Mujeres"],
                      data: arrayYChartBar_2,
                      backgroundColor: "#84ac34",
                      borderColor: "#84ac34",
                      borderWidth: 3,
                    },
                    {
                      label: ["Hombres"],
                      data: arrayY2ChartBar_2,
                      backgroundColor: "#1A3D5A",
                      borderColor: "#1A3D5A",
                      borderWidth: 3,
                    },
                  ]
                },
            };
            configStateBar = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 100,
                      },
                    },
                  ],
                },
              },
              
              data: {
                labels: arrayX2DateChartBar,
                datasets: [{
                  label: ["Ingresos"],
                  data: arrayY2InChartBar,
                  backgroundColor: "#7DAB22",
                  borderColor: "#7DAB22",
                  borderWidth: 3,
                },
                ]
              },
            };
  
            if (isUpdate) {
              let tmpBar = this.state.barGraphic;
              tmpBar.data.labels = configStateBar.data.labels;
              tmpBar.data.datasets = configStateBar.data.datasets;
              tmpBar.update();
              this.setState({
                barGraphic: tmpBar,
                loadingBargraphic: false,
              });
            } else {
              let bar = document.getElementById("chartBarCapacity").getContext("2d");
              let chartBarIn = new Chart(bar, configStateBar);
              this.setState({
                barGraphic: chartBarIn,
                loadingBargraphic: false,
              });
            }

            if (isUpdate) {
              let tmpBar = this.state.barGraphic;
              tmpBar.data.labels = configStateBar.data.labels;
              tmpBar.data.datasets = configStateBar.data.datasets;
              tmpBar.update();
              this.setState({
                barGraphic: tmpBar,
                loadingBargraphic: false,
              });

              let tmpPie = this.state.barGraphic;
              let tmpLine = this.state.barGraphic_2;
              tmpPie.data.labels = configStatePie.data.labels;
              tmpPie.data.datasets = configStatePie.data.datasets;
              tmpPie.update();
              tmpLine.data.labels = configStateBar_2.data.labels;
              tmpLine.data.datasets = configStateBar_2.data.datasets;
              tmpLine.update();

              this.setState({
                pieGraphic: tmpPie,
                barGraphic_2: tmpLine,
                loadingPieGraphic: false,
                loadingLineGraphic: false,
              });

            } else {
              let pie = document.getElementById("chartPie").getContext("2d");
              let chartPie = new Chart(pie, configStatePie);
              let bar_2 = document.getElementById("chartBar_2").getContext("2d");
              let chartBar_2 = new Chart(bar_2, configStateBar_2);
              let bar = document.getElementById("chartBarCapacity").getContext("2d");
              let chartBarIn = new Chart(bar, configStateBar);
              this.setState({
                barGraphic: chartBarIn,
                loadingBargraphic: false,
              });
              this.setState({
                pieGraphic: chartPie,
                barGraphic_2: chartBar_2,
                loadingPieGraphic: false,
                loadingLineGraphic: false,
              });
            }
          });

        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }
  
  showGraphicsZones(id, name, isUpdate, startDate, finishDate) {

    let arrayXDateZones = [];
    let configStateLine_2;
    let datasetsZone = [];
    let nombreMayorZona;
    let medianoche = new Date().setHours(0,0,0,0);

    this.setState({ loadingBarZones: true});

    API.get(
      "https://dot",
      "/analytics/zones/" + id + "?start=" + medianoche + "&end=" + startDate + "&groupId=" + localStorage.getItem("idGroup")
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              document.getElementById("chartLineZones").style.display = "";
              document.getElementById("loadingCharLineZones").style.display = "none";

              let zonesNames = Object.keys(data[0].zones);
              let zonesObject = {};
              let peopleZones = 0;
              let mayorzona = 0;
              let positionZone;
              let totalPorZonas = [];
              let suma = 0;

              zonesNames.forEach((zone) => {
                zonesObject[zone] = [];
                data.forEach((element) => {
                  zonesObject[zone].push({
                    x: data.indexOf(element),
                    y: element.zones[zone],
                  });
                  peopleZones += element.zones[zone];
                });
              });

              let objetoZonas =  Object.values(zonesObject);

              for(let i=0; i<objetoZonas.length; i++){

                let listaObjetoZona = Object.values(zonesObject)[i];
                /* Obteniendo los valores de cada zona */
                for(let i=0; i<listaObjetoZona.length; i++){

                  suma += listaObjetoZona[i].y;
                  
                }
                totalPorZonas.push(suma);
                /* Obtener la zona con más tráfico y obtener su posición*/
                for(let i=0; i<totalPorZonas.length; i++){

                  if(totalPorZonas[i] > mayorzona){
                    mayorzona = totalPorZonas[i];
                    positionZone = i;
                  }

                }
                nombreMayorZona = zonesNames[positionZone];
              }

              this.setState({ aforoZonas: peopleZones, zona: nombreMayorZona });

              data.forEach((element) => {
                arrayXDateZones.push(element.event_date);
              });

              datasetsZone = [];
              let arrayColors = ["#BEF74A", "#A0B96F", "#D6F794", "#C6F763"];
              let counter = 0;

              zonesNames.forEach((zone) => {
                datasetsZone.push({
                  label: [zone],
                  data: zonesObject[zone],
                  backgroundColor: ["rgb(255, 255, 255, 0)"],
                  //backgroundColor:  arrayColors[counter],
                  borderColor: arrayColors[counter],
                  pointHoverBorderWidth: 10,
                });
                counter++;
              });

            } else {
              document.getElementById("chartLineZones").style.display = "none";
              document.getElementById("loadingCharLineZones").style.display = "";
            }

            configStateLine_2 = {
              type: "line",
              options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                        },
                      },
                    ],
                  },
              },
              data: {
                labels: arrayXDateZones,
                datasets: datasetsZone,
              },
            };

            if (isUpdate) {
              let tmpLine = this.state.lineGraphic_2;
              tmpLine.data.labels = configStateLine_2.data.labels;
              tmpLine.data.datasets = configStateLine_2.data.datasets;
              tmpLine.update();

              this.setState({
                lineGraphic_2: tmpLine,
                loadingBarZones: false,
                loading: false,
              });
            } else {
              let line = document.getElementById("chartLineZones").getContext("2d");
              let chartLineZones = new Chart(line, configStateLine_2);

              this.setState({
                lineGraphic_2: chartLineZones,
                loadingBarZones: false,
                loading: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));
  
  }

  async showUpdates() {
    try {
      const res = await API.get("https://dot", "/general");
      let js = await res.json();
      let arry = [];
      if (!Object.keys(js).includes("message")) {
        js.forEach((element) => {
          const url = element.url_tuto;
          arry.push(
            <div className="Card">
              <div className="contain_update" key={js.indexOf(element)}>
                <label className="Title-Update">{element.description} </label>
                <label className="Text-Update">
                  <img src={iconHand} alt="Icono noticia" width="26rem"></img>{" "}
                  <a
                    className="Link-Update"
                    href={"http://www." + url}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {element.url_tuto}
                  </a>
                </label>
              </div>
            </div>
          );
        });
      } else {
        arry.push(
          <div className="contain_update-none" key="update">
            <strong>{js.message}</strong>
          </div>
        );
      }
      this.setState({
        updates: arry,
      });
    } catch (error) {
      return error;
    }
  }

  verifyModule(){
    const cookies = new Cookies();
    let menu = cookies.get("modules");
    for(let i=0; i<menu.length; i++){
      if(menu[i] == "item-characterization"){
        document.getElementById("pruebaimagen").style.display= "none";
        document.getElementById("pruebaimagen2").style.display= "none";
        document.getElementById("chartBar_2").style.display="";
      }else{
        document.getElementById("pruebaimagen").style.display= "";
        document.getElementById("pruebaimagen2").style.display= "";
        document.getElementById("chartBar_2").style.display="none";
      }
    }
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard";
    this.getListCities(this.state.pagination);
    this.verifyModule();
    this.getListCameras();
    this.getListGroups()
    this.hideObjects();

    //document.getElementById("chat").style.display = "none";
  }

  openChat = () => {
    this.handleOpen();
  }

  handleOpen(){
    if(this.state.switchChat === 0){
      document.getElementById("chat").style.display = "";
      this.setState({ switchChat: 1});

    } else if(this.state.switchChat === 1){
      document.getElementById("chat").style.display = "none";
      this.setState({ switchChat: 0});
    }
  }

  componentWillUnmount() {
  }
  
  hideObjects(){
    document.getElementById("loadingBarCapacity").style.display = "none";
    document.getElementById("loadingPie").style.display = "none";
    document.getElementById("loadingLine").style.display = "none";
    document.getElementById("loadingCharLineZones").style.display = "none";
    document.getElementById("pruebaimagen").style.display= "none";
    document.getElementById("pruebaimagen2").style.display= "none";
    
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <Row>
            <Col lg={12}>
              <Row>
              {/* <Col lg={2} className="pr-0"><LateralMenu></LateralMenu></Col> */}
                <Col lg={12}>
                  <div>
                    <div className="Body-Dashboard" id="Body-Dashboard">
                      <Row>
                        <Col lg={1}></Col>
                        
                        <Col lg={2}>
                          <div className="form-inline">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="Camera-Button"
                              >
                                {this.state.tempName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.cameras}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                        
                      </Row>
                      
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={6}>
                          <div className="Container-Layout">
                            <label className="label-Option">¿Cuál ha sido el tráfico de personas en mis zonas hoy?</label>
                              <div className="Load">
                                    {this.state.loadingBargraphic  && (
                                      <div className="loader"></div>
                                    )}
                                  </div>
                                  <canvas id="chartBarCapacity" className="pb-3"></canvas>
                                  <div id="loadingBarCapacity">
                                    <div className="Div-Center">
                                      <br></br>
                                      <br></br>
                                      <img
                                        src={working}
                                        width="80rem"
                                        alt="Imagen de Loading"
                                      ></img>
                                      <br></br>
                                      <br></br>
                                      <label className="Text-Message">
                                        Estamos procesando tus datos
                                      </label>
                                      <br></br>
                                      <label className="Text-Message">
                                        Pronto tendremos estadísticas para ti
                                      </label>
                                    </div>
                                  </div>
                          </div>
                        </Col>
                        <Col lg={4} className="pt-4">
                          <Row>
                            <Col lg={10} className="Card-insight mr-4 mb-3">
                              <Row style={{minHeight: "7rem"}}>
                                <Col lg={12} className="mx-2">
                                  <Row className="pt-3" style={{margin:"auto"}}>
                                    <label className="label-Option-Dashboard">{" "}¿En cuál zona hubo mayor tráfico de personas hoy?</label>
                                  </Row>
                                  <Row>
                                    <div style={{ margin:"auto"}}>
                                      <label className="Insight-Home">{this.state.store}</label>
                                    </div> 
                                  </Row>
                                </Col>  
                              </Row>            
                            </Col>
                          </Row>   
                          <Row>
                            <Col lg={10} className="Card-insight mr-4 mb-3">
                              <Row style={{minHeight: "7rem"}}>
                                <Col lg={12} className="mx-2">
                                  <Row className="pt-3" style={{ margin:"auto"}}>
                                    <label className="label-Option-Dashboard">{" "}¿Cuántas personas han ingresado hoy en mis zonas?</label>
                                  </Row>
                                  <Row>
                                    <div style={{ margin:"auto"}}>
                                      <label className="Insight-Home">{this.state.aforo}</label>
                                    </div> 
                                  </Row>
                                </Col>  
                              </Row>            
                            </Col>
                          </Row>                                                
                        </Col>
                        <Col lg={1}></Col>
                      </Row>
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={6}>
                         {/*  <div className="Container-Layout" id="pruebaimagen">
                            <img src={imagenPrueba} alt="prueba" height="50vh"></img>
                          </div> */}
                        
                          <div className="Container-Layout">
                            <label className="label-Option">Caracterización en cada una de mis zonas hoy</label>
                            <div className="Load">
                              {this.state.loadingLineGraphic  && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <div>
                              <img src={imagenPrueba} id="pruebaimagen" alt="prueba" height="100%" class="mx-auto d-block"></img>
                            </div>
                            <canvas id="chartBar_2" className="pb-3"></canvas>
                            
                            <div id="loadingLine">
                              <div className="Div-Center">
                                <br></br>
                                <br></br>
                                <img
                                  src={working}
                                  width="80rem"
                                  alt="Imagen de Loading"
                                ></img>
                                <br></br>
                                <br></br>
                                <label className="Text-Message">
                                  Estamos procesando tus datos
                                </label>
                                <br></br>
                                <label className="Text-Message">
                                  Pronto tendremos estadísticas para ti
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="Container-Layout">
                            <label className="label-Option">Mujeres y hombres en mis zonas hoy</label>
                            <div className="Load">
                              {this.state.loadingPieGraphic  && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <div>
                              <img src={imagenPrueba2} id="pruebaimagen2" alt="prueba" height="100%" class="mx-auto d-block"></img>
                            </div>
                            <canvas id="chartPie" className="p-3"></canvas>
                            <div id="loadingPie">
                              <div className="Div-Center">
                                <br></br>
                                <br></br>
                                <img
                                  src={working}
                                  width="80rem"
                                  alt="Imagen de Loading"
                                ></img>
                                <br></br>
                                <br></br>
                                <label className="Text-Message">
                                  Estamos procesando tus datos
                                </label>
                                <br></br>
                                <label className="Text-Message">
                                  Pronto tendremos estadísticas para ti
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={1}></Col>
                      </Row>
                      <Row>
                      <Col lg={1}></Col>
                      <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="Camera-Button"
                            >
                              Grupo {localStorage.getItem("nameGroup")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.groups}
                            </Dropdown.Menu>
                          </Dropdown>
                        
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-basic"
                            className="Camera-Button"
                          >
                            Cámara {this.state.tempName2}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.cameras2}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Col lg={1}></Col>
                      </Row>
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={4} className="pt-4 pl-4">
                          <Row style={{margin:"auto"}}>
                            <Col lg={10} className="Card-insight">
                              <Row style={{minHeight: "7rem"}}>
                                <Col lg={12} className="mx-2">
                                  <Row className="pt-3">
                                    <label className="label-Option-Dashboard">{" "}¿En cuál zona hubo mayor tráfico de personas hoy?</label>
                                  </Row>
                                  <Row>
                                    <div style={{ margin: "auto"}}>
                                      <label className="Insight-Home">{this.state.zona}</label>
                                    </div> 
                                  </Row>
                                </Col>  
                              </Row>            
                            </Col>
                          </Row>   
                          <Row className="pt-3" style={{margin:"auto"}}>
                            <Col lg={10} className="Card-insight">
                              <Row style={{ minHeight: "7rem"}}>
                                <Col lg={12} className="mx-2">
                                  <Row className="pt-3" style={{margin:"auto"}}>
                                    <label className="label-Option-Dashboard">{" "}Promedio de personas en mis zonas hoy</label>
                                  </Row>
                                  <Row>
                                    <div style={{margin:"auto"}}>
                                      <label className="Insight-Home">{this.state.aforoZonas}</label>
                                    </div> 
                                  </Row>
                                </Col>  
                              </Row>            
                            </Col>
                          </Row>                                                
                        </Col>
                        <Col lg={6}>
                        <div className="Container-Layout">
                            <label className="label-Option">Estimados de personas en la última hora en zonas</label>
                            <div className="Load">
                              {this.state.loadingBarZones  && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <canvas id="chartLineZones" className="pb-3"></canvas>
                            <div id="loadingCharLineZones">
                              <div className="Div-Center">
                                <br></br>
                                <br></br>
                                <img
                                  src={working}
                                  width="80rem"
                                  alt="Imagen de Loading"
                                ></img>
                                <br></br>
                                <br></br>
                                <label className="Text-Message">
                                  Estamos procesando tus datos
                                </label>
                                <br></br>
                                <label className="Text-Message">
                                  Pronto tendremos estadísticas para ti
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={1}></Col>
                      </Row>  
                       
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={10}>
                          <Row>
                            <Col lg={12}>
                              <div className="Table">
                                <ReactTabulator
                                  data={this.state.data}
                                  columns={this.state.columns}
                                  tooltips={true}
                                  layout={"fitData"}
                                  columnVertAlign="center"
                                  
                                />
                              </div>
                              <div className="Load">
                                {this.state.loading && (
                                  <div className="loader"></div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={1}></Col>

                      </Row>

                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* position: "fixed", botton: "50px", padding: "5px", right: "10px", height: "150px", width: "85px", backgrounColor: "black" 
            <div id="chat" style={{ display: "flex", position: "fixed", justifyContent: "flex-start", flexDirection: "column", backgroundColor: "black", height: "calc(100% - 120px)", bottom: "100px", right: "20px", width: "376px", overflow: "hidden", zIndex: "3"}}>
              <div> X   {" "} Switch AI
                <br/>
                <p>Te responderemos tan pronto veamos el mensaje</p>
              </div>
            </div>
            <Button style={{height: "50px", width: "55px", position: "fixed", backgroundColor: "#84ac32", right: "10px", cursor: "pointer", zIndex: "3", borderRadius: "50px", bottom: "30px", padding: "5px", borderColor: "#84ac32", alignItems: "center"}} onClick={this.openChat}> 
            <img 
              src={chat} 
              style={{height: "38px", width: "38px"}}
              />
            </Button>*/}
          </Row>
          <div>

          </div>
          <div>
              
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default DashboardMarketing;
