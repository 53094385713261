//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
//import NavMenuBar from "../../components/NavMenu";
import API from "../../api/Main";
import { Row, Col, Dropdown, } from "react-bootstrap";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import Cookies from "universal-cookie";
import Schedules from "../../utils/HoursConfiguration";
import '@nivo/core';
import '@nivo/calendar';
import { ResponsiveCalendarCanvas } from "@nivo/calendar";
import ProgressBar from 'react-bootstrap/ProgressBar';
//import { ResponsiveCalendarCanvas, ResponsiveCalendar , Calendar} from '@nivo/calendar'

//Import images
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/home.css";

const _ = require("underscore");

class HomeOwner extends React.Component {
  utils = new Schedules();
  constructor(props) {
    let today = new Date(),
        date = today.getFullYear(),
        firstDay = new Date(new Date().getFullYear(), 0, 1),
        firstDayMilium = firstDay.getTime()/1000;

    super(props);
    this.state = {
      dateFrom: "",
      dateTo: "",
      firstDayYear: firstDayMilium,
      year: date,
      women: 0,
      men: 0,
      groups: [],
      eightDayBefore: 0,
      TotalPeopleLastWeek: 0,
      TotalPeopleToday: 0,
      tempId: "",
      tempName: "",
      progress: 0.0,
      datosTotales: 0,
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 604800000),
      middleNight: new Date().setHours(0,0,0,0),
      trafic: [],
      data: [],
      loadingStaticInBar: false,
      loadingCalendar: false,
      barGraphic: {},
      capacity: "",
      loading: true,
      pagination: 1,
      counter: 0,
      cameras: [],
      dataCalendar: [],
    };
  }

  handleClickCamera = (e) => {
    this.setState({
      loading: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    this.updateDashboardStatic(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
    this.getAforo();
  };
  
  getListCameras(pagination) {
    API.get("https://dot", "/cameras/1" + pagination)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickCamera}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  {element.name}
                </Dropdown.Item>
              );
              this.state.cameras.push(item);
            });

            this.setState({
              loading: false,
              tempId: this.state.cameras[0].key,
              tempName: this.state.cameras[0].props.name,
            });
            this.updateDashboardStatic(
              this.state.cameras[0].key,
              this.state.cameras[0].props.name,
              false,
              new Date().getTime(),
              new Date().getTime() - 1 * 3600 * 1000
            );
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  updateDashboardStatic(id, name, isUpdate, startDate, finishDate){
    this.showGraphicsStatic(id, isUpdate, startDate, finishDate);
  }

  showGraphicsStatic(id, isUpdate, startDate, finishDate) {
    this.getAforo();

    let arrayY2InChartBar = [];
    let arrayX2DateChartBar = [];
    let arrayXDateCapacity = [];
    let arrayDates = [];
    let configStateBar;
    let startDateTrafic = new Date().getTime() - (8 * 24 * 60 * 60 * 1000);

    let NumberWomen = 0;
    let NumberMen = 0;
    let NumberPeopleOnWeek = 0;
    let NumberPeopleToday = 0;
    let startDateCalendar = new Date(new Date().getFullYear(), 0, 1);
    let finishDateCalendar =  new Date(new Date().getTime());
    let formatstartDateTrafic = new Date(startDateTrafic);
    let startDateFormat = `${startDateCalendar.getFullYear()}-${startDateCalendar.getMonth()+1}-${startDateCalendar.getDate()}`;
    let finishDateFormat = `${finishDateCalendar.getFullYear()}-${finishDateCalendar.getMonth()+1}-${finishDateCalendar.getDate()}`;
    
    this.setState({ loadingCalendar: true, loadingStaticInBar: true });

    API.get(
      "https://dot",
      "/analytics/gender" + "?start=" + finishDate + "&end=" + startDate)
      .then((response) => {

        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              
              NumberWomen = NumberWomen + data.women;
              NumberMen = data.men + NumberMen; 
              
              this.setState({ women: NumberWomen, men: NumberMen, loading: false });

            } else {
              console.log("Error" + response);
            }            
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));    

    console.log("formatstartDateTrafic",startDateFormat);
    API.get(
      "https://dot",
      "/analytics/resume-traffic/"+
      "?groupId=" +
      localStorage.getItem("idGroup") +
      "&start=" + startDateFormat +
      "&end=" + finishDateFormat
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              document.getElementById("chartBar").style.display = "";
              document.getElementById("loadingBarChartIn").style.display = "none";
              data.forEach((element) => {
                arrayX2DateChartBar.push(element.fecha);
                arrayY2InChartBar.push({
                  x: data.indexOf(element),
                  y: element.come_in,
                });
                NumberPeopleOnWeek = NumberPeopleOnWeek + element.come_in;
              });

              data.forEach((element) => {
                arrayXDateCapacity.push(element.fecha);
              });

              for(let i=0; i<arrayXDateCapacity.length; i++){
                var fechaEjeX = new Date(arrayXDateCapacity[i]);
                var fechaFinal = `${fechaEjeX.getFullYear()}-${fechaEjeX.getMonth()+1}-${fechaEjeX.getDate()}`;
                arrayDates.push(fechaFinal);
              } 

              let firstElement = arrayY2InChartBar[0];
              this.setState({ TotalPeopleLastWeek: NumberPeopleOnWeek, eightDayBefore: firstElement.y});
            
            } else {
              document.getElementById("chartBar").style.display = "none";
              document.getElementById("loadingBarChartIn").style.display = "";
            }

            configStateBar = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 100,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayDates,
                datasets: [
                  {
                    label: ["Tráfico"],
                    data: arrayY2InChartBar,
                    backgroundColor: "#7DAB22",
                    borderColor: "#7DAB22",
                    borderWidth: 3,
                  }
                ],
              },
            };


            if (isUpdate) {
              let tmpBar = this.state.barGraphic;
              tmpBar.data.labels = configStateBar.data.labels;
              tmpBar.data.datasets = configStateBar.data.datasets;
              tmpBar.update();
              this.setState({
                barGraphic: tmpBar,
                loadingStaticInBar: false,
              });
            } else {
              let bar = document.getElementById("chartBar").getContext("2d");
              let chartBarIn = new Chart(bar, configStateBar);

              this.setState({
                barGraphic: chartBarIn,
                loadingStaticInBar: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
    })
    .catch((error) => console.log("error ", error));

    API.get(
      "https://dot",
      "/analytics/traffic" + "?start=" + this.state.middleNight + "&end=" + finishDate + "&groupId=" + localStorage.getItem("idGroup")
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              data.forEach((element) => {
                NumberPeopleToday = NumberPeopleToday + element.come_in;
              });

              this.setState({ TotalPeopleToday: NumberPeopleToday});

            }else{
            }

          })
        }
      })
    .catch((error) => console.log("error ", error));

      let arrayCalendar = [];
      let arrayDataCalendar = [];
      
      API.get(
        "https://dot",
        "/analytics/resume-traffic/"+
        "?groupId=" +
        localStorage.getItem("idGroup") +
        "&start=" + startDateFormat +
        "&end=" + finishDateFormat
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              if (data.length !== 0) {
                document.getElementById("chartCalendar").style.display = "none";
                document.getElementById("loadingChartCalendar").style.display = "none";
                document.getElementById("loadingBarCalendar").style.display = "";
                
                this.setState({ datosTotales: data.length});
                const archivosTotales = this.state.datosTotales;
                var porcentaje = 100/this.state.datosTotales;
                data = data;
                const self=this;

                  for(let i=0; i<archivosTotales; i++){
                    document.getElementById("loadingBarCalendar").style.display = "";
                    setTimeout(function(){
                      data.forEach((element) => {
                        arrayCalendar.push({
                          day: element.fecha,
                          value: element.come_in,
                        })
                      });

                      var conversion = new Date(arrayCalendar[i].day); 
                      var valor = arrayCalendar[i].value;

                      if(conversion.getMonth()<9){

                        if(conversion.getDate()<9){

                          let completeDay = `${conversion.getFullYear()}-0${conversion.getMonth()+1}-0${conversion.getDate()}`;
                          arrayDataCalendar.push({
                            day: completeDay,
                            value: valor,
                          })

                        }else{

                          let completeDay = `${conversion.getFullYear()}-0${conversion.getMonth()+1}-${conversion.getDate()}`;
                          arrayDataCalendar.push({
                            day: completeDay,
                            value: valor,
                          })

                        }
                        
                      }else{
                        if(conversion.getDate()<9){

                          let completeDay = `${conversion.getFullYear()}-${conversion.getMonth()+1}-0${conversion.getDate()}`;
                          arrayDataCalendar.push({
                            day: completeDay,
                            value: valor,
                          })

                        }else{

                          let completeDay = `${conversion.getFullYear()}-${conversion.getMonth()+1}-${conversion.getDate()}`;
                          arrayDataCalendar.push({
                            day: completeDay,
                            value: valor,
                          })
                        }
                        
                      }  

                      self.setState({progress: self.state.progress+porcentaje, datosTotales: self.state.datosTotales-1});
            
                      if(i == archivosTotales-1){
                        document.getElementById("chartCalendar").style.display = "";
                        document.getElementById("loadingBarCalendar").style.display = "none";
                        self.setState({progress: 0.0});
                      }
                      
                    },(2000*i))
                  }
                  
                this.setState({
                  //loadingCalendar: false,
                  dataCalendar: arrayDataCalendar
                });
                
              }else{
                document.getElementById("chartCalendar").style.display = "none";
                document.getElementById("loadingChartCalendar").style.display = "";
                document.getElementById("loadingBarCalendar").style.display = "none";

                this.setState({
                  loadingCalendar: false,
                });

              }
            });
          }
        }) 
      .catch((error) => console.log("error ", error));
  }

  handleClickGroup = (e) => {
    localStorage.setItem("idGroup", e.target.id);
    localStorage.setItem("nameGroup", e.target.name);
    //this.getListCameras();
    //this.getListGroups();
    console.log("inside handle roup");
    this.updateDashboardStatic(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - (7 * 24 * 60 * 60 * 1000) 
    );
    //this.setState({ capacity: "0" });
  };

  getListGroups() {
    API.get("https://dot", "/resource_group")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickGroup}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  {element.name}
                </Dropdown.Item>
              );
              this.state.groups.push(item);
            });
            this.setState({
              loading: false,
            });
            localStorage.setItem("idGroup", this.state.groups[0].key);
            localStorage.setItem("nameGroup", this.state.groups[0].props.name);

            this.getAforo();
            console.log("idGroup", this.state.groups[0].key, "nameGroup", this.state.groups[0].props.name);
            this.updateDashboardStatic(
              localStorage.getItem("idGroup"),
              localStorage.getItem("nameGroup"),
              false,
              new Date().getTime(),
              new Date().getTime() - 1 * 3600 * 1000
            );
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }


  verifyCaracterizacion(){
    const cookies = new Cookies();
    let menu = cookies.get("modules");
    let i = 0;
     while( i < menu.length ){
        if(menu[i] == "item-characterization"){
          document.getElementById("Caracterizacion1").style.display = "";
          document.getElementById("Caracterizacion2").style.display = "";
          document.getElementById("noCaracterizacion1").style.display = "none";  
          document.getElementById("noCaracterizacion2").style.display = "none";    
          break;
        }
        i++;
      } 
  }

  componentDidMount() {
    document.title = "Switch AI | Home";
    this.getListGroups();
    this.hideObjects();
    //this.getListCameras(this.state.pagination);
    this.getAforo();
    this.intervalId = setInterval(
      () =>
        this.updateDashboardStatic(
          localStorage.getItem("idGroup"),
          localStorage.getItem("nameGroup"),
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );  

    document.getElementById("Caracterizacion1").style.display = "none";
    document.getElementById("Caracterizacion2").style.display = "none";  
    this.verifyCaracterizacion();
    this.setState({ dateFrom: `${this.state.year}-01-31`, dateTo:  `${this.state.year}-12-31` });
  }

    async updateAforo() {
        try {
        const request = await API.get("https://dot", "/analytics/capacity?groupId=" + localStorage.getItem('idGroup'));
        const data = await request.json();
        this.setState({ capacity: data.response });

        } catch (error) {
          throw new Error(error);
        }
    }

    hideObjects() {
      document.getElementById("loadingBarChartIn").style.display = "none";
      document.getElementById("loadingChartCalendar").style.display = "none";
      document.getElementById("chartCalendar").style.display = "none";
      document.getElementById("loadingBarCalendar").style.display = "";
    }

    getAforo() {
  
      API.get("https://dot", "/analytics/capacity?groupId=" + localStorage.getItem('idGroup'))
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              this.setState({ capacity: data.response });
            });
            //console.log(contar++)
          } else {
            console.log("Error" + response);
          }
        })
        .catch((error) => console.log(error));
      
        setInterval(() => {
          this.updateAforo();
        }, 60000);
    }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Home">
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <div>
                  <div className="Div-Justify">
                    <Row>
                      <Col lg={4}>
                        <div className="form-inline">
                            <span className="Title-greeting">
                                Hola, {localStorage.getItem("UserName")}
                            </span>
                        </div>                        
                      </Col>
                    </Row>
                    <br></br>
                    <Row className="mt-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="Camera-Button"
                        >
                          {localStorage.getItem("nameGroup")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.groups}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row> 
                  </div>

                  <div id="Configuration" className="Container-Configuration">
                    <Row>
                      <Col lg={6}>
                        <div className="Container-Layout-Home">
                          <label className="label-Option">{" "}¿Cuántas personas han ingresado en los últimos 7 días?</label>
                          <div className="Load">
                            {this.state.loadingStaticInBar && (
                            <div className="loader"></div>
                          )}
                          </div>
                          <canvas id="chartBar" className="pb-4" ></canvas>
                          <div
                            id="loadingBarChartIn"
                            className="Div-Center"
                          >
                            <br></br>
                            <br></br>
                            <img
                              src={working}
                              width="80rem"
                              alt="Imagen de Loading"
                            ></img>
                            <br></br>
                            <br></br>
                            <label className="Text-Message">
                              Estamos procesando tus datos
                            </label>
                            <br></br>
                            <label className="Text-Message">
                              Pronto tendremos estadísticas para ti
                            </label>
                          </div>
                        </div> 
                      </Col>
                      <Col lg={6} className="pt-3">
                      <Row>
                          <Col lg={5} className="Card-insight mr-4 mb-3">
                            <Row style={{ minHeight: "6rem" }}>
                              <Col lg={12} className="m-1">
                                <Row className="pt-3" style={{margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Total de visitantes que han ingresado en los últimos 7 días</label>
                                </Row> 
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home">{this.state.TotalPeopleLastWeek}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row> 
                          </Col>
                          <Col lg={5} className="Card-insight mr-4 mb-3">
                            <Row style={{minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" style={{ margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Visitantes en este momento</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home">{this.state.capacity}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                        </Row>
                        <Row> 
                          <Col lg={5} className="Card-insight mr-4 mb-3">
                            <Row style={{ minHeight: "6rem"}}>
                              <Col lg={12} className="m-1">
                                <Row className="pt-3" style={{margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Visitantes que han ingresado hoy</label>
                                </Row> 
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home">{this.state.TotalPeopleToday}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                          <Col lg={5} className="Card-insight mr-4 mb-3">
                            <Row style={{minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" style={{ margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Visitantes hace ocho días</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home">{this.state.eightDayBefore}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                        </Row>
                        <Row>
                        <Col lg={5} className="Card-insight mr-4 mb-3" id="noCaracterizacion1">
                            <Row style={{ minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" style={{ margin: "auto"}}>
                                  <label className="label-Option-insight"  style={{ margin: "auto"}}>{" "}Cantidad de Hombres</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home"  style={{ margin: "auto"}}>¡Actívala!</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col> 
                          <Col lg={5} className="Card-insight mr-4 mb-3" id="noCaracterizacion2">
                            <Row style={{ minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" style={{ margin: "auto"}}>
                                  <label className="label-Option-insight" style={{ margin: "auto"}}>{" "}Cantidad de Mujeres</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home" style={{ margin: "auto"}}>¡Actívala!</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                          <Col lg={5} className="Card-insight mr-4 mb-3" id="Caracterizacion1">
                            <Row style={{ minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" style={{ margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Mujeres que han ingresado hoy</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home">{this.state.women}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                          <Col lg={5} className="Card-insight mr-4 mb-3" id="Caracterizacion2">
                            <Row style={{minHeight: "6rem"}}>
                              <Col lg={12} className="mx-2">
                                <Row className="pt-3" margin={{margin: "auto"}}>
                                  <label className="label-Option-insight">{" "}Hombres que han ingresado hoy</label>
                                </Row>
                                <Row style={{ justifyContent: "center"}}>
                                  <div>
                                    <label className="Insight-Home" style={{ fontSize: "1.5rem", margin: "auto" }}>{this.state.men}</label>
                                  </div> 
                                </Row>
                              </Col>  
                            </Row>            
                          </Col>
                        </Row>
                       
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                      <div className="Container-Box-Home">
                      <label className="label-Option" >{" "}¿Cuántos visitantes han ingresado a tu establecimiento?</label>
                        <div id="loadingBarCalendar" className="mt-2">
                          {this.state.loadingCalendar && (
                            <ProgressBar animated variant="success" max={100} now={this.state.progress} /> 
                          )} 
                          <span className="ProgressText">Estamos procesando tus datos. En un momento verás tu información</span>
                        </div>

                        <div id="chartCalendar" style={{ height: 300, display: "block" }}>
                          <ResponsiveCalendarCanvas
                            data={this.state.dataCalendar}
                            from={this.state.dateFrom}
                            to={this.state.dateTo}
                            emptyColor="#EFEFEF"
                            colors={["#606060", "#323232", "#1a3b5a", "#86ac36", "#beea53"]}
                            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                            yearSpacing={40}
                            monthBorderColor="#ffffff"
                            dayBorderWidth={2}
                            dayBorderColor="#ffffff"
                            legends={[
                              {
                                anchor: "bottom-right",
                                direction: "row",
                                translateY: 36,
                                itemCount: 4,
                                itemWidth: 42,
                                itemHeight: 36,
                                itemsSpacing: 14,
                                itemDirection: "right-to-left"
                              }
                            ]}
                          /> 
                        </div>
                        
                        <div
                          id="loadingChartCalendar"
                          className="Div-Center"
                        >
                          <br></br>
                          <br></br>
                          <img
                            src={working}
                            width="80rem"
                            alt="Imagen de Loading"
                          ></img>
                          <br></br>
                          <br></br>
                          <label className="Text-Message">
                            Estamos procesando tus datos
                          </label>
                          <br></br>
                          <label className="Text-Message">
                            Pronto tendremos estadísticas para ti
                          </label>
                        </div> 
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={1}></Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default HomeOwner;