export const ALERT = {
    CAPACITY: "CAPACITY",
    TRAFFIC: "TRAFFIC",
    DAILY_TRAFFIC: "DAILY_TRAFFIC",
    WITHOUT_PEOPLE: "WITHOUT_PEOPLE",
    PEOPLE_PEAK: "PEOPLE_PEAK",
    PEOPLE_RUNNING: "PEOPLE_RUNNING",
    PEOPLE_ON_BICYCLES: "PEOPLE_ON_BICYCLES",
    ARMED_PEOPLE: "ARMED_PEOPLE",
    RESTRICTED_ACCESS: "RESTRICTED_ACCESS",
    PETS: "PETS",
    LYING_PERSON: "LYING_PERSON",
    AGGLOMERATION: "AGGLOMERATION",
    OBJECT_MOVEMENT: "OBJECT_MOVEMENT",
    DWELL_DETECTION: "DWELL_DETECTION",
    VISITOR_MANAGEMENT: "VISITOR_MANAGEMENT",
    CAMERA_MANAGEMENT: "CAMERA_MANAGEMENT",
};
