import { useState } from "react";

export const useOption = defaultValue => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValue);

    const selectOption = (option, name) => {
        setSelectedOptions({ ...selectedOptions, [name]: option });
    };

    return {
        selectOption,
        selectedOptions,
    };
};
