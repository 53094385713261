import React, { useState } from "react";
import Input from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneInput.scss";

export const PhoneInput = ({ disabled }) => {
    const [value, setValue] = useState("");

    return (
        <Input country="co" value={value} onChange={value => setValue(value)} containerClass="phone-input" disabled={disabled} />
    );
};
