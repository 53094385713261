import React, { useEffect, useState } from "react";
import API from "api/Main";
import Footer from "components/Footer";
import NavMenuBar from "components/NavMenuBar";
import { Calendar } from "./components";
import { closeSideBar } from "utils/Navbar";
import "styles/Schedule/schedule.css";

const Schedule = () => {
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        getCameras();
    }, []);

    const getCameras = async () => {
        const response = await API.get("https://dot", "/resource_group_specific/?model=ML-D11");
        if (response.ok) setCameras(await response.json());
    };

    return (
        <>
            <NavMenuBar />
            <main className="mb-5" id="genericBody" style={{ width: "86%" }} onClick={closeSideBar}>
                <Calendar cameras={cameras} />
            </main>
            <Footer />
        </>
    );
};

export default Schedule;
