//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";
import { Row, Col } from "react-bootstrap";
import API from "../../api/Main";
import { Chart } from "chart.js";

//Import images
import working from "../../imgs/error.png";

//Import styles
import "../../styles/Sympthoms/dashboardsymptoms.css";

class DashboardSymptoms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barGraphicSymptoms: {},
      pieGraphicSymptoms: {},
      barGraphicCovid: {},
      pieGraphicCovid: {},
      loading: true,
    };
  }

  verifyEmails() {
    document.getElementById("noDataBarSymptoms").style.display = "none";
    document.getElementById("noDataCircleSymptoms").style.display = "none";
    document.getElementById("noDataBarCovid").style.display = "none";
    document.getElementById("noDataCircleCovid").style.display = "none";
    document.getElementById("chartBarSymptoms").style.display = "none";
    document.getElementById("chartCircleSymptoms").style.display = "none";
    document.getElementById("chartBarCovid").style.display = "none";
    document.getElementById("chartCircleCovid").style.display = "none";

    API.get("https://dot", "/email")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            this.setState({ loading: false });
            if (data.info_to_send.length === 0) {
              document.getElementById("noDataBarSymptoms").style.display =
                "";
              document.getElementById("noDataCircleSymptoms").style.display =
                "";
              document.getElementById("noDataBarCovid").style.display = "";
              document.getElementById("noDataCircleCovid").style.display =
                "";
            } else {
              document.getElementById("chartBarSymptoms").style.display = "";
              document.getElementById("chartCircleSymptoms").style.display = "";
              document.getElementById("chartBarCovid").style.display = "";
              document.getElementById("chartCircleCovid").style.display = "";
              this.showGraphics(false);
            }
          });
        } else {
          response.json().then((data) => {
            this.setState({ loading: false });
            document.getElementById("noDataBarSymptoms").style.display = "";
            document.getElementById("noDataCircleSymptoms").style.display =
              "";
            document.getElementById("noDataBarCovid").style.display = "";
            document.getElementById("nODataCircleCovid").style.display = "";
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  showGraphics(isUpdate) {
    let arrayXSymptoms = [];
    let arrayYSymptoms = [];
    let arrayXCovid = [];
    let arrayYCovid = [];
    let sumSymptoms = 0;
    let sumCovid = 0;
    let configStatePieSymptoms;
    let configStatePieCovid;
    let configStateBarSymptoms;
    let configStateBarCovid;
    let symptoms = [
      "Fiebre",
      "Dolor de garganta",
      "Dolor de cabeza",
      "Congestión",
      "Tos",
      "Dificultad para respirar",
      "Fatiga",
      "Escalofrío",
      "Dolor muscular",
      "Ninguno",
    ];
    let specialCases = [
      "Viaje internacional",
      "Viaje nacional",
      "Trabajador de salud",
      "Contacto Covid-19",
    ];
    let counterS = 0;
    let counterC = 0;

    API.get("https://dot", "/symptom/dashboard")
      .then((response) => {
        this.setState({ loading: false });
        if (response.ok) {
          response.json().then((data) => {
            if (data.response.data.sintomas.length !== 0) {
              data.response.data.sintomas.forEach((element) => {
                arrayXSymptoms.push(symptoms[counterS]);
                arrayYSymptoms.push({
                  x: data.response.data.sintomas.indexOf(element),
                  y: element.val,
                });
                counterS++;
              });

              data.response.data.covid.forEach((element) => {
                arrayXCovid.push(specialCases[counterC]);
                arrayYCovid.push({
                  x: data.response.data.covid.indexOf(element),
                  y: element.val,
                });
                counterC++;
              });

              sumSymptoms =
                data.response.data.t_sintomas.Y +
                data.response.data.t_sintomas.N;
              sumCovid =
                data.response.data.t_covid.Y + data.response.data.t_covid.N;

              configStatePieSymptoms = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por síntomas",
                      new Date().toLocaleDateString(),
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No Riesgo"],
                  datasets: [
                    {
                      data: [
                        Math.round(
                          (data.response.data.t_sintomas.Y * 100) / sumSymptoms
                        ),
                        Math.round(
                          (data.response.data.t_sintomas.N * 100) / sumSymptoms
                        ),
                      ],
                      backgroundColor: ["#AB3822", "#84ac34"],
                    },
                  ],
                },
              };
              configStatePieCovid = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por Covid-19",
                      new Date().toLocaleDateString(),
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No Riesgo"],
                  datasets: [
                    {
                      data: [
                        Math.round(
                          (data.response.data.t_covid.Y * 100) / sumCovid
                        ),
                        Math.round(
                          (data.response.data.t_covid.N * 100) / sumCovid
                        ),
                      ],
                      backgroundColor: ["#AB3822", "#84ac34"],
                    },
                  ],
                },
              };
            } else {
              configStatePieSymptoms = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por síntomas",
                      new Date().toLocaleDateString(),
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No Riesgo"],
                  datasets: [
                    {
                      data: [0, 0],
                      backgroundColor: ["#AB3822", "#84ac34"],
                    },
                  ],
                },
              };
              configStatePieCovid = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por Covid-19",
                      new Date().toLocaleDateString(),
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No Riesgo"],
                  datasets: [
                    {
                      data: [0, 0],
                      backgroundColor: ["#AB3822", "#84ac34"],
                    },
                  ],
                },
              };
            }
            configStateBarSymptoms = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text:
                    "Síntomas reportados " + new Date().toLocaleDateString(),
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayXSymptoms,
                datasets: [
                  {
                    label: ["Cantidad"],
                    data: arrayYSymptoms,
                    backgroundColor: "#4466AB",
                    borderColor: "#4466AB",
                    borderWidth: 1,
                  },
                ],
              },
            };
            configStateBarCovid = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Casos adicionales " + new Date().toLocaleDateString(),
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayXCovid,
                datasets: [
                  {
                    label: ["Cantidad"],
                    data: arrayYCovid,
                    backgroundColor: "#4466AB",
                    borderColor: "#4466AB",
                    borderWidth: 1,
                  },
                ],
              },
            };

            if (isUpdate) {
              let tmpBarSymptoms = this.state.barGraphicSymptoms;
              let tmpBarCovid = this.state.barGraphicCovid;
              let tmpPieSymptoms = this.state.pieGraphicSymptoms;
              let tmpPieCovid = this.state.pieGraphicCovid;
              tmpBarSymptoms.data.labels = configStateBarSymptoms.data.labels;
              tmpBarSymptoms.data.datasets =
                configStateBarSymptoms.data.datasets;
              tmpBarSymptoms.update();
              tmpBarCovid.data.labels = configStateBarCovid.data.labels;
              tmpBarCovid.data.datasets = configStateBarCovid.data.datasets;
              tmpBarCovid.update();
              tmpPieSymptoms.data.labels = configStatePieSymptoms.data.labels;
              tmpPieSymptoms.data.datasets =
                configStatePieSymptoms.data.datasets;
              tmpPieSymptoms.update();
              tmpPieCovid.data.labels = configStatePieCovid.data.labels;
              tmpPieCovid.data.datasets = configStatePieCovid.data.datasets;
              tmpPieCovid.update();
              this.setState({
                barGraphicSymptoms: tmpBarSymptoms,
                barGraphicCovid: tmpBarCovid,
                pieGraphicSymptoms: tmpPieSymptoms,
                pieGraphicCovid: tmpPieCovid,
                loading: false,
              });
            } else {
              let barSymptoms = document
                .getElementById("chartBarSymptoms")
                .getContext("2d");
              let chartBarSymptoms = new Chart(
                barSymptoms,
                configStateBarSymptoms
              );

              let barCovid = document
                .getElementById("chartBarCovid")
                .getContext("2d");
              let chartBarCovid = new Chart(barCovid, configStateBarCovid);

              let pieSymptoms = document
                .getElementById("chartCircleSymptoms")
                .getContext("2d");
              let chartCircleSymptoms = new Chart(
                pieSymptoms,
                configStatePieSymptoms
              );

              let pieCovid = document
                .getElementById("chartCircleCovid")
                .getContext("2d");
              let chartCircleCovid = new Chart(pieCovid, configStatePieCovid);

              this.setState({
                barGraphicSymptoms: chartBarSymptoms,
                barGraphicCovid: chartBarCovid,
                pieGraphicSymptoms: chartCircleSymptoms,
                pieGraphicCovid: chartCircleCovid,
                loading: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));
  }

  styleToActive() {
    /* document.getElementById("activeDashSym").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeDashSym").style.borderStyle = "dashed";
    document.getElementById("activeDashSym").style.borderColor = "#84ac34";
    document.getElementById("activeDashSym").style.borderRadius = "1rem";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeCamsBio").style.color = "";
    document.getElementById("activeCamsBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none"; */
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard Síntomas";
    this.styleToActive();
    this.verifyEmails();
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Dashboard-Symptoms">
            <Row>
              <Col lg={12}>
                <div className="Center-Title">
                  <label className="Title-Dashboard">
                    Dashboard Reporte de Síntomas
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={7}>
                    <div className="Container-Layout-Symptoms">
                      <div className="Load">
                        {this.state.loading && <div className="loader"></div>}
                      </div>
                      <canvas id="chartBarSymptoms"></canvas>
                      <div id="noDataBarSymptoms">
                        <div className="Div-Center">
                          <label className="Title-Message">
                            Síntomas reportados
                          </label>
                          <br></br>
                          <br></br>
                          <img
                            src={working}
                            width="100rem"
                            alt="Imagen de Loading"
                          ></img>
                          <br></br>
                          <label className="Text-Message">
                            No tienes correos registrados
                          </label>
                          <br></br>
                          <label className="Text-Message">
                            No tenemos estadísticas para ti
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className="Container-Layout-Symptoms">
                      <div className="Load">
                        {this.state.loading && <div className="loader"></div>}
                      </div>
                      <canvas id="chartCircleSymptoms"></canvas>
                      <div id="noDataCircleSymptoms">
                        <div className="Div-Center">
                          <label className="Title-Message">
                            Porcentaje de personas en estado de riesgo por
                            síntomas
                          </label>
                          <br></br>
                          <br></br>
                          <img
                            src={working}
                            width="100rem"
                            alt="Imagen de Loading"
                          ></img>
                          <br></br>
                          <label className="Text-Message">
                            No tienes correos registrados
                          </label>
                          <br></br>
                          <label className="Text-Message">
                            No tenemos estadísticas para ti
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={7}>
                    <div className="Container-Layout-Symptoms">
                      <div className="Load">
                        {this.state.loading && <div className="loader"></div>}
                      </div>
                      <canvas id="chartBarCovid"></canvas>
                      <div id="noDataBarCovid">
                        <div className="Div-Center">
                          <label className="Title-Message">
                            Casos adicionales
                          </label>
                          <br></br>
                          <br></br>
                          <img
                            src={working}
                            width="100rem"
                            alt="Imagen de Loading"
                          ></img>
                          <br></br>
                          <label className="Text-Message">
                            No tienes correos registrados
                          </label>
                          <br></br>
                          <label className="Text-Message">
                            No tenemos estadísticas para ti
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className="Container-Layout-Symptoms">
                      <div className="Load">
                        {this.state.loading && <div className="loader"></div>}
                      </div>
                      <canvas id="chartCircleCovid"></canvas>
                      <div id="noDataCircleCovid">
                        <div className="Div-Center">
                          <label className="Title-Message">
                            Porcentaje de personas en estado de riesgo por
                            Covid-19
                          </label>
                          <br></br>
                          <br></br>
                          <img
                            src={working}
                            width="100rem"
                            alt="Imagen de Loading"
                          ></img>
                          <br></br>
                          <label className="Text-Message">
                            No tienes correos registrados
                          </label>
                          <br></br>
                          <label className="Text-Message">
                            No tenemos estadísticas para ti
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default DashboardSymptoms;
