//Import main libraries and components
import React, { useState } from "react";
import NavMenuBar from "../../components/NavMenuBar";
//import NavMenuBar from "../../components/NavMenu";
import LateralMenu from "../../components/LateralMenu";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";

//Import images
import iconHand from "../../imgs/manita.png";
import working from "../../imgs/Recurso10.png";
import notFound from "../../imgs/error.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";
import "react-datepicker/dist/react-datepicker.css";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphic: [],
      lastEventsPartOne: [],
      lastEventsPartTwo: [],
      pagination: 1,
      cameras: [],
      lineGraphic: {},
      barGraphic: {},
      barGraphic_2: {},
      pieGraphic: {},
      pieGraphic_2: {},
      loadingLine: false,
      loadingPieDistance: false,
      loadingBarDistance: false,
      loadingPieMask: false,
      loadingBarMask: false,
      loadingEvents: false,
      tempId: "",
      tempName: "",
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 3600000),
    };
  }

  handleClickLoad = () => {
    this.getListCameras(this.state.pagination + 1);
  };

  handleClickCamera = (e) => {
    this.setState({
      loading: true,
      loadingEvents: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    this.updateDashboard(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
  };

  applyFilter = () => {
    clearInterval(this.intervalId);
    this.updateDashboard(
      this.state.tempId,
      this.state.tempName,
      true,
      this.state.startDate.getTime(),
      this.state.finishDate.getTime()
    );
  };

  deleteFilter = () => {
    this.setState({
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 3600000),
    });
    this.updateDashboard(
      this.state.tempId,
      this.state.tempName,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
    this.intervalId = setInterval(
      () =>
        this.updateDashboard(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );
  };

  updateDashboard(id, name, isUpdate, startDate, finishDate) {
    this.hideObjects();
    this.showUpdates();
    this.showGraphics(id, name, isUpdate, startDate, finishDate);
    this.getLastEvents(id, name);
  }

  async getResourceGroup() {
    try {
      const request = await API.get("https://dot", "/resource_group");

      const data = await request.json();
      return data[0].id;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getListCameras(pagination) {
    const cookies = new Cookies();
    let role = cookies.get("role");
    if (role === "owner") {
      const idGroup = await this.getResourceGroup();
      try {
        const request = await API.get(
          "https://dot",
          "/cameraSpecific/1" + "?groupId=" + idGroup + "&model=ML-D1"
        );
        const data = await request.json();

        data.forEach((element) => {
          let item = (
            <Dropdown.Item
              onClick={this.handleClickCamera}
              className="Camera"
              id={element.id}
              key={element.id}
              name={element.name}
            >
              Cámara {element.name}
            </Dropdown.Item>
          );
          this.state.cameras.push(item);
        });
        const cookies = new Cookies();
        if (pagination === cookies.get("pagination")) {
          document.getElementById("buttonLoad").style.display = "none";
        } else {
        }
        this.setState({
          pagination: pagination,
          loading: false,
          tempId: this.state.cameras[0].key,
          tempName: this.state.cameras[0].props.name,
        });
        this.updateDashboard(
          this.state.cameras[0].key,
          this.state.cameras[0].props.name,
          false,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        );
      } catch (error) {
        throw new Error(error);
      }
    } else {
      API.get("https://dot", "/cameras/" + pagination)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              data.forEach((element) => {
                let item = (
                  <Dropdown.Item
                    onClick={this.handleClickCamera}
                    className="Camera"
                    id={element.id}
                    key={element.id}
                    name={element.name}
                  >
                    Cámara {element.name}
                  </Dropdown.Item>
                );
                this.state.cameras.push(item);
              });
              const cookies = new Cookies();
              if (pagination === cookies.get("pagination")) {
                document.getElementById("buttonLoad").style.display = "none";
              } else {
              }
              this.setState({
                pagination: pagination,
                loading: false,
                tempId: this.state.cameras[0].key,
                tempName: this.state.cameras[0].props.name,
              });
              this.updateDashboard(
                this.state.cameras[0].key,
                this.state.cameras[0].props.name,
                false,
                new Date().getTime(),
                new Date().getTime() - 1 * 3600 * 1000
              );
            });
          } else {
            console.log("Error" + response);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  showGraphics(id, name, isUpdate, startDate, finishDate) {
    let arrayXCapacity = [];
    let arrayYCapacity = [];
    let arrayXRisk = [];
    let arrayY1Risk = [];
    let arrayY2Risk = [];
    let arrayXMask = [];
    let arrayNoRiskMask = [];
    let arrayRiskMask = [];
    let sum = 0;
    let sum_2 = 0;
    let configStatePie;
    let configStatePie_2;
    let configStateLine;
    let configStateBar;
    let configStateBar_2;

    this.setState({
      loadingLine: true,
      loadingPieDistance: true,
      loadingBarDistance: true,
      loadingPieMask: true,
      loadingBarMask: true,
    });
    

    API.get(
      "https://dot",
      "/cameras/dashboard/current/" +
        id +
        "?initial=" +
        startDate +
        "&finalize=" +
        finishDate
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.CapacityData !== undefined) {
              document.getElementById("chartPieDistancing").style.display = "";
              document.getElementById("chartBarDistancing").style.display = "";
              document.getElementById("chartBarMask").style.display = "";
              document.getElementById("chartPieMask").style.display = "";
              document.getElementById("chartLineCapacity").style.display = "";
              document.getElementById("loadingPieDistancing").style.display =
                "none";
              document.getElementById("loadingBarDistancing").style.display =
                "none";
              document.getElementById("loadingLineCapacity").style.display =
                "none";
              document.getElementById("loadingBarMask").style.display = "none";
              document.getElementById("loadingPieMask").style.display = "none";
              document.getElementById("loadingEvents").style.display = "none";
              
              data.CapacityData.forEach((element) => {
                arrayXCapacity.push(element.date);
                arrayYCapacity.push({
                  x: data.CapacityData.indexOf(element),
                  y: element.value,
                });
              });

              data.RiskData.forEach((element) => {
                if(element.riskValue !== 0){
                  arrayXRisk.push(element.date);
                  arrayY1Risk.push({
                    x: data.RiskData.indexOf(element),
                    y: element.riskValue,
                  });
                }    
                arrayY2Risk.push({
                  x: data.RiskData.indexOf(element),
                  y: element.noRiskValue,
                });
              });

              data.MaskRisk.forEach((element) => {
                arrayXMask.push(element.date);
                arrayRiskMask.push({
                  x: data.MaskRisk.indexOf(element),
                  y: element.riskValue,
                });
                arrayNoRiskMask.push({
                  x: data.MaskRisk.indexOf(element),
                  y: element.noRiskValue,
                });
              });

              sum = data.PieRiskData.riskMean + data.PieRiskData.noRiskMean;
              sum_2 = data.MaskPie.noRiskMean + data.MaskPie.riskMean;

              configStatePie = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por distanciamiento",
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No riesgo"],
                  datasets: [
                    {
                      data: [
                        Math.round((data.PieRiskData.riskMean * 100) / sum),
                        Math.round((data.PieRiskData.noRiskMean * 100) / sum),
                      ],
                      backgroundColor: ["#AB3822", "#86ac36"],
                      borderColor: ["#AB3822", "#86ac36"],
                      borderWidth: 3,
                    },
                  ],
                },
              };

              configStatePie_2 = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por máscaras",
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No riesgo"],
                  datasets: [
                    {
                      data: [
                        Math.round((data.MaskPie.riskMean * 100) / sum_2),
                        Math.round((data.MaskPie.noRiskMean * 100) / sum_2),
                      ],
                      backgroundColor: ["#AB3822", "#86ac36"],
                      borderColor: ["#AB3822", "#86ac36"],
                      borderWidth: 3,
                    },
                  ],
                },
              };

              configStateLine = {
                type: "line",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de aforo",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: arrayXCapacity,
                  datasets: [
                    {
                      label: ["Personas en " + name],
                      data: arrayYCapacity,
                      backgroundColor: ["rgb(255, 255, 255, 0)"],
                      borderColor: "#A0B96F",
                      pointStyle: "star",
                      pointHoverBorderWidth: 10,
                    },
                  ],
                },
              };

              configStateBar = {
                type: "bar",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de riesgo por distanciamiento",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: arrayXRisk,
                  datasets: [
                    {
                      autoSkip: false,
                      fill: false,
                      label: ["Personas en riesgo"],
                      data: arrayY1Risk,
                      backgroundColor: "#AB3822",
                      borderColor: "#AB3822",
                      borderWidth: 3,
                    },
                    /* {
                      label: ["Personas seguras"],
                      data: arrayY2Risk,
                      backgroundColor: "#84ac3490",
                      borderColor: "rgb(132, 172, 52)",
                      borderWidth: 3,
                    }, */
                  ],
                },
              };

              configStateBar_2 = {
                type: "bar",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de riesgo por máscaras",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: arrayXMask,
                  datasets: [
                    {
                      label: ["Personas en riesgo"],
                      data: arrayRiskMask,
                      backgroundColor: "#AB3822",
                      borderColor: "#AB3822",
                      borderWidth: 3,
                    },
                    {
                      label: ["Personas seguras"],
                      data: arrayNoRiskMask,
                      backgroundColor: "#86ac36",
                      borderColor: "#86ac36",
                      borderWidth: 3,
                    },
                  ],
                },
              };
            } else {
              configStatePie = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por distanciamiento",
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No riesgo"],
                  datasets: [
                    {
                      data: [0, 0],
                      backgroundColor: ["#AB3822", "#86ac36"],
                      borderColor: ["#AB3822", "#86ac36"],
                      borderWidth: 3,
                    },
                  ],
                },
              };

              configStatePie_2 = {
                type: "doughnut",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: [
                      "Porcentaje de personas en ",
                      "estado de riesgo por máscaras",
                    ],
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                },
                data: {
                  labels: ["Riesgo", "No riesgo"],
                  datasets: [
                    {
                      data: [0, 0],
                      backgroundColor: ["#AB3822", "#86ac36"],
                      borderColor: ["#AB3822", "#86ac36"],
                      borderWidth: 3,
                    },
                  ],
                },
              };

              configStateLine = {
                type: "line",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de aforo",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: [""],
                  datasets: [
                    {
                      label: ["Personas en " + name],
                      data: [0],
                      backgroundColor: ["rgb(255, 255, 255, 0)"],
                      borderColor: "#86ac36",
                      pointStyle: "star",
                      pointHoverBorderWidth: 10,
                    },
                  ],
                },
              };

              configStateBar = {
                type: "bar",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de riesgo por distanciamiento",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: [""],
                  datasets: [
                    {
                      label: ["Personas en riesgo"],
                      data: [0],
                      backgroundColor: "#AB3822",
                      borderColor: "#AB3822",
                      borderWidth: 3,
                    },
                    /* {
                      label: ["Personas seguras"],
                      data: [0],
                      backgroundColor: "#84ac3490",
                      borderColor: "rgb(132, 172, 52)",
                      borderWidth: 3,
                    }, */
                  ],
                },
              };

              configStateBar_2 = {
                type: "bar",
                options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  title: {
                    display: true,
                    text: "Histórico de riesgo por máscaras",
                    fontSize: 16,
                    fontFamily: "Gotham",
                    fontColor: "rgb(70, 67, 67)",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                },
                data: {
                  labels: [],
                  datasets: [
                    {
                      label: ["Personas en riesgo"],
                      data: [0],
                      backgroundColor: "#AB3822",
                      borderColor: "#AB3822",
                      borderWidth: 3,
                    },
                    {
                      label: ["Personas seguras"],
                      data: [0],
                      backgroundColor: "#86ac36",
                      borderColor: "#86ac36",
                      borderWidth: 3,
                    },
                  ],
                },
              };

              document.getElementById("chartPieDistancing").style.display =
                "none";
              document.getElementById("chartPieMask").style.display = "none";
              document.getElementById("chartBarMask").style.display = "none";
              document.getElementById("chartBarDistancing").style.display =
                "none";
              document.getElementById("chartLineCapacity").style.display =
                "none";
              document.getElementById("loadingPieDistancing").style.display =
                "";
              document.getElementById("loadingBarDistancing").style.display =
                "";
              document.getElementById("loadingLineCapacity").style.display = "";
              document.getElementById("loadingPieMask").style.display = "";
              document.getElementById("loadingBarMask").style.display = "";
            }

            if (isUpdate) {
              let tmpLine = this.state.lineGraphic;
              let tmpBar = this.state.barGraphic;
              let tmpBar_2 = this.state.barGraphic_2;
              let tmpPie = this.state.pieGraphic;
              let tmpPie_2 = this.state.pieGraphic_2;
              tmpLine.data.labels = configStateLine.data.labels;
              tmpLine.data.datasets = configStateLine.data.datasets;
              tmpLine.update();
              tmpBar.data.labels = configStateBar.data.labels;
              tmpBar.data.datasets = configStateBar.data.datasets;
              tmpBar.update();
              tmpBar_2.data.labels = configStateBar_2.data.labels;
              tmpBar_2.data.datasets = configStateBar_2.data.datasets;
              tmpBar_2.update();
              tmpPie.data.labels = configStatePie.data.labels;
              tmpPie.data.datasets = configStatePie.data.datasets;
              tmpPie.update();
              tmpPie_2.data.labels = configStatePie_2.data.labels;
              tmpPie_2.data.datasets = configStatePie_2.data.datasets;
              tmpPie_2.update();
              this.setState({
                lineGraphic: tmpLine,
                barGraphic: tmpBar,
                barGraphic_2: tmpBar_2,
                pieGraphic: tmpPie,
                pieGraphic_2: tmpPie_2,
                loadingLine: false,
                loadingPieDistance: false,
                loadingBarDistance: false,
                loadingPieMask: false,
                loadingBarMask: false,
              });
            } else {
              let line = document
                .getElementById("chartLineCapacity")
                .getContext("2d");
              let chartLineCapacity = new Chart(line, configStateLine);

              let bar = document
                .getElementById("chartBarDistancing")
                .getContext("2d");
              let chartBarDistancing = new Chart(bar, configStateBar);

              let bar_2 = document
                .getElementById("chartBarMask")
                .getContext("2d");
              let chartBarMask = new Chart(bar_2, configStateBar_2);

              let pie = document
                .getElementById("chartPieDistancing")
                .getContext("2d");
              let chartPieDistancing = new Chart(pie, configStatePie);

              let pie_2 = document
                .getElementById("chartPieMask")
                .getContext("2d");
              let chartPieMask = new Chart(pie_2, configStatePie_2);

              this.setState({
                lineGraphic: chartLineCapacity,
                barGraphic: chartBarDistancing,
                barGraphic_2: chartBarMask,
                pieGraphic: chartPieDistancing,
                pieGraphic_2: chartPieMask,
                loadingLine: false,
                loadingPieDistance: false,
                loadingBarDistance: false,
                loadingPieMask: false,
                loadingBarMask: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));
  }

  async showUpdates() {
    try {
      const res = await API.get("https://dot", "/general");
      let js = await res.json();
      let arry = [];
      if (!Object.keys(js).includes("message")) {
        js.forEach((element) => {
          const url = element.url_tuto;
          arry.push(
            <div className="Card">
              <div className="contain_update" key={js.indexOf(element)}>
                <label className="Title-Update">{element.description} </label>
                <label className="Text-Update">
                  <img src={iconHand} alt="Icono noticia" width="26rem"></img>{" "}
                  <a
                    className="Link-Update"
                    href={"http://www." + url}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    key={js.indexOf(element)}
                  >
                    {element.url_tuto}
                  </a>
                </label>
              </div>
            </div>
          );
        });
      } else {
        arry.push(
          <div className="contain_update-none">
            <strong>{js.message}</strong>
          </div>
        );
      }
      this.setState({
        updates: arry,
      });
    } catch (error) {
      return error;
    }
  }

  getLastEvents(id, name) {
    document.getElementById("loadingEvents").style.display = "none";
    document.getElementById("listEvents").style.display = "none";
    let body = JSON.stringify({
      data: {
        pageSize: new Date().toString(),
      },
    });
    this.setState({ loadingEvents: true });
    API.postForCameras("https://dot", "/cameras/events/" + id, body)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              let tempEventsPartOne = [];
              let tempEventsPartTwo = [];
              let counter = 0;
              data.forEach((element) => {
                if (counter < 2 && counter % 2 === 0) {
                  let event = (
                    <Card className="Event-Card" key={data.indexOf(element)}>
                      <Card.Img
                        variant="top"
                        width="10rem"
                        src={element.file}
                      />
                      <Card.Body className="Card-Body">
                        <Card.Title className="Card-Event-Title">
                          Evento {new Date(element.timestamp * 1000).toString()}
                        </Card.Title>
                        <Card.Text className="Card-Event-Text">
                          Personas en riesgo: {element.event.distance.riesgo}
                        </Card.Text>
                        <Button
                          variant="success"
                          id={counter}
                          onClick={() => {
                            localStorage.setItem("CameraID", id);
                            localStorage.setItem("CameraName", name);
                            this.props.history.push(
                              "/biodot/cameras/events/" + id
                            );
                          }}
                          className="Button-See-Event"
                        >
                          Ver más información
                        </Button>
                      </Card.Body>
                    </Card>
                  );
                  tempEventsPartOne.push(event);
                  counter++;
                } else if (counter < 2 && counter % 2 !== 0) {
                  let event = (
                    <Card className="Event-Card" key={data.indexOf(element)}>
                      <Card.Img
                        variant="top"
                        width="10rem"
                        src={element.file}
                      />
                      <Card.Body className="Card-Body">
                        <Card.Title className="Card-Event-Title">
                          Evento {new Date(element.timestamp * 1000).toString()}
                        </Card.Title>
                        <Card.Text className="Card-Event-Text">
                          Personas en riesgo: {element.event.distance.riesgo}
                        </Card.Text>
                        <Button
                          variant="success"
                          id={counter}
                          onClick={() => {
                            localStorage.setItem("CameraID", id);
                            localStorage.setItem("CameraName", name);
                            this.props.history.push(
                              "/biodot/cameras/events/" + id
                            );
                          }}
                          className="Button-See-Event"
                        >
                          Ver más información
                        </Button>
                      </Card.Body>
                    </Card>
                  );
                  tempEventsPartTwo.push(event);
                  counter++;
                }
              });
              document.getElementById("loadingEvents").style.display = "none";
              document.getElementById("listEvents").style.display = "";
              this.setState({
                lastEventsPartOne: tempEventsPartOne,
                lastEventsPartTwo: tempEventsPartTwo,
                loadingEvents: false,
              });
            } else {
              this.setState({
                lastEventsPartOne: [],
                lastEventsPartTwo: [],
                loadingEvents: false,
              });
              document.getElementById("loadingEvents").style.display = "";
              document.getElementById("listEvents").style.display = "none";
            }
          });
        } else {
          console.log("Error");
        }
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard";
    this.getListCameras(this.state.pagination);
    this.styleToActive();
    this.hideObjects();
    this.intervalId = setInterval(
      () =>
        this.updateDashboard(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  styleToActive() {
    /* document.getElementById("activeDashBio").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeDashBio").style.borderStyle = "dashed";
    document.getElementById("activeDashBio").style.borderColor = "#84ac34";
    document.getElementById("activeDashBio").style.borderRadius = "1rem";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle =
      "none";
    document.getElementById("activeCamsBio").style.color = "";
    document.getElementById("activeCamsBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeAnsSym").style.color = "";
    document.getElementById("activeAnsSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none"; */
  }

  hideObjects() {
    document.getElementById("loadingPieDistancing").style.display = "none";
    document.getElementById("loadingBarDistancing").style.display = "none";
    document.getElementById("loadingLineCapacity").style.display = "none";
    document.getElementById("loadingEvents").style.display = "none";
    document.getElementById("loadingPieMask").style.display = "none";
    document.getElementById("loadingBarMask").style.display = "none";
  }
  
  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
  } 
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <Row>
            <Col lg={12}>
              <Row>
                {/* <Col lg={2} className="pr-0"><LateralMenu></LateralMenu></Col> */}
                <Col lg={12} className="pl-0">
                  <div>
                    <div className="Body-Dashboard" id="Body-Dashboard">
                      <Row>
                        <Col lg={12}>
                          <div className="form-inline">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="Camera-Button"
                              >
                                Cámara {this.state.tempName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.cameras}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div>
                              <div className="Div-Date">
                                <DatePicker
                                  className="Date-Picker"
                                  selected={this.state.finishDate}
                                  onChange={(date) =>
                                    this.setState({ finishDate: date })
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={30}
                                  timeCaption="Hora"
                                  dateFormat="yyyy/MM/dd h:mm aa"
                                />
                                <DatePicker
                                  className="Date-Picker"
                                  selected={this.state.startDate}
                                  onChange={(date) =>
                                    this.setState({ startDate: date })
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={30}
                                  timeCaption="Hora"
                                  dateFormat="yyyy/MM/dd h:mm aa"
                                />
                                <Button
                                  variant="success"
                                  className="Button-Apply-Filter"
                                  onClick={this.applyFilter}
                                >
                                  Aplicar
                                </Button>
                                <Button
                                  variant="dark"
                                  className="Button-Delete-Filter"
                                  onClick={this.deleteFilter}
                                >
                                  Eliminar
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {/* First graphic
                         */}
                        <Col lg={7}>
                          <Row>
                            <Col lg={12}>
                              <div className="Container-Layout">
                                <div className="Load">
                                  {this.state.loadingLine && (
                                    <div className="loader"></div>
                                  )}
                                </div>
                                <canvas id="chartLineCapacity"></canvas>
                                <div id="loadingLineCapacity">
                                  <div className="Div-Center">
                                    <label className="Title-Message">
                                      Histórico de aforo
                                    </label>
                                    <br></br>
                                    <br></br>
                                    <img
                                      src={working}
                                      width="80rem"
                                      alt="Imagen de Loading"
                                    ></img>
                                    <br></br>
                                    <br></br>
                                    <label className="Text-Message">
                                      Estamos procesando tus datos
                                    </label>
                                    <br></br>
                                    <label className="Text-Message">
                                      Pronto tendremos estadísticas para ti
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* Second graphic
                         */}
                        <Col lg={5}>
                          <div className="Container-Layout">
                            <div className="Load">
                              {this.state.loadingPieDistance && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <canvas id="chartPieDistancing"></canvas>
                            <div
                              id="loadingPieDistancing"
                              className="Div-Center"
                            >
                              <label className="Title-Message">
                                Porcentaje de personas en estado de riesgo por
                                distanciamiento
                              </label>
                              <br></br>
                              <br></br>
                              <img
                                src={working}
                                width="80rem"
                                alt="Imagen de Loading"
                              ></img>
                              <br></br>
                              <br></br>
                              <label className="Text-Message">
                                Estamos procesando tus datos
                              </label>
                              <br></br>
                              <label className="Text-Message">
                                Pronto tendremos estadísticas para ti
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* New row
                       */}
                      <Row>
                        {/* First graphic
                         */}
                        <Col lg={7}>
                          <Row>
                            <Col lg={12}>
                              <div className="Container-Layout">
                                <div className="Load">
                                  {this.state.loadingBarDistance && (
                                    <div className="loader"></div>
                                  )}
                                </div>
                                <canvas id="chartBarDistancing"></canvas>
                                <div
                                  id="loadingBarDistancing"
                                  className="Div-Center"
                                >
                                  <label className="Title-Message">
                                    Histórico de riesgo por distanciamiento
                                  </label>
                                  <br></br>
                                  <br></br>
                                  <img
                                    src={working}
                                    width="80rem"
                                    alt="Imagen de Loading"
                                  ></img>
                                  <br></br>
                                  <br></br>
                                  <label className="Text-Message">
                                    Estamos procesando tus datos
                                  </label>
                                  <br></br>
                                  <label className="Text-Message">
                                    Pronto tendremos estadísticas para ti
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* Second graphic
                         */}
                        <Col lg={5}>
                          <div className="Container-Layout">
                            <div className="Load">
                              {this.state.loadingPieDistance && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <canvas id="chartPieMask"></canvas>
                            <div id="loadingPieMask" className="Div-Center">
                              <label className="Title-Message">
                                Porcentaje de personas en estado de riesgo por
                                máscaras
                              </label>
                              <br></br>
                              <br></br>
                              <img
                                src={working}
                                width="80rem"
                                alt="Imagen de Loading"
                              ></img>
                              <br></br>
                              <br></br>
                              <label className="Text-Message">
                                Estamos procesando tus datos
                              </label>
                              <br></br>
                              <label className="Text-Message">
                                Pronto tendremos estadísticas para ti
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* New row
                       */}
                      <Row>
                        {/* First graphic
                         */}
                        <Col lg={7}>
                          <div className="Container-Layout">
                            <div className="Load">
                              {this.state.loadingBarMask && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <canvas id="chartBarMask"></canvas>
                            <div id="loadingBarMask" className="Div-Center">
                              <label className="Title-Message">
                                Histórico de riesgo por máscaras
                              </label>
                              <br></br>
                              <br></br>
                              <img
                                src={working}
                                width="80rem"
                                alt="Imagen de Loading"
                              ></img>
                              <br></br>
                              <br></br>
                              <label className="Text-Message">
                                Estamos procesando tus datos
                              </label>
                              <br></br>
                              <label className="Text-Message">
                                Pronto tendremos estadísticas para ti
                              </label>
                            </div>
                          </div>
                        </Col>
                        {/* Second graphic
                         */}
                        <Col lg={5}>
                          <div className="Container-Layout-Events">
                            <div className="Div-Center-Chart">
                              <label className="Title-Chart">
                                Últimos eventos
                              </label>
                            </div>
                            <div className="Load">
                              {this.state.loadingEvents && (
                                <div className="loader"></div>
                              )}
                            </div>
                            <div id="loadingEvents" className="Div-Center">
                              <br></br>
                              <img
                                src={notFound}
                                width="100rem"
                                alt="Imagen de Loading"
                              ></img>
                              <br></br>
                              <label className="Text-Message">
                                Por ahora no tienes eventos
                              </label>
                            </div>
                            <div id="listEvents">
                              <Row>
                                <Col lg={6}>
                                  <div>{this.state.lastEventsPartOne}</div>
                                </Col>
                                <Col lg={6}>
                                  <div>{this.state.lastEventsPartTwo}</div>
                                </Col>
                              </Row>
                              
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Dashboard;
