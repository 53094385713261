//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import { Row, Col, Button, Dropdown, Modal, ModalBody } from "react-bootstrap";
//import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";

//Import images
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";

// Special graphs
import "chartjs-plugin-datalabels";

class DashboardHistorical extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dayFilter: "7 días",
            dayFilterStart: "",
            dayFilterEnd: "",
            cameras: [],
            graphic: [],
            groups: [],
            lineGraphic: {},
            barGraphic: {},
            pieGraphic: {},
            barGraphic_2: {},
            loadingStaticInLine: false,
            loadingStaticInBar: false,
            loadingStaticInPie: false,
            showModalCustomFilter: false,
            showModalCustomFilterDay: false,
            tempId: "",
            tempName: "",
            moreDay: "",
            lessDay: "",
            moreDayNumber: "",
            lessDayNumber: "",
            startDateStatic: new Date(),
            finishDateStatic: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            capacity: "",
            tempLogo: "",
            peaksPerDay: {},
        };
    }

    handleClickGroup = e => {
        localStorage.setItem("idGroup", e.target.id);
        localStorage.setItem("nameGroup", e.target.name);
        this.getListCameras();
        this.showGraphicsStatic(
            e.target.id,
            e.target.name,
            true,
            new Date().getTime(),
            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        this.setState({ capacity: "0" });
    };

    applyFilterStatic = () => {
        clearInterval(this.intervalId);

        let milisegundosDia = 24 * 60 * 60 * 1000;
        let milisegundosHora = 60 * 60 * 1000;
        let milisegundostranscurridos = Math.abs(this.state.startDateStatic.getTime() - this.state.finishDateStatic.getTime());
        let diastranscuridos = Math.round(milisegundostranscurridos / milisegundosDia);
        let diastranscuridosstr = "";

        if (this.state.showModalCustomFilterDay && this.state.showModalCustomFilter) {
            diastranscuridos = Math.round(milisegundostranscurridos / milisegundosDia);
            this.showGraphicsStatic("", "", true, this.state.startDateStatic.getTime(), this.state.finishDateStatic.getTime());
            diastranscuridosstr = diastranscuridos.toString() + " días";
        } else {
            diastranscuridos = Math.round(milisegundostranscurridos / milisegundosHora);
            this.showGraphicsCustom("", "", true, this.state.startDateStatic.getTime(), this.state.finishDateStatic.getTime());
            diastranscuridosstr = diastranscuridos.toString() + " horas";
        }

        this.setState({ showModalCustomFilter: false, dayFilter: diastranscuridosstr, showModalCustomFilterDay: false });
    };

    getListCameras() {
        this.setState({ cameras: [], tempId: "", tempName: "" });
        API.get("https://dot", "/cameras/1" + "?groupId=" + localStorage.getItem("idGroup"))
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length != 0) {
                            data.forEach(element => {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickCamera}
                                        className="Camera"
                                        id={element.id}
                                        key={element.id}
                                        name={element.name}
                                    >
                                        Cámara {element.name}
                                    </Dropdown.Item>
                                );
                                this.state.cameras.push(item);
                            });
                            //document.getElementById("buttonLoad").style.display = "none";
                            this.setState({
                                loading: false,
                                tempId: this.state.cameras[0].key,
                                tempName: this.state.cameras[0].props.name,
                            });
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getListGroups() {
        API.get("https://dot", "/resource_group_specific/?model=ML-D11&modelNo=ML-D7")
            .then(response => {
                var bBandera = true;
                if (response.ok) {
                    response.json().then(data => {
                        data.forEach(element => {
                            let item = (
                                <Dropdown.Item
                                    onClick={this.handleClickGroup}
                                    className="Camera"
                                    id={element.id}
                                    key={element.id}
                                    name={element.name}
                                >
                                    Grupo {element.name}
                                </Dropdown.Item>
                            );

                            if (bBandera) {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickGroup}
                                        className="Camera"
                                        id={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        key={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        name={"Todos los accesos"}
                                    >
                                        {"Todos los accesos"}
                                    </Dropdown.Item>
                                );

                                this.state.groups.push(item);
                                bBandera = false;
                            }
                            this.state.groups.push(item);
                        });

                        this.setState({
                            loading: false,
                            tempLogo: "https://storage.googleapis.com/logos-switchai/" + this.state.groups[0].key + "/logo.png",
                        });
                        localStorage.setItem("idGroup", this.state.groups[0].key);
                        localStorage.setItem("nameGroup", this.state.groups[0].props.name);

                        this.getListCameras();
                        this.getAforo();
                        this.showGraphicsStatic(
                            localStorage.getItem("idGroup"),
                            localStorage.getItem("nameGroup"),
                            false,
                            new Date().getTime(),
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        );
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    async updateAforo() {
        try {
            const request = await API.get("https://dot", "/analytics/capacity");
            const data = await request.json();
            this.setState({ capacity: data.response });
        } catch (error) {
            throw new Error(error);
        }
    }

    getAforo() {
        this.setState({
            loadingAforo: true,
        });

        API.get("https://dot", "/analytics/capacity")
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        this.setState({ capacity: data.response });
                    });
                    this.setState({
                        loadingAforo: false,
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    showGraphicsStatic(id, name, isUpdate, startDate, finishDate) {
        this.getAforo();
        let arrayXDateChartLine = [];
        let arrayYOutChartLine = [];
        let arrayYInChartLine = [];
        let arrayX2DateChartBar = [];
        let arrayY2InChartBar = [];
        let arrayY2OutChartBar = [];
        let arrayDateLine = [];
        let arrayCompleteDateLine = [];
        let dataTrafficIn = [];
        let dataTrafficOut = [];
        let maximo = 0;
        let menor = 0;
        let positionMaximo;
        let positionMenor;
        let fechaMaximo;
        let fechaMenor;
        let label_pie = [];

        let configStateLine;
        let configStateBar;
        let configStatePie;
        let startDateTrafic = new Date(startDate);
        let finishDateTrafic = new Date(finishDate);

        let startDateFormat = `${startDateTrafic.getFullYear()}-${startDateTrafic.getMonth() + 1}-${startDateTrafic.getDate()}`;
        let finishDateFormat = `${finishDateTrafic.getFullYear()}-${
            finishDateTrafic.getMonth() + 1
        }-${finishDateTrafic.getDate()}`;

        this.setState({
            loadingStaticInLine: true,
            loadingStaticInBar: true,
        });

        if (localStorage.getItem("idGroup") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
            var nCountInterno = 0;
            var camerasTotal = {};
            var dict_out = {};
            var dict_in = {};
            var pie_special = {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
            };
            this.setState.loadingStaticInPie = true;
            this.state["groups"].forEach(element => {
                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        element["key"] +
                        "&start=" +
                        finishDateFormat +
                        "&end=" +
                        startDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            let name_camera = element["key"];

                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartLineIn").style.display = "";
                                    document.getElementById("loadingLineIn").style.display = "none";
                                    document.getElementById("chartBarIn").style.display = "";
                                    document.getElementById("chartPie").style.display = "";
                                    document.getElementById("loadingBarChartIn").style.display = "none";
                                    document.getElementById("loadingPieChart").style.display = "none";

                                    data.forEach(element => {
                                        if (element.come_in !== undefined) {
                                            var date_format = new Date(element.fecha);
                                            let formatted_date =
                                                date_format.getFullYear() +
                                                "-" +
                                                (date_format.getMonth() + 1) +
                                                "-" +
                                                date_format.getDate();

                                            // HARDCODE
                                            /*
                      Nivel 1 - Puerta 3 = a9211a1f-1b58-415a-9354-edf514315d52
                      Nivel 2 - Puerta 1 = 977c040f-25ae-40b7-9374-4f53570261ce + ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651
                      Nivel 2 - Puerta 2 = 8b5890e6-7760-4ea4-ab13-ffdedf212d0d + f702432e-e713-437e-ad31-87daf772163e
                      Nivel 3 - Puerta 4 = b7f160d1-cc0c-4f42-81e8-9f4999e1d13e + 42ab832f-289a-418b-9986-a4d22a607243

                      */

                                            // La colina 138
                                            if (
                                                name_camera == "e90124bc-cd5e-4570-8313-6b6392dedc96" ||
                                                name_camera == "edf41d80-95ad-4e1f-91ac-85392f7613f4" ||
                                                name_camera == "2aead531-8f77-47af-87c3-015d0f6ba24f" ||
                                                name_camera == "82a24bf8-7856-4421-89c9-a32d0e8bec6b"
                                            ) {
                                                label_pie = [
                                                    "Entrada al parque",
                                                    "Entrada Calle 138",
                                                    "Entrada Carulla",
                                                    "Entrada Colpatria",
                                                ];
                                                if (name_camera == "e90124bc-cd5e-4570-8313-6b6392dedc96") {
                                                    pie_special[0] = pie_special[0] + element.come_out;
                                                }

                                                if (name_camera == "edf41d80-95ad-4e1f-91ac-85392f7613f4") {
                                                    pie_special[1] = pie_special[1] + element.come_out;
                                                }

                                                if (name_camera == "2aead531-8f77-47af-87c3-015d0f6ba24f") {
                                                    pie_special[2] = pie_special[2] + element.come_out;
                                                }

                                                if (name_camera == "82a24bf8-7856-4421-89c9-a32d0e8bec6b") {
                                                    pie_special[3] = pie_special[3] + element.come_out;
                                                }
                                            }

                                            if (
                                                name_camera == "a9211a1f-1b58-415a-9354-edf514315d52" ||
                                                name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                                name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651" ||
                                                name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                                name_camera == "f702432e-e713-437e-ad31-87daf772163e" ||
                                                name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                                name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                            ) {
                                                label_pie = [
                                                    "Nivel 1 - Puerta 3",
                                                    "Nivel 2 - Puerta 1",
                                                    "Nivel 2 - Puerta 2",
                                                    "Nivel 3 - Puerta 4",
                                                ];

                                                if (name_camera == "a9211a1f-1b58-415a-9354-edf514315d52") {
                                                    pie_special[0] = pie_special[0] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                                    name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651"
                                                ) {
                                                    pie_special[1] = pie_special[1] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                                    name_camera == "f702432e-e713-437e-ad31-87daf772163e"
                                                ) {
                                                    pie_special[2] = pie_special[2] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                                    name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                                ) {
                                                    pie_special[3] = pie_special[3] + element.come_out;
                                                }
                                            }

                                            // FINISH HARDCODE

                                            if (formatted_date in camerasTotal) {
                                                dict_out[formatted_date] = element.come_out + camerasTotal[formatted_date][0];
                                                dict_in[formatted_date] = element.come_in + camerasTotal[formatted_date][1];
                                                camerasTotal[formatted_date][0] = dict_out[formatted_date];
                                                camerasTotal[formatted_date][1] = dict_in[formatted_date];
                                            } else {
                                                dict_out[formatted_date] = element.come_out;
                                                dict_in[formatted_date] = element.come_in;
                                                var dict_in_out = { 0: element.come_out, 1: element.come_in };
                                                camerasTotal[formatted_date] = dict_in_out;
                                            }
                                        }
                                    });
                                    nCountInterno += 1;
                                    if (nCountInterno == this.state["groups"].length - 1) {
                                        var nCount = 0;
                                        let suma1 = 0;
                                        let suma2 = 0;
                                        for (var key in camerasTotal) {
                                            arrayXDateChartLine.push(key);
                                            arrayX2DateChartBar.push(key);
                                            arrayYOutChartLine.push(camerasTotal[key][0]);
                                            arrayYInChartLine.push(camerasTotal[key][1]);
                                            suma1 += camerasTotal[key][1];
                                            suma2 += camerasTotal[key][0];

                                            arrayY2InChartBar.push(suma1);
                                            arrayY2OutChartBar.push(suma2);
                                            nCount += 1;
                                        }

                                        var maximo = 0;
                                        var menor = 5000000000;
                                        for (let i = 0; i < arrayYInChartLine.length; i++) {
                                            var fechaEjeX = new Date(arrayXDateChartLine[i]);
                                            fechaEjeX.setSeconds(20000);
                                            arrayDateLine.push(fechaEjeX);
                                            arrayCompleteDateLine.push(
                                                `${arrayDateLine[i].getFullYear()}-${
                                                    arrayDateLine[i].getMonth() + 1
                                                }-${arrayDateLine[i].getDate()}`
                                            );
                                            /* Día más visitado */
                                            if (arrayYInChartLine[i] > maximo) {
                                                maximo = arrayYInChartLine[i];
                                                positionMaximo = arrayXDateChartLine[i];
                                            }

                                            /* día menos visitado */
                                            if (arrayYInChartLine[i] <= arrayYInChartLine[0]) {
                                                menor = arrayYInChartLine[i];
                                                positionMenor = arrayXDateChartLine[i];
                                            }

                                            fechaMaximo = new Date(String(positionMaximo));
                                            fechaMenor = new Date(String(positionMenor));
                                        }

                                        /* Data para gráfica de historicos */
                                        let valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1];
                                        let valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1];

                                        dataTrafficIn = [valorTotalPersonasIn];
                                        dataTrafficOut = [valorTotalPersonasOut];

                                        /* Formato de los insight */
                                        let DateCompleteMaximo = `${fechaMaximo.getFullYear()}-${
                                            fechaMaximo.getMonth() + 1
                                        }-${fechaMaximo.getDate()}`;
                                        let DateCompleteMenor = `${fechaMenor.getFullYear()}-${
                                            fechaMenor.getMonth() + 1
                                        }-${fechaMenor.getDate()}`;

                                        this.setState({
                                            moreDay: DateCompleteMaximo,
                                            lessDay: DateCompleteMenor,
                                            moreDayNumber: maximo,
                                            lessDayNumber: menor,
                                        });
                                        configStateLine = {
                                            type: "line",
                                            options: {
                                                plugins: {
                                                    datalabels: {
                                                        display: "auto",
                                                        color: "green",
                                                        align: "start",
                                                        anchor: "start",
                                                        offset: 12,
                                                        font: { size: "14" },
                                                        overlap: "auto",
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: `¿Cuál fue el tráfico desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}?`,
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 10,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: arrayCompleteDateLine,
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: arrayYOutChartLine,
                                                        backgroundColor: "transparent",
                                                        borderColor: "#86ac36",
                                                        pointStyle: "star",
                                                        pointHoverBorderWidth: 10,
                                                    },
                                                ],
                                            },
                                        };
                                        const highestValue = Math.max(...arrayYOutChartLine);
                                        const lowestValue = Math.min(...arrayYOutChartLine);
                                        const majorDate =
                                            arrayCompleteDateLine[
                                                arrayYOutChartLine.findIndex(value => value === highestValue) ?? 0
                                            ];
                                        const minorDate =
                                            arrayCompleteDateLine[
                                                arrayYOutChartLine.findIndex(value => value === lowestValue) ?? 0
                                            ];

                                        this.setState({ peaksPerDay: { lowestValue, highestValue, majorDate, minorDate } });

                                        configStateBar = {
                                            type: "bar",
                                            options: {
                                                plugins: {
                                                    datalabels: {
                                                        display: "auto",
                                                        color: "white",
                                                        align: "end",
                                                        anchor: "center",
                                                        offset: 0,
                                                        font: { size: "18" },
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: [
                                                        "Total de Personas",
                                                        `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                                    ],
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 100,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: [
                                                    this.state.finishDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.finishDateStatic.toLocaleDateString() +
                                                        " - " +
                                                        this.state.startDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.startDateStatic.toLocaleDateString(),
                                                ],
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: dataTrafficOut,
                                                        backgroundColor: "#86ac36",
                                                        borderColor: "#86ac36",
                                                        borderWidth: 3,
                                                    },
                                                ],
                                            },
                                        };
                                        var listPie = Object.values(pie_special);

                                        configStatePie = {
                                            type: "pie",
                                            options: {
                                                plugins: {
                                                    datalabels: {
                                                        display: true,
                                                        color: "white",
                                                        align: "center",
                                                        anchor: "center",
                                                        offset: 0,
                                                        font: { size: "18" },
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: [
                                                        "Distribución",
                                                        `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                                    ],
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 100,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: label_pie,
                                                datasets: [
                                                    {
                                                        backgroundColor: ["#86ac36", "#383838", "#4c5c24", "#606060"],
                                                        data: listPie,
                                                    },
                                                ],
                                            },
                                        };

                                        if (isUpdate) {
                                            let tmpLine = this.state.lineGraphic;

                                            tmpLine.data.labels = configStateLine.data.labels;
                                            tmpLine.options.title.text = configStateLine.options.title.text;
                                            tmpLine.data.datasets = configStateLine.data.datasets;
                                            tmpLine.update();
                                            let tmpBar = this.state.barGraphic;
                                            tmpBar.data.labels = configStateBar.data.labels;
                                            tmpBar.data.datasets = configStateBar.data.datasets;
                                            tmpBar.options.title.text = configStateBar.options.title.text;

                                            tmpBar.update();

                                            let tmpPie = this.state.pieGraphic;
                                            tmpPie.data.labels = configStatePie.data.labels;
                                            tmpPie.data.datasets = configStatePie.data.datasets;
                                            tmpPie.options.title.text = configStatePie.options.title.text;

                                            tmpPie.update();
                                            this.setState({
                                                lineGraphic: tmpLine,
                                                loadingStaticInLine: false,
                                                barGraphic: tmpBar,
                                                loadingStaticInBar: false,
                                                pieGraphic: tmpPie,
                                                loadingStaticInPie: false,
                                            });
                                        } else {
                                            let line = document.getElementById("chartLineIn").getContext("2d");
                                            let bar = document.getElementById("chartBarIn").getContext("2d");

                                            let pie = document.getElementById("chartPie").getContext("2d");

                                            let chartLineIn = new Chart(line, configStateLine);
                                            let chartBarIn = new Chart(bar, configStateBar);
                                            let chartPie = new Chart(pie, configStatePie);

                                            this.setState({
                                                lineGraphic: chartLineIn,
                                                loadingStaticInLine: false,
                                                barGraphic: chartBarIn,
                                                loadingStaticInBar: false,
                                                pieGraphic: chartPie,
                                                loadingStaticInPie: false,
                                            });
                                        }
                                    }
                                } else {
                                    document.getElementById("chartLineIn").style.display = "none";
                                    document.getElementById("loadingLineIn").style.display = "";
                                    document.getElementById("chartBarIn").style.display = "none";
                                    document.getElementById("chartPie").style.display = "none";

                                    document.getElementById("loadingBarChartIn").style.display = "";
                                    document.getElementById("loadingPieChart").style.display = "";
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
            });
        } else {
            var pie_special = {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
            };
            API.get(
                "https://dot",
                "/analytics/resume-traffic/" +
                    "?groupId=" +
                    localStorage.getItem("idGroup") +
                    "&start=" +
                    finishDateFormat +
                    "&end=" +
                    startDateFormat
            )

                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            if (data.length !== 0) {
                                document.getElementById("chartLineIn").style.display = "";
                                document.getElementById("loadingLineIn").style.display = "none";
                                document.getElementById("chartBarIn").style.display = "";
                                document.getElementById("loadingBarChartIn").style.display = "none";

                                let name_camera = localStorage.getItem("idGroup");
                                let suma1 = 0;
                                let suma2 = 0;
                                let come_out_fix;
                                let come_in_fix;

                                data.forEach(element => {
                                    
                                    if (element.come_in !== undefined) {
                                        arrayXDateChartLine.push(element.fecha);

                                        let bBandera = true;

                                        if (localStorage.getItem("idGroup") == "8c452fe5-e963-4e7c-ae42-ec3182b8cfb8") {
                                            come_out_fix = 0
                                            come_in_fix = 0
                                            bBandera=false;
                                        }
    
                                        if (localStorage.getItem("idGroup") == "624b1ed3-6ed4-403b-8329-9232c4512f1b") {
                                            //come_out_fix = element.come_out - 1300
                                            come_out_fix = element.come_out
                                            come_in_fix = element.come_in
                                            bBandera=false;
                                        }
    
                                        if (localStorage.getItem("idGroup") == "f702432e-e713-437e-ad31-87daf772163e") {
                                            come_out_fix = element.come_out 
                                            come_in_fix = element.come_in
                                            bBandera=false;
                                        }
    
                                        if (localStorage.getItem("idGroup") == "42ab832f-289a-418b-9986-a4d22a607243"  || localStorage.getItem("idGroup") == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651" || localStorage.getItem("idGroup") == "977c040f-25ae-40b7-9374-4f53570261ce" || localStorage.getItem("idGroup") == "a9211a1f-1b58-415a-9354-edf514315d52" || localStorage.getItem("idGroup") == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ) {
                                            //come_out_fix = element.come_out + 6000
                                            come_out_fix = element.come_out
                                            come_in_fix = element.come_in
                                            bBandera=false;
                                        }

                                        if  (bBandera){
                                            //come_out_fix = Math.abs(element.come_out - 1200)
                                            come_out_fix = element.come_out
                                            come_in_fix = element.come_in
                                        }

                                        arrayYOutChartLine.push(come_out_fix);
                                        arrayYInChartLine.push(come_in_fix);

                                        if (
                                            name_camera == "a9211a1f-1b58-415a-9354-edf514315d52" ||
                                            name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                            name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651" ||
                                            name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                            name_camera == "f702432e-e713-437e-ad31-87daf772163e" ||
                                            name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                            name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                        ) {
                                            if (name_camera == "a9211a1f-1b58-415a-9354-edf514315d52") {
                                                pie_special[0] = pie_special[0] + come_out_fix;
                                            }

                                            if (
                                                name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                                name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651"
                                            ) {
                                                pie_special[1] = pie_special[1] + come_out_fix;
                                            }

                                            if (
                                                name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                                name_camera == "f702432e-e713-437e-ad31-87daf772163e"
                                            ) {
                                                pie_special[2] = pie_special[2] + come_out_fix;
                                            }

                                            if (
                                                name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                                name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                            ) {
                                                pie_special[3] = pie_special[3] + come_out_fix;
                                            }
                                        }
                                    }
                                });

                                data.forEach(element => {
                                    if (element.come_in !== undefined) {
                                        arrayX2DateChartBar.push(element.fecha);
                                        suma1 += come_in_fix;
                                        suma2 += come_out_fix;

                                        arrayY2InChartBar.push(suma1);
                                        arrayY2OutChartBar.push(suma2);
                                    }
                                });

                                for (let i = 0; i < arrayYInChartLine.length; i++) {
                                    /* Eje X grafica de tráfico */

                                    var fechaEjeX = new Date(arrayXDateChartLine[i]);
                                    fechaEjeX.setSeconds(20000);
                                    arrayDateLine.push(fechaEjeX);
                                    arrayCompleteDateLine.push(
                                        `${arrayDateLine[i].getFullYear()}-${arrayDateLine[i].getMonth() + 1}-${arrayDateLine[
                                            i
                                        ].getDate()}`
                                    );
                                    /* Día más visitado */
                                    if (arrayYInChartLine[i] > maximo) {
                                        maximo = arrayYInChartLine[i];
                                        positionMaximo = arrayXDateChartLine[i];
                                    }

                                    /* día menos visitado */
                                    if (arrayYInChartLine[i] <= arrayYInChartLine[0]) {
                                        menor = arrayYInChartLine[i];
                                        positionMenor = arrayXDateChartLine[i];
                                    }
                                    fechaMaximo = new Date(positionMaximo);
                                    fechaMenor = new Date(positionMenor);
                                }

                                /* Data para gráfica de historicos */
                                let valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1];
                                let valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1];

                                dataTrafficIn = [valorTotalPersonasIn];
                                dataTrafficOut = [valorTotalPersonasOut];

                                /* Formato de los insight */
                                let DateCompleteMaximo = `${fechaMaximo.getFullYear()}-${
                                    fechaMaximo.getMonth() + 1
                                }-${fechaMaximo.getDate()}`;
                                let DateCompleteMenor = `${fechaMenor.getFullYear()}-${
                                    fechaMenor.getMonth() + 1
                                }-${fechaMenor.getDate()}`;

                                this.setState({
                                    moreDay: DateCompleteMaximo,
                                    lessDay: DateCompleteMenor,
                                    moreDayNumber: maximo,
                                    lessDayNumber: menor,
                                });
                            } else {
                                document.getElementById("chartLineIn").style.display = "none";
                                document.getElementById("loadingLineIn").style.display = "";
                                document.getElementById("chartBarIn").style.display = "none";

                                document.getElementById("loadingBarChartIn").style.display = "";
                            }

                            configStateLine = {
                                type: "line",
                                options: {
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: "green",
                                            align: "end",
                                            anchor: "start",
                                            offset: 6,
                                            font: { size: "14" },
                                        },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: `¿Cuál fue el tráfico desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}?`,
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 10,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: arrayCompleteDateLine,
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: arrayYOutChartLine,
                                            backgroundColor: "transparent",
                                            borderColor: "#86ac36",
                                            pointStyle: "star",
                                            pointHoverBorderWidth: 10,
                                        },
                                    ],
                                },
                            };

                            configStateBar = {
                                type: "bar",
                                options: {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: [
                                            "Total de Personas",
                                            `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                        ],
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 100,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: [
                                        this.state.finishDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.finishDateStatic.toLocaleDateString() +
                                            " - " +
                                            this.state.startDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.startDateStatic.toLocaleDateString(),
                                    ],
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: dataTrafficOut,
                                            backgroundColor: "#86ac36",
                                            borderColor: "#86ac36",
                                            borderWidth: 3,
                                        },
                                    ],
                                },
                            };

                            var listPie = Object.values(pie_special);

                            configStatePie = {
                                type: "pie",
                                options: {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: [
                                            "Distribución",
                                            `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                        ],
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 100,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: [
                                        "Nivel 1 - Puerta 3",
                                        "Nivel 2 - Puerta 1",
                                        "Nivel 2 - Puerta 2",
                                        "Nivel 3 - Puerta 4",
                                    ],
                                    datasets: [
                                        {
                                            backgroundColor: ["#86ac36", "#383838", "#4c5c24", "#606060"],
                                            data: listPie,
                                        },
                                    ],
                                },
                            };

                            if (isUpdate) {
                                let tmpLine = this.state.lineGraphic;

                                tmpLine.data.labels = configStateLine.data.labels;
                                tmpLine.data.datasets = configStateLine.data.datasets;
                                tmpLine.options.title.text = configStateLine.options.title.text;
                                tmpLine.update();

                                let tmpBar = this.state.barGraphic;
                                tmpBar.data.labels = configStateBar.data.labels;
                                tmpBar.data.datasets = configStateBar.data.datasets;
                                tmpBar.options.title.text = configStateBar.options.title.text;
                                tmpBar.update();

                                let tmpPie = this.state.pieGraphic;
                                tmpPie.data.labels = configStatePie.data.labels;
                                tmpPie.data.datasets = configStatePie.data.datasets;
                                tmpPie.options.title.text = configStatePie.options.title.text;

                                tmpPie.update();

                                this.setState({
                                    lineGraphic: tmpLine,
                                    loadingStaticInLine: false,
                                    barGraphic: tmpBar,
                                    loadingStaticInBar: false,
                                    pieGraphic: tmpPie,
                                    loadingStaticInPie: false,
                                });
                            } else {
                                let line = document.getElementById("chartLineIn").getContext("2d");
                                let bar = document.getElementById("chartBarIn").getContext("2d");
                                let pie = document.getElementById("chartPie").getContext("2d");

                                let chartLineIn = new Chart(line, configStateLine);
                                let chartBarIn = new Chart(bar, configStateBar);
                                let chartPie = new Chart(pie, configStatePie);
                                this.setState({
                                    lineGraphic: chartLineIn,
                                    loadingStaticInLine: false,
                                    barGraphic: chartBarIn,
                                    loadingStaticInBar: false,
                                    pieGraphic: chartPie,
                                    loadingStaticInPie: false,
                                });
                            }
                        });
                    } else {
                        console.log("Error" + response);
                    }
                })
                .catch(error => console.log("error ", error));
        }
    }

    showGraphicsCustom(id, name, isUpdate, startDate, finishDate) {
        this.getAforo();

        let arrayXDateChartLine = [];
        let arrayYOutChartLine = [];
        let arrayYInChartLine = [];
        let arrayX2DateChartBar = [];
        let arrayY2InChartBar = [];
        let arrayY2OutChartBar = [];
        let arrayDateLine = [];
        let arrayCompleteDateLine = [];
        let dataTrafficIn = [];
        let dataTrafficOut = [];
        let maximo = 0;
        let menor = 0;
        let positionMaximo;
        let positionMenor;
        let fechaMaximo;
        let fechaMenor;

        let configStateLine;
        let configStateBar;
        let configStatePie;
        let startDateTrafic = new Date(startDate);
        let finishDateTrafic = new Date(finishDate);
        let label_pie = [];

        let startDateFormat = `${startDateTrafic.getFullYear()}-${startDateTrafic.getMonth() + 1}-${startDateTrafic.getDate()}`;
        let finishDateFormat = `${finishDateTrafic.getFullYear()}-${
            finishDateTrafic.getMonth() + 1
        }-${finishDateTrafic.getDate()}`;

        this.setState({
            loadingStaticInLine: true,
            loadingStaticInBar: true,
        });

        if (localStorage.getItem("idGroup") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
            var nCountInterno = 0;
            var camerasTotal = {};
            var dict_out = {};
            var dict_in = {};
            var pie_special = {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
            };
            this.setState.loadingStaticInPie = true;
            this.state["groups"].forEach(element => {
                API.get(
                    "https://dot",
                    "/analytics/ingress" + "?start=" + finishDate + "&end=" + startDate + "&groupId=" + element["key"]
                )
                    .then(response => {
                        if (response.ok) {
                            let name_camera = element["key"];

                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartLineIn").style.display = "";
                                    document.getElementById("loadingLineIn").style.display = "none";
                                    document.getElementById("chartBarIn").style.display = "";
                                    document.getElementById("chartPie").style.display = "";

                                    document.getElementById("loadingBarChartIn").style.display = "none";
                                    document.getElementById("loadingPieChart").style.display = "none";

                                    data.forEach(element => {
                                        if (element.come_in !== undefined) {
                                            // HARDCODE | We should improve this method
                                            /*
                      Nivel 1 - Puerta 3 = a9211a1f-1b58-415a-9354-edf514315d52
                      Nivel 2 - Puerta 1 = 977c040f-25ae-40b7-9374-4f53570261ce + ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651
                      Nivel 2 - Puerta 2 = 8b5890e6-7760-4ea4-ab13-ffdedf212d0d + f702432e-e713-437e-ad31-87daf772163e
                      Nivel 3 - Puerta 4 = b7f160d1-cc0c-4f42-81e8-9f4999e1d13e + 42ab832f-289a-418b-9986-a4d22a607243

                      */

                                            // La colina 138
                                            if (
                                                name_camera == "e90124bc-cd5e-4570-8313-6b6392dedc96" ||
                                                name_camera == "edf41d80-95ad-4e1f-91ac-85392f7613f4" ||
                                                name_camera == "2aead531-8f77-47af-87c3-015d0f6ba24f" ||
                                                name_camera == "82a24bf8-7856-4421-89c9-a32d0e8bec6b"
                                            ) {
                                                label_pie = [
                                                    "Entrada al parque",
                                                    "Entrada Calle 138",
                                                    "Entrada Carulla",
                                                    "Entrada Colpatria",
                                                ];
                                                if (name_camera == "e90124bc-cd5e-4570-8313-6b6392dedc96") {
                                                    pie_special[0] = pie_special[0] + element.come_out;
                                                }

                                                if (name_camera == "edf41d80-95ad-4e1f-91ac-85392f7613f4") {
                                                    pie_special[1] = pie_special[1] + element.come_out;
                                                }

                                                if (name_camera == "2aead531-8f77-47af-87c3-015d0f6ba24f") {
                                                    pie_special[2] = pie_special[2] + element.come_out;
                                                }

                                                if (name_camera == "82a24bf8-7856-4421-89c9-a32d0e8bec6b") {
                                                    pie_special[3] = pie_special[3] + element.come_out;
                                                }
                                            }

                                            // Titan plaza
                                            if (
                                                name_camera == "a9211a1f-1b58-415a-9354-edf514315d52" ||
                                                name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                                name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651" ||
                                                name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                                name_camera == "f702432e-e713-437e-ad31-87daf772163e" ||
                                                name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                                name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                            ) {
                                                label_pie = [
                                                    "Nivel 1 - Puerta 3",
                                                    "Nivel 2 - Puerta 1",
                                                    "Nivel 2 - Puerta 2",
                                                    "Nivel 3 - Puerta 4",
                                                ];
                                                if (name_camera == "a9211a1f-1b58-415a-9354-edf514315d52") {
                                                    pie_special[0] = pie_special[0] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "977c040f-25ae-40b7-9374-4f53570261ce" ||
                                                    name_camera == "ac059cbc-fc11-4cc8-a3ce-1c5f8cb7e651"
                                                ) {
                                                    pie_special[1] = pie_special[1] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "8b5890e6-7760-4ea4-ab13-ffdedf212d0d" ||
                                                    name_camera == "f702432e-e713-437e-ad31-87daf772163e"
                                                ) {
                                                    pie_special[2] = pie_special[2] + element.come_out;
                                                }

                                                if (
                                                    name_camera == "b7f160d1-cc0c-4f42-81e8-9f4999e1d13e" ||
                                                    name_camera == "42ab832f-289a-418b-9986-a4d22a607243"
                                                ) {
                                                    pie_special[3] = pie_special[3] + element.come_out;
                                                }
                                            }

                                            // FINISH HARDCODE

                                            if (element.event_date in camerasTotal) {
                                                dict_out[element.event_date] =
                                                    element.come_out + camerasTotal[element.event_date][0];
                                                dict_in[element.event_date] =
                                                    element.come_in + camerasTotal[element.event_date][1];
                                                camerasTotal[element.event_date][0] = dict_out[element.event_date];
                                                camerasTotal[element.event_date][1] = dict_in[element.event_date];
                                            } else {
                                                dict_out[element.event_date] = element.come_out;
                                                dict_in[element.event_date] = element.come_in;
                                                var dict_in_out = { 0: element.come_out, 1: element.come_in };
                                                camerasTotal[element.event_date] = dict_in_out;
                                            }
                                        }
                                    });
                                    nCountInterno += 1;

                                    if (nCountInterno == this.state["groups"].length - 1) {
                                        var nCount = 0;
                                        let suma1 = 0;
                                        let suma2 = 0;
                                        if ("00:00" in camerasTotal) {
                                            delete camerasTotal["00:00"];
                                        }
                                        const keys = Object.keys(camerasTotal);
                                        const entries = Object.entries(camerasTotal);

                                        var sorted = entries.sort();

                                        for (const [key, value] of sorted) {
                                            arrayXDateChartLine.push(key);
                                            arrayX2DateChartBar.push(key);
                                            arrayYOutChartLine.push(camerasTotal[key][0]);
                                            arrayYInChartLine.push(camerasTotal[key][1]);
                                            suma1 += camerasTotal[key][1];
                                            suma2 += camerasTotal[key][0];

                                            arrayY2InChartBar.push(suma1);
                                            arrayY2OutChartBar.push(suma2);
                                            nCount += 1;
                                        }

                                        this.setState({
                                            moreDay: "Análitica aplicada a General, no aplica filtros",
                                            lessDay: "Análitica aplicada a General, no aplica filtros",
                                            moreDayNumber: "Análitica aplicada a General, no aplica filtros",
                                            lessDayNumber: "Análitica aplicada a General, no aplica filtros",
                                        });
                                        var maximo = 0;
                                        var menor = 5000000000;
                                        for (let i = 0; i < arrayYInChartLine.length; i++) {
                                            arrayCompleteDateLine.push(arrayXDateChartLine[i]);

                                            /* Día más visitado */
                                            if (arrayYInChartLine[i] > maximo) {
                                                maximo = arrayYInChartLine[i];
                                                positionMaximo = arrayXDateChartLine[i];
                                            }

                                            /* día menos visitado */
                                            if (arrayYInChartLine[i] <= arrayYInChartLine[0]) {
                                                menor = arrayYInChartLine[i];
                                                positionMenor = arrayXDateChartLine[i];
                                            }
                                            fechaMaximo = new Date(positionMaximo);
                                            fechaMenor = new Date(positionMenor);
                                        }

                                        /* Data para gráfica de historicos */
                                        let valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1];
                                        let valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1];

                                        dataTrafficIn = [valorTotalPersonasIn];
                                        dataTrafficOut = [valorTotalPersonasOut];

                                        /* Formato de los insight */
                                        let DateCompleteMaximo = `${fechaMaximo.getFullYear()}-${
                                            fechaMaximo.getMonth() + 1
                                        }-${fechaMaximo.getDate()}`;
                                        let DateCompleteMenor = `${fechaMenor.getFullYear()}-${
                                            fechaMenor.getMonth() + 1
                                        }-${fechaMenor.getDate()}`;

                                        configStateLine = {
                                            type: "line",
                                            options: {
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: `¿Cuál fue el tráfico desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}?`,
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 10,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: arrayCompleteDateLine,
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: arrayYOutChartLine,
                                                        backgroundColor: "transparent",
                                                        borderColor: "#86ac36",
                                                        pointStyle: "star",
                                                        pointHoverBorderWidth: 10,
                                                    },
                                                ],
                                            },
                                        };

                                        configStateBar = {
                                            type: "bar",
                                            options: {
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: [
                                                        "Total de Personas",
                                                        `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                                    ],
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 100,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: [
                                                    this.state.finishDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.finishDateStatic.toLocaleDateString() +
                                                        " - " +
                                                        this.state.startDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.startDateStatic.toLocaleDateString(),
                                                ],
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: dataTrafficOut,
                                                        backgroundColor: "#86ac36",
                                                        borderColor: "#86ac36",
                                                        borderWidth: 3,
                                                    },
                                                ],
                                            },
                                        };

                                        var listPie = Object.values(pie_special);
                                        configStatePie = {
                                            type: "pie",
                                            options: {
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: [
                                                        "Distribución",
                                                        `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                                    ],
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 100,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: label_pie,
                                                datasets: [
                                                    {
                                                        backgroundColor: ["#86ac36", "#606060", "#819631", "#4c5c24"],
                                                        data: listPie,
                                                    },
                                                ],
                                            },
                                        };

                                        if (isUpdate) {
                                            let tmpLine = this.state.lineGraphic;

                                            tmpLine.data.labels = configStateLine.data.labels;
                                            tmpLine.data.datasets = configStateLine.data.datasets;
                                            tmpLine.options.title.text = configStateLine.options.title.text;
                                            tmpLine.update();
                                            let tmpBar = this.state.barGraphic;
                                            tmpBar.data.labels = configStateBar.data.labels;
                                            tmpBar.data.datasets = configStateBar.data.datasets;
                                            tmpBar.options.title.text = configStateBar.options.title.text;
                                            tmpBar.update();

                                            let tmpPie = this.state.pieGraphic;
                                            tmpPie.data.labels = configStatePie.data.labels;
                                            tmpPie.data.datasets = configStatePie.data.datasets;
                                            tmpPie.options.title.text = configStatePie.options.title.text;
                                            tmpPie.update();

                                            this.setState({
                                                lineGraphic: tmpLine,
                                                loadingStaticInLine: false,
                                                barGraphic: tmpBar,
                                                loadingStaticInBar: false,
                                                pieGraphic: tmpPie,
                                                loadingStaticInPie: false,
                                            });
                                        } else {
                                            let line = document.getElementById("chartLineIn").getContext("2d");
                                            let bar = document.getElementById("chartBarIn").getContext("2d");
                                            let pie = document.getElementById("chartPie").getContext("2d");
                                            let chartLineIn = new Chart(line, configStateLine);
                                            let chartBarIn = new Chart(bar, configStateBar);
                                            let chartPie = new Chart(pie, configStatePie);

                                            this.setState({
                                                lineGraphic: chartLineIn,
                                                loadingStaticInLine: false,
                                                barGraphic: chartBarIn,
                                                loadingStaticInBar: false,
                                                pieGraphic: chartPie,
                                                loadingStaticInPie: false,
                                            });
                                        }
                                    }
                                } else {
                                    document.getElementById("chartLineIn").style.display = "none";
                                    document.getElementById("loadingLineIn").style.display = "";
                                    document.getElementById("chartBarIn").style.display = "none";
                                    document.getElementById("chartPie").style.display = "none";
                                    document.getElementById("loadingBarChartIn").style.display = "";
                                    document.getElementById("loadingPieChart").style.display = "";
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
            });
        } else {
            API.get(
                "https://dot",
                "/analytics/ingress" +
                    "?start=" +
                    finishDate +
                    "&end=" +
                    startDate +
                    "&groupId=" +
                    localStorage.getItem("idGroup")
            )
                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            if (data.length !== 0) {
                                document.getElementById("chartLineIn").style.display = "";
                                document.getElementById("loadingLineIn").style.display = "none";
                                document.getElementById("chartBarIn").style.display = "";

                                document.getElementById("loadingBarChartIn").style.display = "none";

                                let suma1 = 0;
                                let suma2 = 0;
                                data.forEach(element => {
                                    if (element.come_in !== undefined) {
                                        arrayXDateChartLine.push(element.event_date);

                                        arrayYOutChartLine.push({
                                            x: data.indexOf(element),
                                            y: element.come_out,
                                        });
                                        arrayYInChartLine.push({
                                            x: data.indexOf(element),
                                            y: element.come_in,
                                        });
                                    }
                                });

                                data.forEach(element => {
                                    if (element.come_in !== undefined) {
                                        arrayX2DateChartBar.push(element.event_date);
                                        suma1 += element.come_in;
                                        suma2 += element.come_out;

                                        arrayY2InChartBar.push({
                                            x: data.indexOf(element),
                                            y: suma1,
                                        });
                                        arrayY2OutChartBar.push({
                                            x: data.indexOf(element),
                                            y: suma2,
                                        });
                                    }
                                });

                                this.setState({
                                    moreDay: "Análitica aplicada a General, no aplica filtros",
                                    lessDay: "Análitica aplicada a General, no aplica filtros",
                                    moreDayNumber: "Análitica aplicada a General, no aplica filtros",
                                    lessDayNumber: "Análitica aplicada a General, no aplica filtros",
                                });

                                for (let i = 0; i < arrayYInChartLine.length; i++) {
                                    arrayCompleteDateLine.push(arrayXDateChartLine[i]);

                                    /* Día más visitado */
                                    if (arrayYInChartLine[i].y > maximo) {
                                        maximo = arrayYInChartLine[i].y;
                                        positionMaximo = arrayYInChartLine[i].x;
                                    }

                                    /* día menos visitado */
                                    if (arrayYInChartLine[i].y <= arrayYInChartLine[0].y) {
                                        menor = arrayYInChartLine[i].y;
                                        positionMenor = arrayYInChartLine[i].x;
                                    }

                                    fechaMaximo = new Date(positionMaximo);
                                    fechaMenor = new Date(positionMenor);
                                }

                                /* Data para gráfica de historicos */
                                let valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1].y;
                                let valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1].y;

                                dataTrafficIn = [
                                    {
                                        x: 1,
                                        y: valorTotalPersonasIn,
                                    },
                                ];
                                dataTrafficOut = [
                                    {
                                        x: 1,
                                        y: valorTotalPersonasOut,
                                    },
                                ];

                                /* Formato de los insight */
                                let DateCompleteMaximo = `${fechaMaximo.getFullYear()}-${
                                    fechaMaximo.getMonth() + 1
                                }-${fechaMaximo.getDate()}`;
                                let DateCompleteMenor = `${fechaMenor.getFullYear()}-${
                                    fechaMenor.getMonth() + 1
                                }-${fechaMenor.getDate()}`;
                            } else {
                                document.getElementById("chartLineIn").style.display = "none";
                                document.getElementById("loadingLineIn").style.display = "";
                                document.getElementById("chartBarIn").style.display = "none";
                                document.getElementById("loadingBarChartIn").style.display = "";
                            }

                            configStateLine = {
                                type: "line",
                                options: {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: `¿Cuál fue el tráfico desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}?`,
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 10,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: arrayCompleteDateLine,
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: arrayYOutChartLine,
                                            backgroundColor: "transparent",
                                            borderColor: "#86ac36",
                                            pointStyle: "star",
                                            pointHoverBorderWidth: 10,
                                        },
                                    ],
                                },
                            };

                            configStateBar = {
                                type: "bar",
                                options: {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: [
                                            "Total de Personas",
                                            `desde ${this.state.finishDateStatic.toLocaleString()} hasta ${this.state.startDateStatic.toLocaleString()}`,
                                        ],
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 100,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: [
                                        this.state.finishDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.finishDateStatic.toLocaleDateString() +
                                            " - " +
                                            this.state.startDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.startDateStatic.toLocaleDateString(),
                                    ],
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: dataTrafficOut,
                                            backgroundColor: "#86ac36",
                                            borderColor: "#86ac36",
                                            borderWidth: 3,
                                        },
                                    ],
                                },
                            };

                            if (isUpdate) {
                                let tmpLine = this.state.lineGraphic;

                                tmpLine.data.labels = configStateLine.data.labels;
                                tmpLine.data.datasets = configStateLine.data.datasets;
                                tmpLine.options.title.text = configStateLine.options.title.text;
                                tmpLine.update();
                                let tmpBar = this.state.barGraphic;
                                tmpBar.data.labels = configStateBar.data.labels;
                                tmpBar.data.datasets = configStateBar.data.datasets;
                                tmpBar.options.title.text = configStateBar.options.title.text;
                                tmpBar.update();

                                this.setState({
                                    lineGraphic: tmpLine,
                                    loadingStaticInLine: false,
                                    barGraphic: tmpBar,
                                    loadingStaticInBar: false,
                                });
                            } else {
                                let line = document.getElementById("chartLineIn").getContext("2d");
                                let bar = document.getElementById("chartBarIn").getContext("2d");

                                let chartLineIn = new Chart(line, configStateLine);
                                let chartBarIn = new Chart(bar, configStateBar);
                                this.setState({
                                    lineGraphic: chartLineIn,
                                    loadingStaticInLine: false,
                                    barGraphic: chartBarIn,
                                    loadingStaticInBar: false,
                                });
                            }
                        });
                    } else {
                        console.log("Error" + response);
                    }
                })
                .catch(error => console.log("error ", error));
        }
    }

    filterchart(e) {
        if (e == 1) {
            let days = "7 días";
            this.setState({
                dayFilter: days,
                startDateStatic: new Date(new Date().getTime()),
                finishDateStatic: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
            });
            this.showGraphicsStatic(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                true,
                new Date().getTime(),
                new Date().getTime() - 7 * 24 * 60 * 60 * 1000
            );
        } else if (e == 2) {
            let days = "15 días";
            this.setState({
                dayFilter: days,
                startDateStatic: new Date(new Date().getTime()),
                finishDateStatic: new Date(new Date().getTime() - 15 * 24 * 60 * 60 * 1000),
            });
            this.showGraphicsStatic(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                true,
                new Date().getTime(),
                new Date().getTime() - 15 * 24 * 60 * 60 * 1000
            );
        } else if (e == 3) {
            let days = "21 días";
            this.setState({
                dayFilter: days,
                startDateStatic: new Date(new Date().getTime()),
                finishDateStatic: new Date(new Date().getTime() - 21 * 24 * 60 * 60 * 1000),
            });
            this.showGraphicsStatic(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                true,
                new Date().getTime(),
                new Date().getTime() - 21 * 24 * 60 * 60 * 1000
            );
        } else if (e == 4) {
            let days = "30 días";
            this.setState({
                dayFilter: days,
                startDateStatic: new Date(new Date().getTime()),
                finishDateStatic: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
            });
            this.showGraphicsStatic(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                true,
                new Date().getTime(),
                new Date().getTime() - 30 * 24 * 60 * 60 * 1000
            );
        } else if (e == 5) {
            let days = "90 días";
            this.setState({
                dayFilter: days,
                startDateStatic: new Date(new Date().getTime()),
                finishDateStatic: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
            });
            this.showGraphicsStatic(
                localStorage.getItem("idGroup"),
                localStorage.getItem("nameGroup"),
                true,
                new Date().getTime(),
                new Date().getTime() - 90 * 24 * 60 * 60 * 1000
            );
        } else if (e == 6) {
            this.setState({ showModalCustomFilter: true });
        } else if (e == 7) {
            this.setState({ showModalCustomFilterDay: true });
            this.setState({ showModalCustomFilter: true });
        }
    }

    componentDidMount() {
        document.title = "Switch AI | Dashboard";
        this.getListGroups();
        this.hideObjects();

        document.getElementById("staticDashboard").style.display = "";
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    hideObjects() {
        document.getElementById("loadingBarChartIn").style.display = "none";

        document.getElementById("loadingLineIn").style.display = "none";
    }

    closeSideBar() {
        document.getElementById("lateralMenu").style.display = "none";
        document.getElementById("genericBody").style.filter = "none";
        document.getElementById("sub-menu").style.display = "none";
        document.getElementById("menuIcon2").style.display = "none";
        document.getElementById("menuIcon1").style.display = "";
        document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
    }

    render() {
        return (
            <div>
                <NavMenuBar></NavMenuBar>
                <div id="genericBody" onClick={this.closeSideBar}>
                    <div>
                        <div className="Body-Dashboard" id="Body-Dashboard">
                            <div id="staticDashboard">
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-inline">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic" className="Camera-Button">
                                                    Grupo {localStorage.getItem("nameGroup")}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>{this.state.groups}</Dropdown.Menu>
                                            </Dropdown>

                                            <div className="Div-Date">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="light"
                                                        id="dropdown-basic"
                                                        className="Camera-Button"
                                                    >
                                                        Últimos {this.state.dayFilter}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={e => this.filterchart(1)}>
                                                            Últimos 7 días
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(2)}>
                                                            Últimos 15 días
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(3)}>
                                                            Últimos 21 días
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(4)}>
                                                            Últimos 30 días
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(5)}>
                                                            Últimos 90 días
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(6)}>
                                                            Horas Específicas
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.filterchart(7)}>
                                                            Días Específicos
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Modal
                                                    show={this.state.showModalCustomFilter}
                                                    size="lg"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <ModalBody className="px-5 py-3">
                                                        <Row>
                                                            <Col lg={3}>
                                                                <p>Fecha Inicio:</p>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <p>Fecha Final:</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <DatePicker
                                                                className="Date-Picker"
                                                                selected={this.state.finishDateStatic}
                                                                onChange={date => this.setState({ finishDateStatic: date })}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                timeCaption="Hora"
                                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                            />
                                                            <DatePicker
                                                                id="customFilter"
                                                                className="Date-Picker"
                                                                selected={this.state.startDateStatic}
                                                                onChange={date => this.setState({ startDateStatic: date })}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                timeCaption="Hora"
                                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                            />
                                                        </Row>
                                                        <Row style={{ justifyContent: "center" }}>
                                                            <Button
                                                                variant="success"
                                                                className="Button-Apply-Filter"
                                                                onClick={this.applyFilterStatic}
                                                            >
                                                                Aplicar
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="Button-Close-Modal"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showModalCustomFilter: false,
                                                                    });
                                                                }}
                                                            >
                                                                Cerrar
                                                            </Button>
                                                        </Row>
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="Container-Layout">
                                                    <div className="Load">
                                                        {this.state.loadingStaticInLine && <div className="loader"></div>}
                                                    </div>
                                                    <canvas id="chartLineIn"></canvas>
                                                    <div id="loadingLineIn">
                                                        <div className="Div-Center">
                                                            <label className="Title-Message">Tráfico</label>
                                                            <br></br>
                                                            <br></br>
                                                            <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                            <br></br>
                                                            <br></br>
                                                            <label className="Text-Message">Estamos procesando tus datos</label>
                                                            <br></br>
                                                            <label className="Text-Message">
                                                                Pronto tendremos estadísticas para ti
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="Container-Layout">
                                                    <div className="Load">
                                                        {this.state.loadingStaticInBar && <div className="loader"></div>}
                                                    </div>
                                                    <canvas id="chartBarIn"></canvas>
                                                    <div id="loadingBarChartIn" className="Div-Center">
                                                        <label className="Title-Message">Histórico de ingreso y salida</label>
                                                        <br></br>
                                                        <br></br>
                                                        <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                        <br></br>
                                                        <br></br>
                                                        <label className="Text-Message">Estamos procesando tus datos</label>
                                                        <br></br>
                                                        <label className="Text-Message">
                                                            Pronto tendremos estadísticas para ti
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="Container-Layout">
                                                    <div className="Load">
                                                        {this.state.loadingStaticInPie && <div className="loader"></div>}
                                                    </div>
                                                    <canvas id="chartPie"></canvas>
                                                    <div id="loadingPieChart" className="Div-Center">
                                                        <label className="Title-Message">
                                                            Distribución de trafico por accesos
                                                        </label>
                                                        <br></br>
                                                        <br></br>
                                                        <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                        <br></br>
                                                        <br></br>
                                                        <label className="Text-Message">Estamos procesando tus datos</label>
                                                        <br></br>
                                                        <label className="Text-Message">
                                                            Pronto tendremos estadísticas para ti
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4} className="mt-5">
                                        <Row>
                                            <Col lg={10} className="Card-insight mr-4 mb-3">
                                                <Row style={{ minHeight: "7rem" }}>
                                                    <Col lg={12} className="mx-2">
                                                        <Row className="pt-3" style={{ margin: "auto" }}>
                                                            <label className="label-Option-Dashboard">
                                                                {" "}
                                                                Día más visitado (Año/Mes/día)
                                                            </label>
                                                        </Row>
                                                        <Row>
                                                            <div style={{ margin: "auto" }}>
                                                                <label className="Insight-Home">
                                                                    {this.state.peaksPerDay.majorDate || this.state.moreDay}
                                                                </label>
                                                            </div>
                                                        </Row>
                                                        <Row className="pt-3" style={{ margin: "auto" }}>
                                                            <label className="label-Option-Dashboard">
                                                                {" "}
                                                                Cantidad de visitas del días mas visitado
                                                            </label>
                                                        </Row>
                                                        <Row>
                                                            <div style={{ margin: "auto" }}>
                                                                <label className="Insight-Home">
                                                                    {this.state.peaksPerDay.highestValue ||
                                                                        this.state.moreDayNumber}
                                                                </label>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10} className="Card-insight mr-4 mb-3">
                                                <Row style={{ minHeight: "7rem" }}>
                                                    <Col lg={12} className="mx-2">
                                                        <Row className="pt-3" style={{ margin: "auto" }}>
                                                            <label className="label-Option-Dashboard">
                                                                {" "}
                                                                Día menos visitado (Año/Mes/día)
                                                            </label>
                                                        </Row>
                                                        <Row>
                                                            <div style={{ margin: "auto" }}>
                                                                <label className="Insight-Home">
                                                                    {this.state.peaksPerDay.minorDate || this.state.lessDay}
                                                                </label>
                                                            </div>
                                                        </Row>
                                                        <Row className="pt-3" style={{ margin: "auto" }}>
                                                            <label className="label-Option-Dashboard">
                                                                {" "}
                                                                Cantidad de visitas del días menos visitado
                                                            </label>
                                                        </Row>
                                                        <Row>
                                                            <div style={{ margin: "auto" }}>
                                                                <label className="Insight-Home">
                                                                    {this.state.peaksPerDay.lowestValue ||
                                                                        this.state.lessDayNumber}
                                                                </label>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default DashboardHistorical;
