import * as _ from "underscore";

class Schedules {
  isMorning(object) {
    if (object["format"] === "AM") {
      return true;
    }
    return false;
  }

  parseToSeconds(time) {
    let secondsHours = parseInt(time["hours"]) * 3600;
    let secondsMinutes = parseInt(time["minutes"]) * 60;
    let sum = secondsHours + secondsMinutes;

    if (time["format"] === "PM") {
      sum += 12 * 3600;
    }

    return sum;
  }
  validate(initV, endV, init, end) {
    if (initV >= init && initV <= end) {
      return true;
    } else if (endV >= init && endV <= end) {
      return true;
    }

    return false;
  }
  isRangeEqual(hourInit, hourFinish, object) {
    let initValueInSeconds = this.parseToSeconds(hourInit);
    let endValueInSeconds = this.parseToSeconds(hourFinish);
    let isValidate = false;

    _.each(object, (element) => {
      const init = this.parseToSeconds(element["from"]);
      const end = this.parseToSeconds(element["to"]);
      if (this.validate(initValueInSeconds, endValueInSeconds, init, end)) {
        isValidate = false;
      } else {
        isValidate = true;
      }
    });

    return isValidate;
  }

  existSchedule(schedule, listElements) {
    let isFalse = false;
    _.each(listElements, (element) => {
      if (_.isEqual(element, schedule) === true) {
        isFalse = true;
      }
    });
    return isFalse;
  }

  async indexSchedule(object, pageState) {
    let configuration = pageState.state.actualConfiguration;
    await pageState.setState({ areYouSure: true, idxDropSchedule: _.findIndex(configuration["schedule"], object) });
  }

  async dropSchedule(object) {
    let configurationSaved = object.state.actualConfiguration;
    let configurationShow = object.state.scheduleToShow;
    let idxState = object.state.idxDropSchedule;

    let now = { ...configurationSaved };
    let temp = this.removeValue(now.schedule, now.schedule[idxState]);
    let tempTwo = _.without(configurationShow, configurationShow[idxState]);
    now.schedule = temp;

    if (configurationSaved["schedule"][idxState].isSaved === 1) {
      await object.putConfiguration(
        JSON.stringify({
          data: {
            create_date: new Date(),
            update_date: new Date(),
            configuration: now,
          },
        })
      );
    }
    
    await object.setState({
      actualConfiguration: now,
      scheduleToShow: tempTwo,
      areYouSure: false,
    });
  }

  removeValue(array, id) {
    return _.reject(array, (item) => {
      return item === id;
    });
  }
}

export default Schedules;
