import React, { useState } from "react";
import { Card } from "./Card";
import { DateInput } from "components/DateInput";

export const ManagementCard = ({ activeAlerts, ...props }) => {
    const [date, setDate] = useState({});

    const isChecked = activeAlerts.includes(props.type);
    const disabled = !isChecked;

    const handleDateChange = (value, name) => setDate({ ...date, [name]: value });

    return (
        <Card {...props} isChecked={isChecked}>
            <div className="card__hours mt-2">
                <DateInput
                    value={date?.start}
                    includeHours
                    label="Hora de apertura:"
                    handleChange={date => handleDateChange(date, "start")}
                    disabled={disabled}
                />
                <DateInput
                    value={date?.end}
                    includeHours
                    label="Hora de cierre:"
                    handleChange={date => handleDateChange(date, "end")}
                    disabled={disabled}
                />
            </div>
        </Card>
    );
};
