//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import Footer from "../../components/Footer";
import { Row, Col, Pagination } from "react-bootstrap";
import API from "../../api/Main";

//Import images
import error from "../../imgs/error.png";

//Import styles
import "../../styles/Sympthoms/answerssymptoms.css";

class AnswerSymptoms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
      items: [],
      answers: [],
      data: [],
    };
  }

  handleClickPag = (item) => {
    this.showInformation(
      localStorage.getItem("AnsPag"),
      parseInt(item.target.innerText)
    );
  };

  getAnswers() {
    let timestamp = new Date().setHours(0, 0, 0, 0) / 1000;
    API.get("https://dot", "/symptom/answers/" + timestamp)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.response.data.length !== 0) {
              localStorage.setItem("AnsPag", data.response.data.length);
              let tempData = data;
              this.setState({ data: tempData });
              this.showInformation(localStorage.getItem("AnsPag"), 1);
            } else {
              document.getElementById("errorAnswers").innerText =
                "Aún no hay respuestas";
              document.getElementById("errorImg").style.display = "";
              document.getElementById("divError").style.display = "";
            }
          });
        } else {
          response.json().then((data) => {
            document.getElementById("errorAnswers").innerText =
              "Aún no hay respuestas";
            document.getElementById("errorImg").style.display = "";
            document.getElementById("divError").style.display = "";
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  showInformation(length, itemToActive) {
    let paginationTemp = [];
    for (let number = 1; number <= length; number++) {
      paginationTemp.push(
        <Pagination.Item
          key={number}
          active={number === itemToActive}
          onClick={this.handleClickPag}
        >
          {number}
        </Pagination.Item>
      );
    }
    let tempAnswers = [];
    let nameSymptoms = [
      "Fiebre",
      "Dolor de garganta",
      "Dolor de cabeza",
      "Congestión",
      "Tos",
      "Dificultad para respirar",
      "Fatiga",
      "Escalofrío",
      "Dolor muscular",
      "Ninguno",
    ];
    let nameCases = [
      "He realizado un viaje internacional",
      "He realizado un viaje nacional",
      "Soy trabajador de salud",
      "He estado en contacto en los últimos 14 días con una persona diagnosticada con COVID-19",
    ];

    let symptoms = [];
    let cases = [];

    for (
      var i = 0;
      i <
      this.state.data.response.data[itemToActive - 1].answer.symptoms.length;
      i++
    ) {
      if (
        this.state.data.response.data[itemToActive - 1].answer.symptoms[i]
          .answer === 1
      ) {
        symptoms.push(
          <div>
            <label className="Text-Answer">{nameSymptoms[i]}</label>
          </div>
        );
      }
    }

    for (
      var j = 0;
      j < this.state.data.response.data[itemToActive - 1].answer.covid.length;
      j++
    ) {
      if (
        this.state.data.response.data[itemToActive - 1].answer.covid[j]
          .answer === 1
      ) {
        cases.push(
          <div>
            <label className="Text-Answer">{nameCases[j]}</label>
          </div>
        );
      }
    }

    if (symptoms.length === 0) {
      symptoms.push(
        <div>
          <label className="Text-Answer">Ninguno</label>
        </div>
      );
    }

    if (cases.length === 0) {
      cases.push(
        <div>
          <label className="Text-Answer">Ninguno</label>
        </div>
      );
    }

    tempAnswers = (
      <div>
        <label className="Title-Answer">Correo electrónico: </label>{" "}
        <label className="Text-Answer">
          {this.state.data.response.data[itemToActive - 1].email}
        </label>
        <br></br>
        <label className="Title-Answer">Hora de respuesta:</label>{" "}
        <label className="Text-Answer">
          {new Date(
            this.state.data.response.data[itemToActive - 1].answer.timestamp
          ).toTimeString()}
        </label>
        <br></br>
        <label className="Title-Answer">Síntomas reportados:</label>
        <br></br>
        <div className="List">{symptoms}</div>
        <label className="Title-Answer">Casos adicionales reportados:</label>
        <br></br>
        <div className="List">{cases}</div>
      </div>
    );
    this.setState({ items: paginationTemp, answers: tempAnswers });
  }

  styleToActive() {
    /* document.getElementById("activeAnsSym").style.color = "rgb(70, 67, 67)";
    document.getElementById("activeAnsSym").style.borderStyle = "dashed";
    document.getElementById("activeAnsSym").style.borderColor = "#84ac34";
    document.getElementById("activeAnsSym").style.borderRadius = "1rem";
    document.getElementById("item-dashboard-manager").style.color = "";
    document.getElementById("item-dashboard-manager").style.borderStyle = "none";
    document.getElementById("activeCamsBio").style.color = "";
    document.getElementById("activeCamsBio").style.borderStyle = "none";
    document.getElementById("activeConfigBio").style.color = "";
    document.getElementById("activeConfigBio").style.borderStyle = "none";
    document.getElementById("activeConfig").style.color = "";
    document.getElementById("activeConfig").style.borderStyle = "none";
    document.getElementById("activeDashSym").style.color = "";
    document.getElementById("activeDashSym").style.borderStyle = "none";
    document.getElementById("activeConfigSym").style.color = "";
    document.getElementById("activeConfigSym").style.borderStyle = "none";
    document.getElementById("activeDashBio").style.color = "";
    document.getElementById("activeDashBio").style.borderStyle = "none";*/
    document.getElementById("errorImg").style.display = "none";
    document.getElementById("divError").style.display = "none"; 
  }

  componentDidMount() {
    document.title = "Switch AI | Respuestas individuales";
    this.styleToActive();
    this.getAnswers();
  }

  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }
  
  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <div className="Body-Answers-Symptoms">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={8}>
                    <div className="Container-Card">
                      <div className="Center-Title">
                        <label className="Title-Dashboard">
                          Respuestas individuales
                        </label>
                        <br></br>
                        <div id="divError">
                          <img
                            src={error}
                            width="150rem"
                            alt="Mensaje de error"
                            id="errorImg"
                          ></img>
                          <br></br>
                          <label
                            id="errorAnswers"
                            className="Error-Answers"
                          ></label>
                        </div>
                      </div>
                      <div>{this.state.answers}</div>
                    </div>

                    <div>
                      <Pagination>{this.state.items}</Pagination>
                    </div>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default AnswerSymptoms;
