import React from "react";

export const Input = ({ placeholder, iconName, type = "text", disabled }) => {
    return (
        <div className="input">
            <input className="input__box" placeholder={placeholder} type={type} disabled={disabled} />
            <i class={`fa-solid ${iconName} input__icon`} />
        </div>
    );
};
