import React from "react";
import { LineChart as Chart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import "styles/chart.css";

export const LineChart = ({ data = [], settings = {},className }) => {
    const { lineKey, lineName, xKey } = settings;

    return (
        <ResponsiveContainer className={className} width={settings?.width ?? 570} height={settings?.height ?? 300}>
            <Chart data={data} title="¿Cúal fue tráfico desde octubre hasta noviembre?">
                <Legend iconType="point" />
                <Line type="monotone" dataKey={lineKey} name={lineName} stroke="#87ad36" strokeWidth={4} fontSize={12} />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey={xKey} fontSize={12} />
                <YAxis fontSize={12} />
                <Tooltip itemStyle={{ fontSize: 12 }} labelStyle={{ fontSize: 12 }} />
            </Chart>
        </ResponsiveContainer>
    );
};
