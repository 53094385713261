//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import { Row, Col, Card, Button, Dropdown, Nav, Table } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";
import Clock from 'react-live-clock';

//Import images
import iconHand from "../../imgs/manita.png";
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";
import "react-datepicker/dist/react-datepicker.css";

class DashboardMarketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphic: [],
      lastEventsPartOne: [],
      lastEventsPartTwo: [],
      pagination: 1,
      cameras: [],
      lineGraphic: {},
      lineGraphic_2: {},
      barGraphic: {},
      barGraphic_2: {},
      pieGraphic: {},
      loadingStaticInLine: false,
      loadingStaticInBar: false,
      loadingDynamicImgZones: false,
      loadingDynamicZones: false,
      tempId: "",
      tempName: "",
      startDateStatic: new Date(),
      finishDateStatic: new Date(new Date().getTime() - 3600000),
      startDateDynamic: new Date(),
      finishDateDynamic: new Date(new Date().getTime() - 3600000),
      capacity: "",
    };
  }

  handleClickLoad = () => {
    this.getListCameras(this.state.pagination + 1);
  };

  handleClickCamera = (e) => {
    this.setState({
      loadingDynamicImgZones: false,
      loadingDynamicZones: true,
      tempId: e.target.id,
      tempName: e.target.name,
    });
    this.updateDashboardDynamic(
      e.target.id,
      e.target.name,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
  };


  applyFilterDynamic = () => {
    clearInterval(this.intervalId);
    this.updateDashboardDynamic(
      this.state.tempId,
      this.state.tempName,
      true,
      this.state.startDateDynamic.getTime(),
      this.state.finishDateDynamic.getTime()
    );
  };

  deleteFilterStactic = () => {
    this.setState({
      startDateStatic: new Date(),
      finishDateStatic: new Date(new Date().getTime() - 3600000),
    });
    this.updateDashboardStatic(
      this.state.tempId,
      this.state.tempName,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
    this.intervalId = setInterval(
      () =>
        this.updateDashboardStatic(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );
  };

  deleteFilterDynamic = () => {
    this.setState({
      startDate: new Date(),
      finishDate: new Date(new Date().getTime() - 3600000),
    });
    this.updateDashboardDynamic(
      this.state.tempId,
      this.state.tempName,
      true,
      new Date().getTime(),
      new Date().getTime() - 1 * 3600 * 1000
    );
    this.intervalId = setInterval(
      () =>
        this.updateDashboardDynamic(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );
  };

  updateDashboardStatic(id, name, isUpdate, startDate, finishDate) {
    this.hideObjects();
    this.showUpdates();
    this.showGraphicsStatic(id, name, isUpdate, startDate, finishDate);
  }

  updateDashboardDynamic(id, name, isUpdate, startDate, finishDate) {
    this.hideObjects();
    this.showUpdates();
    this.showGraphicsDynamic(id, name, isUpdate, startDate, finishDate);
  }

  getListCameras(pagination) {
    API.get("https://dot", "/cameras/" + pagination)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            data.forEach((element) => {
              let item = (
                <Dropdown.Item
                  onClick={this.handleClickCamera}
                  className="Camera"
                  id={element.id}
                  key={element.id}
                  name={element.name}
                >
                  Cámara {element.name}
                </Dropdown.Item>
              );
              this.state.cameras.push(item);
            });
            const cookies = new Cookies();
            if (pagination === cookies.get("pagination")) {
              document.getElementById("buttonLoad").style.display = "none";
            } else {
            }
            this.setState({
              pagination: pagination,
              loading: false,
              tempId: this.state.cameras[0].key,
              tempName: this.state.cameras[0].props.name,
            });
            this.updateDashboardStatic(
              this.state.cameras[0].key,
              this.state.cameras[0].props.name,
              false,
              new Date().getTime(),
              new Date().getTime() - 1 * 3600 * 1000
            );
            this.updateDashboardDynamic(
              this.state.cameras[0].key,
              this.state.cameras[0].props.name,
              false,
              new Date().getTime(),
              new Date().getTime() - 1 * 3600 * 1000
            );
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));
  }

  showGraphicsStatic(id, name, isUpdate, startDate, finishDate) {

    this.getAforo();

    let arrayXDateChartLine = [];
    let arrayYOutChartLine = [];
    let arrayYInChartLine = [];
    let arrayX2DateChartBar = [];
    let arrayY2InChartBar = [];
    let arrayY2OutChartBar = [];
    let arrayYGenderW = [];
    let arrayYGenderM = [];
    let arrayYGenderG = [];
    let arrayYGenderB = [];
    let configStateLine;
    let configStateBar;
    let configStateBar_2;

    this.setState({ loadingStaticInLine: true, loadingStaticInBar: true });

    API.get(
      "https://dot",
      "/analytics/ingress" + "?start=" + finishDate + "&end=" + startDate
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              document.getElementById("chartLineIn").style.display = "";
              document.getElementById("loadingLineIn").style.display = "none";

              data.forEach((element) => {
                arrayXDateChartLine.push(element.event_date);
                arrayYOutChartLine.push({
                  x: data.indexOf(element),
                  y: element.come_out,
                });
                arrayYInChartLine.push({
                  x: data.indexOf(element),
                  y: element.come_in,
                });
              });
            } else {
              document.getElementById("chartLineIn").style.display = "none";
              document.getElementById("loadingLineIn").style.display = "";
            }

            configStateLine = {
              type: "line",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Tráfico",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayXDateChartLine,
                datasets: [
                  {
                    label: ["Ingresos"],
                    data: arrayYInChartLine,
                    backgroundColor: "transparent",
                    borderColor: "#86ac36",
                    pointStyle: "star",
                    pointHoverBorderWidth: 10,
                  },
                  {
                    label: ["Salidas"],
                    data: arrayYOutChartLine,
                    backgroundColor: "transparent",
                    borderColor: "#606060",
                    pointStyle: "star",
                    pointHoverBorderWidth: 10,
                  },
                ],
              },
            };

            if (isUpdate) {
              let tmpLine = this.state.lineGraphic;
              tmpLine.data.labels = configStateLine.data.labels;
              tmpLine.data.datasets = configStateLine.data.datasets;
              tmpLine.update();

              this.setState({
                lineGraphic: tmpLine,
                loadingStaticInLine: false,
              });
            } else {
              let line = document
                .getElementById("chartLineIn")
                .getContext("2d");
              let chartLineIn = new Chart(line, configStateLine);

              this.setState({
                lineGraphic: chartLineIn,
                loadingStaticInLine: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));

    API.get(
      "https://dot",
      "/analytics/traffic" + "?start=" + finishDate + "&end=" + startDate
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.length !== 0) {
              document.getElementById("chartBarIn").style.display = "";
              document.getElementById("loadingBarChartIn").style.display =
                "none";

              data.forEach((element) => {
                arrayX2DateChartBar.push(element.event_date);
                arrayY2InChartBar.push({
                  x: data.indexOf(element),
                  y: element.come_in,
                });
                arrayY2OutChartBar.push({
                  x: data.indexOf(element),
                  y: element.come_out,
                });
              });
            } else {
              document.getElementById("chartBarIn").style.display = "none";
              document.getElementById("loadingBarChartIn").style.display = "";
            }

            configStateBar = {
              type: "bar",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Histórico de tráfico",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 100,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: [this.state.finishDateStatic.toLocaleTimeString() + " " + this.state.finishDateStatic.toLocaleDateString() + " - " + this.state.startDateStatic.toLocaleTimeString() + " " + this.state.startDateStatic.toLocaleDateString()],
                datasets: [
                  {
                    label: ["Ingresos"],
                    data: arrayY2InChartBar,
                    backgroundColor: "#86ac36",
                    borderColor: "#86ac36",
                    borderWidth: 3,
                  },
                  {
                    label: ["Salidas"],
                    data: arrayY2OutChartBar,
                    backgroundColor: "#606060",
                    borderColor: "#606060",
                    borderWidth: 3,
                  },
                ],
              },
            };


            if (isUpdate) {
              let tmpBar = this.state.barGraphic;
              tmpBar.data.labels = configStateBar.data.labels;
              tmpBar.data.datasets = configStateBar.data.datasets;
              tmpBar.update();
              this.setState({
                barGraphic: tmpBar,
                loadingStaticInBar: false,
              });
            } else {
              let bar = document.getElementById("chartBarIn").getContext("2d");
              let chartBarIn = new Chart(bar, configStateBar);

              this.setState({
                barGraphic: chartBarIn,
                loadingStaticInBar: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error));
  }

  async showUpdates() {
    try {
      const res = await API.get("https://dot", "/general");
      let js = await res.json();
      let arry = [];
      if (!Object.keys(js).includes("message")) {
        js.forEach((element) => {
          const url = element.url_tuto;
          arry.push(
            <div className="Card">
              <div className="contain_update" key={js.indexOf(element)}>
                <label className="Title-Update">{element.description} </label>
                <label className="Text-Update">
                  <img src={iconHand} alt="Icono noticia" width="26rem"></img>{" "}
                  <a
                    className="Link-Update"
                    href={"http://www." + url}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {element.url_tuto}
                  </a>
                </label>
              </div>
            </div>
          );
        });
      } else {
        arry.push(
          <div className="contain_update-none">
            <strong>{js.message}</strong>
          </div>
        );
      }
      this.setState({
        updates: arry,
      });
    } catch (error) {
      return error;
    }
  } 

  showGraphicsDynamic(id, name, isUpdate, startDate, finishDate) {

    let arrayXDateZones = [];
    let configStateLine_2;
    let datasetsZone = [];

    this.setState({ loadingDynamicZones: true});

    API.get(
      "https://dot",
      "/analytics/zones/" + id + "?start=" + finishDate + "&end=" + startDate
    )
      .then((response) => {
        if (response.ok) {
          console.log("id zone", id);
          response.json().then((data) => {
            if (data.length !== 0) {

              document.getElementById("chartLineZones").style.display = "";
              document.getElementById("loadingLineZones").style.display =
                "none";

              let zonesNames = Object.keys(data[0].zones);
              let zonesObject = {};

              zonesNames.forEach((zone) => {
                zonesObject[zone] = [];
                data.forEach((element) => {
                  zonesObject[zone].push({
                    x: data.indexOf(element),
                    y: element.zones[zone],
                  });
                });
              });

              data.forEach((element) => {
                arrayXDateZones.push(element.event_date);
              });

              datasetsZone = [];
              let arrayColors = ["#BEF74A", "#A0B96F", "#D6F794", "#C6F763"];
              let counter = 0;

              zonesNames.forEach((zone) => {
                datasetsZone.push({
                  label: [zone],
                  data: zonesObject[zone],
                  backgroundColor: ["rgb(255, 255, 255, 0)"],
                  borderColor: arrayColors[counter],
                  pointStyle: "star",
                  pointHoverBorderWidth: 10,
                });
                counter++;
              });

            } else {
              document.getElementById("chartLineZones").style.display = "none";
              document.getElementById("loadingLineZones").style.display = "";
            }

            configStateLine_2 = {
              type: "line",
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: true,
                  text: "Aforo por zona",
                  fontSize: 16,
                  fontFamily: "Gotham",
                  fontColor: "rgb(70, 67, 67)",
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: arrayXDateZones,
                datasets: datasetsZone,
              },
            };

            if (isUpdate) {
              let tmpLine = this.state.lineGraphic_2;
              tmpLine.data.labels = configStateLine_2.data.labels;
              tmpLine.data.datasets = configStateLine_2.data.datasets;
              tmpLine.update();

              this.setState({
                lineGraphic_2: tmpLine,
                loadingDynamicZones: false,
              });
            } else {
              let line = document
                .getElementById("chartLineZones")
                .getContext("2d");
              let chartLineZones = new Chart(line, configStateLine_2);

              this.setState({
                lineGraphic_2: chartLineZones,
                loadingDynamicZones: false,
              });
            }
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log("error ", error))

  }


  getAforo() {
    
    this.setState({
      loadingAforo: true,
    });

    API.get("https://dot", "/analytics/capacity")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            this.setState({ capacity: data.response });
          });
          this.setState({
            loadingAforo: false,
          });
        } else {
          console.log("Error" + response);
        }
      })
      .catch((error) => console.log(error));

  }

 
  
  

  changeToStatic = () => {
    document.getElementById("dynamicDashboard").style.display = "none";
    document.getElementById("aforo").style.display = "none";
    document.getElementById("staticDashboard").style.display = "";
    document.getElementById("buttonStaticDashboard").style.backgroundColor = "#84AC3490";
    document.getElementById("buttonAforo").style.backgroundColor = "#dae0e5";
    //document.getElementById("buttonDynamicDashboard").style.backgroundColor = "#dae0e5";
  }

  /* changeToDynamic = () => {
    document.getElementById("staticDashboard").style.display = "none";
    document.getElementById("aforo").style.display = "none";
    document.getElementById("dynamicDashboard").style.display = "";
    document.getElementById("buttonDynamicDashboard").style.backgroundColor = "#84AC3490";
    document.getElementById("buttonAforo").style.backgroundColor = "#dae0e5";
    document.getElementById("buttonStaticDashboard").style.backgroundColor = "#dae0e5";

  } */

  changeToAforo = () => {
    document.getElementById("staticDashboard").style.display = "none";
    document.getElementById("dynamicDashboard").style.display = "none";
    document.getElementById("aforo").style.display = "";
    document.getElementById("buttonAforo").style.backgroundColor = "#84AC3490";
    //document.getElementById("buttonDynamicDashboard").style.backgroundColor = "#dae0e5";
    document.getElementById("buttonStaticDashboard").style.backgroundColor = "#dae0e5";
  }

  componentDidMount() {
    document.title = "Switch AI | Dashboard";
    this.getListCameras(this.state.pagination);
    this.hideObjects();
    
    this.intervalId = setInterval(
      () =>
        this.updateDashboardStatic(
          this.state.tempId,
          this.state.tempName,
          true,
          new Date().getTime(),
          new Date().getTime() - 1 * 3600 * 1000
        ),
      300000
    );
    console.log("id temp", this.state.tempId, "name temp", this.state.tempName );
    document.getElementById("staticDashboard").style.display = "";
    document.getElementById("dynamicDashboard").style.display = "none";
    document.getElementById("buttonStaticDashboard").style.backgroundColor = "#84AC3490";
    //document.getElementById("buttonDynamicDashboard").style.backgroundColor = "#dae0e5";
    document.getElementById("aforo").style.display = "none";
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  hideObjects() {
    document.getElementById("loadingBarChartIn").style.display = "none";
    document.getElementById("loadingLineIn").style.display = "none";
    document.getElementById("loadingLineZones").style.display = "none";
  }

  
  closeSideBar(){
    document.getElementById("lateralMenu").style.display = "none";
    document.getElementById("genericBody").style.filter = "none";
    document.getElementById("sub-menu").style.display = "none";
    document.getElementById("menuIcon2").style.display = "none";
    document.getElementById("menuIcon1").style.display = "";
    document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
  }

  render() {
    return (
      <div>
        <NavMenuBar></NavMenuBar>
        <div id="genericBody" onClick={this.closeSideBar}>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <div>
                    <div className="Body-Dashboard" id="Body-Dashboard">

                      <Row>
                        <Col lg={12}>
                          <div className="Line-Separator-Menu">
                            <Button id="buttonStaticDashboard" variant="light" className="Button-Change-Dash" onClick={this.changeToStatic}>Ingreso y salida</Button>
                            {/* <Button id="buttonDynamicDashboard" variant="light" className="Button-Change-Dash" onClick={this.changeToDynamic}>Zonas</Button> */}
                            <Button id="buttonAforo" variant="light" className="Button-Change-Dash" onClick={this.changeToAforo}>Aforo</Button>
                          </div>
                        </Col>
                      </Row>

                      <div id="staticDashboard">
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={12}>
                                <div className="Container-Layout">
                                  <div className="Load">
                                    {this.state.loadingStaticInLine && (
                                      <div className="loader"></div>
                                    )}
                                  </div>
                                  <canvas id="chartLineIn"></canvas>
                                  <div id="loadingLineIn">
                                    <div className="Div-Center">
                                      <label className="Title-Message">
                                        Tráfico
                                      </label>
                                      <br></br>
                                      <br></br>
                                      <img
                                        src={working}
                                        width="80rem"
                                        alt="Imagen de Loading"
                                      ></img>
                                      <br></br>
                                      <br></br>
                                      <label className="Text-Message">
                                        Estamos procesando tus datos
                                      </label>
                                      <br></br>
                                      <label className="Text-Message">
                                        Pronto tendremos estadísticas para ti
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                        <Row>
                          <Col lg={7}>
                            <Row>
                              <Col lg={12}>
                                <div className="Container-Layout">
                                  <div className="Load">
                                    {this.state.loadingStaticInBar && (
                                      <div className="loader"></div>
                                    )}
                                  </div>
                                  <canvas id="chartBarIn"></canvas>
                                  <div
                                    id="loadingBarChartIn"
                                    className="Div-Center"
                                  >
                                    <label className="Title-Message">
                                      Histórico de tráfico
                                    </label>
                                    <br></br>
                                    <br></br>
                                    <img
                                      src={working}
                                      width="80rem"
                                      alt="Imagen de Loading"
                                    ></img>
                                    <br></br>
                                    <br></br>
                                    <label className="Text-Message">
                                      Estamos procesando tus datos
                                    </label>
                                    <br></br>
                                    <label className="Text-Message">
                                      Pronto tendremos estadísticas para ti
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5}>
                            <div className="Container-Layout">
                              <div className="Load">
                                {this.state.loadingAforo && (
                                  <div className="loader"></div>
                                )}
                              </div>
                              <div className="Div-Center">
                                <label className="Title-Aforo">
                                  En este momento{" "}
                                </label>
                                <br></br>
                                <label className="Capacity">
                                  {this.state.capacity}
                                </label>
                                <br></br>
                                <label className="Title-Aforo">
                                  personas en tu tienda{" "}
                                </label>
                                <br></br>
                                <Clock
                                  format={"h:mm:ss a"}
                                  ticking={true}
                                  timezone={"America/Bogota"}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div id="dynamicDashboard">
                        <Row>
                          <Col lg={12}>
                            <div className="form-inline">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                  className="Camera-Button"
                                >
                                  Cámara {this.state.tempName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.cameras}
                                </Dropdown.Menu>
                              </Dropdown>
                              <Button
                                id="buttonLoad"
                                onClick={this.handleClickLoad}
                                variant="success"
                                className="Button-Success-Load-More"
                              >
                                Cargar más
                              </Button>
                              <div className="Div-Date">
                                <DatePicker
                                  className="Date-Picker"
                                  selected={this.state.finishDateDynamic}
                                  onChange={(date) =>
                                    this.setState({ finishDateDynamic: date })
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={30}
                                  timeCaption="Hora"
                                  dateFormat="yyyy/MM/dd h:mm aa"
                                />
                                <DatePicker
                                  className="Date-Picker"
                                  selected={this.state.startDateDynamic}
                                  onChange={(date) =>
                                    this.setState({ startDateDynamic: date })
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={30}
                                  timeCaption="Hora"
                                  dateFormat="yyyy/MM/dd h:mm aa"
                                />
                              </div>
                              <Button
                                variant="success"
                                className="Button-Apply-Filter"
                                onClick={this.applyFilterDynamic}
                              >
                                Aplicar
                            </Button>
                              <Button
                                variant="dark"
                                className="Button-Delete-Filter"
                                onClick={this.deleteFilterDynamic}
                              >
                                Eliminar
                            </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={7}>
                            <Row>
                              <Col lg={12}>
                                <div className="Container-Layout-Zones">
                                  <div className="Load">
                                    {this.state.loadingDynamicImgZones && (
                                      <div className="loader"></div>
                                    )}
                                  </div>
                                  <div id="loadingImg">
                                    <div className="Div-Center">
                                      <label className="Title-Message">
                                        Mapa de calor por zonas
                                      </label>
                                      <br></br>
                                      <br></br>
                                      <br></br>
                                      <img
                                        src={working}
                                        width="80rem"
                                        alt="Imagen de Loading"
                                      ></img>
                                      <br></br>
                                      <br></br>
                                      <label className="Text-Message">
                                        Estamos procesando tus datos
                                      </label>
                                      <br></br>
                                      <label className="Text-Message">
                                        Pronto tendremos estadísticas para ti
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5}>
                            <div className="Container-Layout-Zones">
                              <div className="Load">
                                {this.state.loadingDynamicZones && (
                                  <div className="loader"></div>
                                )}
                              </div>
                              <canvas id="chartLineZones"></canvas>
                              <div id="loadingLineZones">
                                <div className="Div-Center">
                                  <label className="Title-Message">
                                    Aforo por zona
                                  </label>
                                  <br></br>
                                  <br></br>
                                  <img
                                    src={working}
                                    width="80rem"
                                    alt="Imagen de Loading"
                                  ></img>
                                  <br></br>
                                  <br></br>
                                  <label className="Text-Message">
                                    Estamos procesando tus datos
                                  </label>
                                  <br></br>
                                  <label className="Text-Message">
                                    Pronto tendremos estadísticas para ti
                                  </label>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </Col>
                        </Row>

                      </div>

                      <div id="aforo">
                        <Row>
                          <Col lg={12}>
                            <div className="Container-Layout-Capacity">
                              <div className="Load">
                                {this.state.loadingAforo && (
                                  <div className="loader"></div>
                                )}
                              </div>
                              <div className="Div-Center">
                                <label className="Title-Aforo">
                                  En este momento{" "}
                                </label>
                                <br></br>
                                <label className="Capacity">
                                  {this.state.capacity}
                                </label>
                                <br></br>
                                <label className="Title-Aforo">
                                  personas en tu tienda{" "}
                                </label>
                                <br></br>
                                <Clock
                                  format={"h:mm:ss a"}
                                  ticking={true}
                                  timezone={"America/Bogota"}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default DashboardMarketing;
