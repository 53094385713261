import { v4 as uuid } from "uuid";
import { ALL_ACCESS } from "pages/Schedule";

export const getNewOptions = ({ allOptions, selectedOptions, allKey = ALL_ACCESS }) => {
    const selectAll = selectedOptions.find(option => option.value === allKey);
    return selectAll ? allOptions.slice(1) : selectedOptions;
};

export const createOptions = options =>
    options.map(({ value, type }) => ({
        id: uuid(),
        value,
        label: value,
        type,
    }));
