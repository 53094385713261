import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import RememberPassw from "./pages/Register/RememberPassw";
import NotFound from "./pages/404/NotFound";
import Validation from "./pages/Validation";
import Register from "./pages/Register/Register";
import Cameras from "./pages/Biosecurity/Cameras";
import DashboardBiosecurity from "./pages/Biosecurity/DashboardBiosecurity";
import DashboardSecurity from "./pages/Biosecurity/DashboardSecurity";
import Configuration from "./pages/Biosecurity/Configuration";
import Event from "./pages/Biosecurity/Event";
import FormSymptoms from "./pages/Sympthoms/FormSymptoms";
import AddEmailSymptoms from "./pages/Sympthoms/AddEmailsSymptoms";
import AnswerSymptoms from "./pages/Sympthoms/AnswersSymptoms";
import DashboardSymptoms from "./pages/Sympthoms/DashboardSymptoms";
import DashboardMarketing from "./pages/Marketing/DashboardMarketing";
import DashboardGeneral from "./pages/Marketing/DashboardGeneral";
import DashboardSuper from "./pages/Marketing/DashboardSuper";
import DashboardBehaviour from "./pages/Marketing/DashboardBehaviour";
import PointsSelection from "../src/pages/Configuration/PointsSelectionInOut";
import ConfigurationForAlgorithm from "../src/pages/Configuration/ConfigurationForAlgorithm";
import ZonesSelection from "../src/pages/Configuration/ZonesSelection";
import AdminResourcesGroup from "../src/pages/ResourcesGroup/AdminResourcesGroup";
import HomeOwner from "./pages/Home/HomeOwner";
import HomeAdmin from "./pages/Home/HomeAdmin";
import HomeAdminSecurity from "./pages/Home/HomeAdminSecurity";
import HistoricalMarketing from "./pages/Marketing/HistoricalMarketing";
import DashboardCharacterization from "./pages/Marketing/DashboardCharacterization";
import Schedule from "pages/Schedule";
import alerts from "pages/alerts";
import { ChatButton } from "components/chat-button";
import { Chatbot } from "components/chatbot";
import Historical from "pages/Historical";
// import Reports from "pages/Reports";

const App = () => {
    const [openChatbot, setOpenChatbot] = useState(false);

    useEffect(() => {
        Notification.requestPermission();
    }, []);

    const toggleChatbot = () => setOpenChatbot(!openChatbot);

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/owner/home" component={HomeAdmin}></Route>
                    <Route exact path="/" component={Login}></Route>
                    <Route exact path="/admin/home" component={HomeAdmin}></Route>
                    <Route exact path="/admin/homeSecurity" component={HomeAdminSecurity}></Route>
                    <Route exact path="/admin/homeSecurityDash" component={DashboardSecurity}></Route>
                    <Route exact path="/marketing/historical" component={HistoricalMarketing}></Route>
                    <Route exact path="/marketing/historical2" component={Historical}></Route>
                    <Route exact path="/marketing/behaviour" component={DashboardBehaviour}></Route>
                    <Route exact path="/marketing/characterization" component={DashboardCharacterization}></Route>
                    <Route exact path="/marketing/dashboard" component={DashboardMarketing}></Route>
                    <Route exact path="/marketing/general" component={DashboardGeneral}></Route>
                    <Route exact path="/marketing/super" component={DashboardSuper}></Route>
                    {/* <Route exact path="/marketing/reports" component={Reports} /> */}
                    <Route exact path="/validation/" component={Validation}></Route>
                    <Route exact path="/biodot/cameras" component={Cameras}></Route>
                    <Route exact path="/dashboard/homeBioSecurity" component={DashboardBiosecurity}></Route>
                    <Route exact path="/biodot/account" component={Configuration}></Route>
                    <Route exact path="/biodot/cameras/events/:idCamera" component={Event}></Route>
                    <Route exact path="/symptoms/action" component={FormSymptoms}></Route>
                    <Route exact path="/symptoms/emails" component={AddEmailSymptoms}></Route>
                    <Route exact path="/symptoms/answers" component={AnswerSymptoms}></Route>
                    <Route exact path="/symptoms/dashboard" component={DashboardSymptoms}></Route>
                    <Route exact path="/configuration/points-selection" component={PointsSelection}></Route>
                    <Route exact path="/configuration/zones-selection" component={ZonesSelection}></Route>
                    <Route exact path="/configuration/algorithm" component={ConfigurationForAlgorithm}></Route>
                    <Route exact path="/admin/admin-resources-group" component={AdminResourcesGroup}></Route>
                    <Route exact path="/register" component={Register}></Route>
                    <Route exact path="/remember" component={RememberPassw}></Route>
                    <Route path="/404" component={NotFound}></Route>
                    <Route exact path="/schedule" component={Schedule} />
                    <Route exact path="/configuration/alerts" component={alerts} />
                    <Redirect from="*" to="/404"></Redirect>
                </Switch>
            </BrowserRouter>
            {/* {window.location.pathname !== "/" && (
                <>{openChatbot ? <Chatbot closeChatbot={toggleChatbot} /> : <ChatButton openChatbot={toggleChatbot} />}</>
            )} */}
        </div>
    );
};

export default App;
